import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs/index";
import {ActivatedRoute} from "@angular/router";
import {PreparationConditionnementSupplier} from "../../preparation-conditionnement-resolver.service";
import {
  PreparationConditionnementService
} from "../../../core/services/conditionnements/preparation-conditionnement.service";
import {UtilsService} from "../../../core/utils/utils.service";
import {HELP_FOLDERS} from "../../../core/constants";
import {PointDeLivraisonDTO} from "../../../core/dtos/point-de-livraison-d-t-o";
import {RoutemapService} from "../../../core/services/routemap.service";
import {PrintMenuService} from "../../../core/services/gestionmenus/print-menu.service";
import {LigneConditionnementDetailsDto} from "../../../core/dtos/conditionnement/ligne-conditionnement-details-dto";
import {InternationalizationService} from "../../../core/services/i8n/i8n.service";
import {ProductionService} from "../../../core/services/gestion-production/production.service";

@Component({
  selector: 'yo-preparation-calcul-conditionnement',
  templateUrl: './preparation-calcul-conditionnement.component.html',
  styleUrls: ['./preparation-calcul-conditionnement.component.scss']
})
export class PreparationCalculConditionnementComponent implements OnInit, OnDestroy {

  data: LigneConditionnementDetailsDto[];
  subscriptionRoute: Subscription;
  displayErrors: boolean = false;
  subscriptionCalcul: Subscription;
  loadingVisible: boolean;
  idPlanProduction: number;
  errors: any = [];
  NoDataText: string;
  idParentSelected: number;
  expandAll: boolean = false;
  plcsNotTakenAccount: PointDeLivraisonDTO[] = [];
  readonly UtilsService = UtilsService;

  pathFile: string = HELP_FOLDERS.CONDITIONNEMENT + '/conditionnement-calcul-global';

  planProduction: string;

  constructor(private route: ActivatedRoute,
              private routeMapSvc: RoutemapService,
              private pcSvc: PreparationConditionnementService,
              public utils: UtilsService,
              public printMenuSvc: PrintMenuService,
              private i8nSvc: InternationalizationService,
              private prodSvc: ProductionService) {
  }

  ngOnInit(): void {
    this.routeSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subscriptionRoute);
    this.utils.unsubscribe(this.subscriptionCalcul);
  }

  routeSubscription = () => {
    this.subscriptionRoute = this.route.parent.data
      .subscribe((data: { preparationConditionnementSupplier: PreparationConditionnementSupplier }) => {
        if (data.preparationConditionnementSupplier) {
          this.idPlanProduction = data.preparationConditionnementSupplier.idPlanProduction;
          this.startCalculation(data.preparationConditionnementSupplier.isRefresh);
        }
      });
  };

  goToProductPage = (idProduct: number) => window.open(`/gestionproduits(secondary:gestionproduits/produit/true/${idProduct}/conditionnement)`, '_blank');

  goToPlcPage = (idClient: number, idPlc: number) => window.open(`/gestion-clients(secondary:point-de-livraison/${idClient}/${idPlc}/conditionnement/regles-regroupements)`, '_blank');

  closeErrors = () => {
    this.displayErrors = false;
  }

  startCalculation = async (refresh?: boolean) => {
    this.NoDataText = 'En cours de chargement...';
    this.loadingVisible = true;
    this.subscriptionCalcul = this.pcSvc.startCalculation(this.idPlanProduction, refresh)
      .subscribe(data => {
          const res: any = data;
          if (res.one?.reports?.length > 0)
            this.prodSvc.announceDisplayCalculCondiErrors(res.one.reports);
          this.pcSvc.getAllResults(this.idPlanProduction)
            .subscribe(data => {
              this.data = data.one.results;
              this.planProduction = data.one.planProduction;
            });
          this.loadingVisible = false;
        }
      );
  };

  previousStep = () => {
    this.routeMapSvc.goToSecondaryRoute(['steps', 'plan-production', this.idPlanProduction]);
  }

  printEditionConditionnement = (): void => {
    this.pcSvc.announcePrintConditionnement();
  }

  changeCollapseAll = () => {
    this.expandAll = !this.expandAll;
  }

}
