<dx-popup
  [showTitle]="true"
  title="Traitement de masse sur les produits"
  [(visible)]="displayDialog"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHiding)="closeDialog()"
  [fullScreen]="fullScreen"
>
  <dxi-toolbar-item
    widget="dxButton"
    location="after"
    [options]="{
            icon: 'fullscreen',
            onClick: toggleFullScreen
        }">
  </dxi-toolbar-item>
  <div class="row">
    <div class="col-12">
      <h2>Sélection des cible(s)</h2>
    </div>
  </div>
  <div class="row mg-b-15">
    <div class="col-4">
      <dx-text-box class="tagbox" [(value)]="filterLabel" placeholder="Filtrer par libellé..."></dx-text-box>
    </div>
    <div class="col-4">
      <dx-tag-box class="tagbox" [(value)]="sitesSelected" [multiline]="false" placeholder="Filtrer par site..."
                  displayExpr="libelle" valueExpr="id" [searchEnabled]="true" [dataSource]="sites"></dx-tag-box>
    </div>
    <div class="col-4">
      <dx-tag-box class="tagbox" [(value)]="typesProduitsSelected" [multiline]="false"
                  placeholder="Filtrer par type de produit..." displayExpr="libelle" [searchEnabled]="true"
                  [dataSource]="allTypesProduitsItems"></dx-tag-box>
    </div>
  </div>
  <div class="row mg-b-15">
    <div class="col-4">
      <dx-tag-box class="tagbox" [(value)]="declinaisonsSelected" [multiline]="false"
                  placeholder="Filtrer par déclinaison..." displayExpr="libelle" valueExpr="id" [searchEnabled]="true"
                  [dataSource]="allDeclinaisonsItems"></dx-tag-box>
    </div>
    <div class="col-4">
      <dx-tag-box class="tagbox" [(value)]="regimesSelected" [multiline]="false" placeholder="Filtrer par régime..."
                  displayExpr="libelle" valueExpr="id" [searchEnabled]="true"
                  [dataSource]="allRegimesItems"></dx-tag-box>
    </div>
    <div class="col-4">
      <dx-text-box class="tagbox" [(value)]="filterCode" placeholder="Filtrer par code..."></dx-text-box>
    </div>
  </div>
  <div class="row mg-b-15">
    <div class="col-12">
      <dx-drop-down-box
        id="dropFamilles"
        [(value)]="treeBoxValue"
        [dataSource]="allFamillesProduits"
        (onValueChanged)="onDropDownBoxValueChanged($event)"
        placeholder="Sélectionner une ou plusieurs famille(s) de plats..."
        fieldTemplate="fieldTemplate"
        [showClearButton]="true"
      >
        <div *dxTemplate="let info of 'fieldTemplate'">
          <dx-text-box *ngIf="treeBoxValue.length == 0" placeholder="Sélectionner une ou plusieurs famille(s) de plats..."></dx-text-box>
          <dx-tag-box
            [dataSource]="allFamillesProduits"
            [(value)]="treeBoxValue"
            [multiline]="false"
            placeholder="Sélectionner une ou plusieurs famille(s) de plats..."
            valueExpr="id"
            displayExpr="libelle"
            [openOnFieldClick]="false"
            width="100%"
            (onValueChanged)="onTagBoxValueChanged($event)"
          >
          </dx-tag-box>
        </div>
        <div *dxTemplate="let data of 'content'">
          <dx-tree-view
            #treeView
            [dataSource]="allFamillesProduits"
            dataStructure="plain"
            keyExpr="id"
            parentIdExpr="parent.id"
            selectionMode="multiple"
            showCheckBoxesMode="normal"
            [selectNodesRecursive]="true"
            displayExpr="libelle"
            [selectByClick]="true"
            (onContentReady)="onTreeViewReady($event)"
            (onItemSelectionChanged)="onTreeViewSelectionChanged($event)"
          >
          </dx-tree-view>
        </div>
      </dx-drop-down-box>
    </div>
  </div>
  <div class="row mg-b-15">
    <div class="col-12">
      <h2>Ajout de régime(s)</h2>
    </div>
  </div>
  <div class="row al mg-b-15">
    <div class="col-4">
      <dx-tag-box class="tagbox" [(value)]="regimesToAdd" [multiline]="false" placeholder="Filtrer par régime..."
                  displayExpr="libelle" valueExpr="id" [searchEnabled]="true"
                  [dataSource]="allRegimesItems"></dx-tag-box>
    </div>
    <div class="col-4"><input [(ngModel)]="optionAddRegimesOnlyMeals" [value]="true" id="ctaAddRegimesForMeals"
                              name="ctaAddRegimes" type="radio"/><label for="ctaAddRegimesForMeals"> Uniquement pour les
      produits</label></div>
    <div class="col-4"><input [(ngModel)]="optionAddRegimesOnlyMeals" [value]="false"
                              id="ctaAddRegimesForMealsAndDeclinaisons" name="ctaAddRegimes" type="radio"/><label
      for="ctaAddRegimesForMealsAndDeclinaisons"> Pour les produits et leurs déclinaisons</label></div>
  </div>
  <div class="row mg-b-15" *ngIf="!containsTypeProduitDenree()">
    <div class="col-12">
      <h2>Affectation du type de fabrication</h2>
    </div>
  </div>
  <div class="row al mg-b-15" *ngIf="!containsTypeProduitDenree()">
    <div class="col-4">
      <dx-select-box class="tagbox" [(value)]="typeToSave" placeholder="Filtrer par type de fabrication..."
                     displayExpr="libelle" valueExpr="id" [searchEnabled]="true" [dataSource]="taches"></dx-select-box>
    </div>
    <div class="col-4"><input id="ctaAddTypesFabForMeals" name="ctaAddTypesFab" [(ngModel)]="optionAddTypesFabOnlyMeals"
                              [value]="true" type="radio"/><label for="ctaAddTypesFabForMeals"> Uniquement pour les
      plats</label></div>
    <div class="col-4"><input id="ctaAddTypesFabForMealsAndDeclinaisons" name="ctaAddTypesFab"
                              [(ngModel)]="optionAddTypesFabOnlyMeals" [value]="false" type="radio"/><label
      for="ctaAddTypesFabForMealsAndDeclinaisons"> Pour les plats et leurs déclinaisons</label></div>
  </div>

  <div class="row mg-b-15">
    <div class="col-12">
      <h2>Ajout d'une famille EGAlim</h2>
    </div>
  </div>
  <div class="row al">
    <div class="col-4">
      <dx-select-box [(value)]="familleEGAlimSelected" placeholder="Filtrer par famille EGAlim..."
                  displayExpr="libelle" valueExpr="id" [searchEnabled]="true"
                  [dataSource]="allFamillesEGAlim"></dx-select-box>
    </div>
  </div>

  <div class="row">
    <div class="col-12 text-right">
      <yo-button buttonClass="cta-success" iconClass="fas fa-save" tooltip="Enregistrer" (onClick)="save()"></yo-button>
    </div>
  </div>
</dx-popup>
