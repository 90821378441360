<span id="container" *ngIf="gefAuditList">

<label
  [title]='"Synchronisé avec la GEF"'
  class="badge cursor badge-gef"
  (click)="openGefAuditDialog($event)">
        <span class="fa-layers fa-fw">
          <i class="fas fa-money-bill-transfer mg-r-5"></i>
          <span class="fa-layers-counter">GEF</span>
        </span>
  </label>
</span>
