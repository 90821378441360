export default class FactureFournisseurFilterDTO {
  ids: number[] = [];
  idsSites: number[];
  numsBill: string[];
  providerIds: number[];
  status: string[];
  page: number;
  size: number;
  sortOrder: string = null;
  statusSort: boolean = false;
  dateFactureSort: boolean= false;
  libelleSiteSort: boolean= false;
  numFactureSort: boolean= false;
  fraisPortSort: boolean= false;
  fraisPortAjustesSort: boolean= false;
  totalTTCSort: boolean= false;
  libelleFournisseurSort: boolean= false;
  totalHTSort: boolean= false;
}
