import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {LigneParametrageFacturation} from "../../../core/models/facturation/client/ligne-parametrage-facturation";
import {DxDataGridComponent} from "devextreme-angular";
import {ParametrageFacturationHierarchy} from "../../../core/models/facturation/client/parametrage-facturation-hierarchy";
import {FacturationClientService} from "../../../core/services/entities/facturation-client.service";
import {Subscription} from "rxjs";
import {UtilsService} from "../../../core/utils/utils.service";
import {NamedItem} from "../../../core/models/named-item";
import {GraphQLService} from "../../../core/services/technique/graphql.service";
import {ElementFacturableDTO} from "../../../core/dtos/ElementFacturableDTO";
import {TaxeDTO} from "../../../core/dtos/taxe-dto";
import {ParametersGenerationLignesParametrage} from "../../../core/models/facturation/client/parameters-generation-lignes-parametrage";
import {LigneParametrageFacturationChanges} from "../../../core/models/facturation/client/ligne-parametrage-facturation-changes";
import {ToastService} from "../../../core/services/technique/toast.service";
import {MSG_KEY, MSG_SEVERITY} from "../../../core/constants";
import {ToolbarPreparingEvent} from "devextreme/ui/data_grid";
import {LigneParametrageDataGridComponent} from "./ligne-parametrage-data-grid/ligne-parametrage-data-grid.component";

@Component({
  templateUrl: './facturation-client-parametrage.component.html',
  styleUrls: ['./facturation-client-parametrage.component.scss']
})
export class FacturationClientParametrageComponent implements OnInit, OnDestroy {

  subData: Subscription;
  subHierarchy: Subscription;
  subElementFacturable: Subscription;
  subTaxe: Subscription;
  subSave: Subscription;
  subToAdd: Subscription;

  // region Propriétés ajout de lignes de paramétrage

  public isAddAreaCollapsed: boolean = true;

  public cellTypes = AddZoneProperty;
  public tabs = AddZoneProperty;

  public hierarchy: ParametrageFacturationHierarchy;

  public offreAlimentaireList: NamedItem[] = [];
  public prestationList: NamedItem[] = [];
  public repasList: NamedItem[] = [];
  public regimeList: NamedItem[] = [];
  public pointFacturationList: NamedItem[] = [];
  public pointLivraisonList: NamedItem[] = [];

  public selectedOffreAlimentaireList: number[] = [];
  public selectedRepasList: number[] = [];
  public selectedRegimeList: number[] = [];
  public selectedPointFacturationList: number[] = [];
  public selectedPointLivraisonList: number[] = [];
  public selectedPrestationList: number[] = [];

  // endregion

  // region Propriétés grille de paramétrage

  @ViewChild("grid") grid : LigneParametrageDataGridComponent;

  private virtualIndex: number = 0;

  public ligneParametrageList: LigneParametrageFacturation[] = [];

  public gridChanges: LigneParametrageFacturationChanges = new LigneParametrageFacturationChanges();

  // endregion

  // region Propriétés popup de modification de masse

  public isModificationPopupDisplayed: Boolean = false;

  public elementFacturableList: ElementFacturableDTO[] = []
  public taxeList: TaxeDTO[] = []

  public selectedElementFacturable: any;
  public selectedTaxe: any;
  public selectedPrix: any;

  //endregion

  // region Propriétés popup de modification avant ajout

  @ViewChild("gridPopup") gridPopup : LigneParametrageDataGridComponent;

  public isEditLinesPopupDisplayed = false;

  public ligneToAddList: LigneParametrageFacturation[] = [];

  private gridEditChanges: LigneParametrageFacturationChanges = new LigneParametrageFacturationChanges();

  // endregion


  constructor(private facturationClientSvc: FacturationClientService,
              private graphQlSvc: GraphQLService,
              private utilsSvc: UtilsService,
              private toastSvc: ToastService) {
  }

  ngOnInit(): void {
    this.initData();
    this.initHierarchy();
    this.initElementFacturable();
    this.initTaxe();
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subData);
    this.utilsSvc.unsubscribe(this.subHierarchy);
    this.utilsSvc.unsubscribe(this.subElementFacturable);
    this.utilsSvc.unsubscribe(this.subTaxe);
    this.utilsSvc.unsubscribe(this.subSave);
    this.utilsSvc.unsubscribe(this.subToAdd);
  }

  private initData(): void {
    this.subData = this.facturationClientSvc.findAllLignesParametrage().subscribe(result => {
      if (result)
        this.ligneParametrageList = result;
    });
  }

  private initHierarchy() {
    this.subHierarchy = this.facturationClientSvc.findLigneParametrageHierarchy().subscribe(result => {
      if (result)
        this.hierarchy = result.one;
      this.hierarchy
    });
  }

  private initElementFacturable() {
    this.subElementFacturable = this.graphQlSvc.sendQuery(`
        {
            allElementsFacturables {
                id,
                libelle,
                taxe {
                    id
                }
            }
        }`
    ).subscribe(result => {
      if (result)
        this.elementFacturableList = result.allElementsFacturables;
    });
  }

  private initTaxe() {
    this.subTaxe = this.graphQlSvc.sendQuery(`
        {
            allTaxes {
                id,
                libelle,
                valeur
            }
        }`
    ).subscribe(result => {
      if (result)
        this.taxeList = result.allTaxes;
    });
  }

  expandAddArea() {
    this.isAddAreaCollapsed = false;
  }

  reduceAddArea() {
    this.isAddAreaCollapsed = true;
  }

  openModificationPopup() {
    this.selectedElementFacturable = null;
    this.selectedTaxe = null;
    this.selectedPrix = null;

    this.isModificationPopupDisplayed = true;
  }

  save() {
    this.subSave = this.facturationClientSvc.saveLignesParametrage(this.gridChanges).subscribe(result => {
      this.gridChanges.clear();
      this.mergeLines(result.resultList);
    });
  }

  saveEditedLines() {
    this.subSave = this.facturationClientSvc.saveLignesParametrage(this.gridEditChanges).subscribe(result => {
      this.gridEditChanges.clear();
      this.ligneToAddList = [];
      this.mergeLines(result.resultList);
      this.closeEditLinesPopup();
    })
  }

  private mergeLines(lines: LigneParametrageFacturation[]) {
    lines.forEach(line => {
      let index = this.ligneParametrageList.findIndex(i => i.id === line.id);

      if (index >= 0) {
        Object.assign(this.ligneParametrageList[index], line);
        this.gridChanges.revert(line);
      } else
        this.ligneParametrageList.push(line);
    });
  }

  delete() {
    let selected = this.grid.getSelectedData();
    selected.forEach(toDelete => {
      this.gridChanges.delete(toDelete);
    });
    this.ligneParametrageList = this.ligneParametrageList.filter(item => !selected.includes(item.id));
  }

  addLignesParametrage() {
    let properties: ParametersGenerationLignesParametrage = {
      offreAlimentaireIds: [...this.selectedOffreAlimentaireList],
      prestationIds: [...this.selectedPrestationList],
      repasIds: [...this.selectedRepasList],
      regimeIds: [...this.selectedRegimeList],
      pointFacturationIds: [...this.selectedPointFacturationList],
      pointLivraisonIds: [...this.selectedPointLivraisonList]
    }

    this.subToAdd = this.facturationClientSvc.prepareLignesParametrageToAdd(properties).subscribe(result => {
      if (result && !result.inError) {
        if (result.resultList?.length > 0) {
          result.resultList.forEach(line => {
            if (!line.id)
              line.id = --this.virtualIndex;
          })
          this.ligneToAddList = result.resultList;
          this.isEditLinesPopupDisplayed = true;
        }
      }
    });

  }

  onTabChange = ($event: any) => {
    switch ($event.itemIndex) {
      case AddZoneTab.OFFRE_ALIMENTAIRE:
        this.offreAlimentaireList = this.hierarchy.offresAlimentaire;
        this.updateValueOffreAlimentaire([], AddZoneProperty.OFFRE_ALIMENTAIRE);
        break;
      case AddZoneTab.CLIENT:
        this.pointFacturationList = this.hierarchy.pointsFacturation;
        this.updateValueClient([], AddZoneProperty.POINT_FACTURATION);
        break;
    }
  }

  /**
   * Permet de mettre à jour la valeur et de réinitialiser les propriétés suivantes
   */
  updateValueOffreAlimentaire = (value: number[], property: AddZoneProperty) => {
    switch (property) {
      case AddZoneProperty.OFFRE_ALIMENTAIRE:
        this.selectedOffreAlimentaireList = value;
        this.prestationList = this.initProperty(this.selectedOffreAlimentaireList, this.hierarchy.prestationsByOffreAlimentaireId);
        value = [];
      // Pas de break
      case AddZoneProperty.PRESTATION:
        this.selectedPrestationList = value;
        this.selectedRepasList = [];
        this.selectedRegimeList = [];

        this.repasList = this.initProperty(this.selectedPrestationList, this.hierarchy.repasByPrestationId);
        this.regimeList = this.initProperty(this.selectedPrestationList, this.hierarchy.regimesByPrestationId);
        this.pointFacturationList = this.initProperty(this.selectedPrestationList, this.hierarchy.pointsFacturationByPrestationId);

        value = [];
      // pas de break
      case AddZoneProperty.POINT_FACTURATION:
        this.selectedPointFacturationList = value;
        this.pointLivraisonList = this.initProperty(this.selectedPointFacturationList, this.hierarchy.pointsLivraisonByPointFacturationId);
        value = [];
      // pas de break
      case AddZoneProperty.POINT_LIVRAISON:
        this.selectedPointLivraisonList = value;
        break;
      case AddZoneProperty.REPAS:
        this.selectedRepasList = value;
        break;
      case AddZoneProperty.REGIME:
        this.selectedRegimeList = value;
        break;
    }
  }

  /**
   * Permet de mettre à jour la valeur et de réinitialiser les propriétés suivantes
   */
  updateValueClient = (value: number[], property: AddZoneProperty) => {
    switch (property) {
      case AddZoneProperty.POINT_FACTURATION:
        this.selectedPointFacturationList = value;
        this.pointLivraisonList = this.initProperty(this.selectedPointFacturationList, this.hierarchy.pointsLivraisonByPointFacturationId);
        value = [];
      // pas de break
      case AddZoneProperty.POINT_LIVRAISON:
        this.selectedPointLivraisonList = value;
        this.offreAlimentaireList = this.initProperty(this.selectedPointLivraisonList, this.hierarchy.offresAlimentaireByPointLivraison);
        value = [];
      // pas de break
      case AddZoneProperty.OFFRE_ALIMENTAIRE:
        this.selectedOffreAlimentaireList = value;
        this.prestationList = this.initProperty(this.selectedOffreAlimentaireList, this.hierarchy.prestationsByOffreAlimentaireId);
        value = [];
      // Pas de break
      case AddZoneProperty.PRESTATION:
        this.selectedPrestationList = value;
        this.repasList = this.initProperty(this.selectedPrestationList, this.hierarchy.repasByPrestationId);
        this.regimeList = this.initProperty(this.selectedPrestationList, this.hierarchy.regimesByPrestationId);
        this.selectedRegimeList = [];
        value = [];
      // Pas de break
      case AddZoneProperty.REPAS:
        this.selectedRepasList = value;
        break;
      case AddZoneProperty.REGIME:
        this.selectedRegimeList = value;
        break;
    }
  }

  private initProperty(selected: number[], source: Map<number, NamedItem[]>) {
    let properties: NamedItem[] = [];

    if (selected?.length > 0) {
      let propertyLists: NamedItem[][] = [];
      selected.forEach(element => {
        propertyLists.push(source[element]);
      });

      let propertyIds: number[] = [];

      (propertyLists as any).flatMap(i => i).forEach(item => {
        if (!propertyIds.includes(item.id)) {
          properties.push(item);
          propertyIds.push(item.id);
        }
      });
    }

    return properties;
  }

  applyModifications() {
    if (this.selectedPrix && this.selectedPrix < 0) {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, "Le prix doit être supérieur à 0")
      return;
    }

    let selectedLines = this.isEditLinesPopupDisplayed ? this.gridPopup.getSelectedData() : this.grid.getSelectedData();
    let lignes = this.isEditLinesPopupDisplayed ? this.ligneToAddList : this.ligneParametrageList;
    let changes = this.isEditLinesPopupDisplayed ? this.gridEditChanges : this.gridChanges;

    let elementFacturable = undefined;
    let taxe = undefined;

    if (this.selectedElementFacturable)
      elementFacturable = this.elementFacturableList.find(element => element.id === this.selectedElementFacturable);

    if (this.selectedTaxe)
      taxe = this.taxeList.find(taxe => taxe.id === this.selectedTaxe);

    lignes.filter(line => selectedLines.includes(line.id))
      .forEach(line => {
        if (elementFacturable) {
          line.elementFacturableId = elementFacturable.id;
          line.elementFacturableLibelle = elementFacturable.libelle
        }

        if (taxe) {
          line.taxeId = taxe.id;
          line.taxeLibelle = taxe.libelle;
        }

        if (this.selectedPrix)
          line.prixVenteHT = this.selectedPrix;

        changes.apply(line);
      });

    this.closeModificationPopup();
  }

  closeModificationPopup() {
    this.isModificationPopupDisplayed = false;
  }

  updateElementFacturable(data: any) {
    this.selectedElementFacturable = data;
    this.selectedTaxe = this.elementFacturableList.filter(elem => elem.id == data)[0].taxe.id;
  }

  updateTaxe(data: any) {
    this.selectedTaxe = data;
  }

  closeEditLinesPopup() {
    this.isEditLinesPopupDisplayed = false;
    this.gridEditChanges = new LigneParametrageFacturationChanges();
    this.ligneToAddList = [];
  }

  isRowSelected() {
    return this.grid?.getSelectedData().length > 0;
  }

  isPopupRowSelected() {
    return this.gridPopup?.getSelectedData().length > 0;
  }

  canAddValues() {
    return this.ligneToAddList.filter(ligne => ligne.elementFacturableId > 0 && ligne.taxeId > 0 && ligne.prixVenteHT > 0).length == this.ligneToAddList.length;
  }

  isReadyToAdd() {
    return this.selectedOffreAlimentaireList?.length > 0
      && this.selectedPrestationList?.length > 0
      && this.selectedRepasList?.length > 0
      && this.selectedRegimeList?.length > 0
      && this.selectedPointFacturationList?.length > 0
      && this.selectedPointLivraisonList?.length > 0;
  }

  isParentSelected(selectedList: number[]) {
    return selectedList?.length > 0;
  }

  getTaxeDisplayValue(taxe: TaxeDTO) {
    return `${taxe.libelle} - ${taxe.valeur}%`
  }
}

export enum AddZoneProperty {
  OFFRE_ALIMENTAIRE,
  PRESTATION,
  REPAS,
  REGIME,
  POINT_FACTURATION,
  POINT_LIVRAISON
}

export enum AddZoneTab {
  OFFRE_ALIMENTAIRE,
  CLIENT
}

