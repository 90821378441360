<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      Lanceurs de flux GEF
    </div>
  </dxi-item>
</dx-toolbar>

<div class="bloc-lanceur">
  <div class="row">Export des fournisseurs</div>
  <div class="row p-mb-2">
    <dx-check-box
      [(ngModel)]="importPartielFluxExportFournisseur"
      text="Import partiel ?"
      [elementAttr]="{ 'aria-label': 'Checked' }"
    ></dx-check-box>
  </div>
  <div class="row">
    <dx-button
      stylingMode="contained"
      text="Lancement"
      type="default"
      [width]="120"
      (onClick)="fluxExportFournisseurs()"
    ></dx-button>
  </div>
</div>

<div class="bloc-lanceur">
  <div class="row">Export des articles</div>
  <div class="row p-mb-2">
    <dx-check-box class="p-mr-4"
      [(ngModel)]="importPartielFluxExportArticles"
      text="Import partiel ?"
      [elementAttr]="{ 'aria-label': 'Checked' }"
    ></dx-check-box>
  </div>
  <div class="row">
    <dx-button
      stylingMode="contained"
      text="Lancement"
      type="default"
      [width]="120"
      (onClick)="fluxExportArticles()"
    ></dx-button>
  </div>
</div>

<div class="bloc-lanceur">
  <div class="row">Export des commandes fournisseurs</div>
  <div class="row p-mb-2">
    <dx-check-box class="p-mr-4"
      [(ngModel)]="importPartielFluxExportCommandesFournisseurs"
      text="Import partiel ?"
      [elementAttr]="{ 'aria-label': 'Checked' }"
    ></dx-check-box>
  </div>
  <div class="row">
    <dx-button
      stylingMode="contained"
      text="Lancement"
      type="default"
      [width]="120"
      (onClick)="fluxExportCommandesFournisseurs()"
    ></dx-button>
  </div>
</div>

<div class="bloc-lanceur">
  <div class="row">Export des bons de réception</div>
  <div class="row p-mb-2">
    <dx-check-box class="p-mr-4"
                  [(ngModel)]="importPartielFluxExportBonsReception"
                  text="Import partiel ?"
                  [elementAttr]="{ 'aria-label': 'Checked' }"
    ></dx-check-box>
  </div>
  <div class="row">
    <dx-button
      stylingMode="contained"
      text="Lancement"
      type="default"
      [width]="120"
      (onClick)="fluxExportBonsReception()"
    ></dx-button>
  </div>
</div>
