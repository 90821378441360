import {ObjectDTO} from './object-dto';
import {AdresseDTO} from './adresse-dto';
import {SecteurFournisseurDTO} from './secteurfournisseur-dto';
import {AuditedDTO} from './audited-dto';

export class FournisseurDTO extends AuditedDTO {

  parent: ObjectDTO;
  children: ObjectDTO[] = [];

  idFournisseur: number;
  libelle: string;
  adresse: AdresseDTO;
  secteurFournisseurList: SecteurFournisseurDTO[] = [];
  code: string;
  siteRef: string;

  francoDePortActif: boolean;
  francoDePortMontant: number;
  francoDePortPoids: number;
  francoDePortTva: number;
  francoDePortCout: number;

  // context Lot marche pour les lot-marche-secteur-fournisseur
  ordre: number;

  siret: string;

  emailCommande: string;
  emailFacturation1: string;
  emailFacturation2: string;
}
