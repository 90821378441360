<dx-popup
  [showTitle]="true"
  width="'auto''"
  height="'auto'"
  title="Transférer les effectifs"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [showCloseButton]="true"
  [(visible)]="displayDialog"
>
  <div class="dialog-box">
    <dx-select-box
      placeholder="Source du type d'effectif"
      [dataSource]="modes"
      displayExpr="label"
      valueExpr="value"
      [value]="typeEffectifSource"
      (onValueChanged)="changeTypeEffectifSource($event)">
    </dx-select-box>

    <div class="override-mode">
      <input id="checkbox-prev" type="checkbox" [(ngModel)]="overrideMode"
             [ngModelOptions]="{standalone: true}"/>


      <label for="checkbox-prev" style="margin-left: 5px;">Ecraser les effectifs de facturation existants</label>
    </div>


    <yo-commande-fournisseur-date-filter
      (onValueChange)="onDateFilterValueChange($event)"
      height="45px"
      [selectedDates]="selectedPeriode"
    >
    </yo-commande-fournisseur-date-filter>
  </div>

  <div class="dialog-line">
    <div class="custom-button-container-right">
      <yo-button
        label="Transférer"
        iconClass="fas fa-copy"
        class="mg-r-5 va-top"
        (onClick)="transfertEffectives()"
        tooltip="Copier">
      </yo-button>
      <yo-button
        label="Fermer"
        iconClass="fas fa-times"
        buttonClass="cta-inner-delete"
        (onClick)="closeDialog()">
      </yo-button>
    </div>
  </div>

</dx-popup>
