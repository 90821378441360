import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from "@angular/core";
import {DxLookupComponent} from "devextreme-angular";

@Component({
  selector: 'yo-lookup',
  templateUrl: './lookup.component.html',
  styleUrls: ['./lookup.component.scss']
})
export class LookUpComponent implements OnInit, OnChanges {

  @Input() data: any[];
  @Input() enableSearch: boolean = true;
  @Input() displayValue: string | Function = "libelle";
  @Input() key: string = "id";
  @Input() disabled: boolean = false;
  @Input() selectedItem: any;
  @Input() placeholder: string;
  @Input() width: any = "auto";
  @Input() autoSelect: boolean = true;
  @Input() showClearButton: boolean = false;

  @Output() onValueChange = new EventEmitter;

  @ViewChild("lookup") lookUp: DxLookupComponent;

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes?.selectedItem?.currentValue || !changes?.data?.currentValue)
      this.autoSelectData();
  }

  autoSelectData = () => {
    if ((!this.selectedItem) && this.autoSelect == true && (this.data?.length === 1)) {
      this.selectedItem = this.data[0][this.key];
      this.valueChanged({
        data: this.selectedItem
      });
    }
  }

  getSelectedItem = (): any => {
    return this.lookUp.selectedItem;
  }

  valueChanged(data) {
    this.onValueChange.emit(data.value);
  }

  displayExpr = (item): Function | string => {
    if (item) {
      if (typeof(this.displayValue) == "function")
        return this.displayValue(item);
      else
        return item[this.displayValue];
    }
  }

}
