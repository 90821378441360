<dx-load-panel
  shadingColor="rgba(0,0,0,0.4)"
  [(visible)]="loadingVisible"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  message="Calcul en cours..."
>
</dx-load-panel>

<div class="custom-toolbar">
  <div class="custom-toolbar-element custom-button-container-left">
    <yo-help lang="fr" [file]="pathFile" [width]="800"></yo-help>
    <div class="d-flex flex-row align-items-center">
      <i class="fas fa-book-reader mg-r-5 mg-l-15"></i> R&Eacute;SUM&Eacute;
    </div>
  </div>
  <div class="custom-button-container-right">
    <yo-button
      iconClass="fas fa-highlighter"
      class="mr-1"
      (onClick)="printEditionConditionnement()"
      tooltip="Générer le bon de conditionnement">
    </yo-button>
  </div>
</div>

<div>
  <label class="badge badge-secondary">
    {{ 'PLAN DE PRODUCTION - ' + planProduction | uppercase }}
  </label>
</div>

<div id="grid-container">
  <dx-data-grid
    id="tab-calcul-conditionnement-details"
    [dataSource]="data"
    keyExpr="id"
    columnResizingMode="widget"
    [allowColumnReordering]="true"
    [hoverStateEnabled]="true"
    [allowColumnResizing]="true"
    [rowAlternationEnabled]="true"
    [showRowLines]="true"
  [showBorders]="true"
    autoExpandAll="false"
  >
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-toolbar>
      <dxi-item location="before">
        <div *dxTemplate>
          <dx-button
            [text]="this.expandAll ? 'Plier' : 'Déplier'"
            width="136"
            (onClick)="changeCollapseAll()"
          >
          </dx-button>
        </div>
      </dxi-item>
      <dxi-item name="searchPanel"></dxi-item>
    </dxo-toolbar>

    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-grouping [autoExpandAll]="expandAll"></dxo-grouping>
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-pager [showPageSizeSelector]="true" [showNavigationButtons]="false" [allowedPageSizes]="[20, 50, 100, 200]"
               [visible]="true" [showInfo]="true" infoText="{2} ligne(s) de paramétrage"></dxo-pager>

    <dxi-column dataField="produit" caption="Produit"
                [filterOperations]="['contains']"
                [groupIndex]="1">
    </dxi-column>
    <dxi-column dataField="declinaison" caption="Declinaison"
                [filterOperations]="['contains']" alignment="left">
    </dxi-column>
    <dxi-column dataField="prestation" caption="Prestation"
                [filterOperations]="['contains']">
    </dxi-column>
    <dxi-column dataField="regime" caption="Régime"
                [filterOperations]="['contains']">
    </dxi-column>
    <dxi-column dataField="uniteProduction" caption="Unité de production"
                [filterOperations]="['contains']">
    </dxi-column>

    <dxi-column dataField="pointLivraison" caption="Point de livraison"
                [filterOperations]="['contains']">
    </dxi-column>
    <dxi-column dataField="repas" caption="Repas"
                [filterOperations]="['contains']" width="100px">
    </dxi-column>
    <dxi-column dataField="qteConditionnement" caption="Qté"
                [filterOperations]="['contains']" width="70px" alignment="left">
    </dxi-column>
    <dxi-column dataField="conditionnement" caption="Conditionnement"
                [filterOperations]="['contains']">
    </dxi-column>
    <dxi-column dataField="variante" caption="Variante"
                [filterOperations]="['contains']">
    </dxi-column>

    <dxi-column dataField="effectifNourri" caption="Effectif Nourri"
                [filterOperations]="['contains']">
    </dxi-column>

    <dxi-column dataField="total" caption="Total"
                [filterOperations]="['contains']" cellTemplate="totalTemplate" alignment="left">
    </dxi-column>

    <dxi-column dataField="dateConditionnement" caption="Date conditionnement" dataType="date"
                [filterOperations]="['contains']" cellTemplate="dateConditionnementTemplate">
    </dxi-column>


    <dxi-column dataField="dateFabrication" caption="Date fabrication" dataType="date"
                [filterOperations]="['contains']" cellTemplate="dateFabricationTemplate">
    </dxi-column>

    <dxi-column dataField="atelier" caption="Atelier"
                [filterOperations]="['contains']">
    </dxi-column>

    <dxi-column dataField="reste" caption="Reste" dataType="boolean" width="70px"
                [filterOperations]="['contains']">
    </dxi-column>

    <dxi-column dataField="regroupement" caption="Regroupé" dataType="boolean" width="100px"
                [filterOperations]="['contains']">
    </dxi-column>

    <div *dxTemplate="let cell of 'totalTemplate'">
      {{ utils.roundFloat(cell.value, 2) }} {{ cell.row.data.uniteConditionnement }}
    </div>


    <dxo-pager
      [showNavigationButtons]="false"
      [visible]="true"
      [showInfo]="true"
      infoText="{2} résumé(s)">
    </dxo-pager>

    <div *dxTemplate="let cell of 'dateConditionnementTemplate'">
      {{ utils.convertddMMYYYYSlashVersion(cell.row.data.dateConditionnement) }}
    </div>

    <div *dxTemplate="let cell of 'dateFabricationTemplate'">
      {{ utils.convertddMMYYYYSlashVersion(cell.row.data.dateFabrication) }}
    </div>

  </dx-data-grid>
</div>

<div id="footer-step" class="custom-button-container">
  <yo-button (onClick)="previousStep()"
             label="Précédent"
             tooltip="Plan de production"
             iconClass="fas fa-angle-left">
  </yo-button>
</div>

<yo-print-settings-editions-conditionnement
  [idPlanProduction]="idPlanProduction"></yo-print-settings-editions-conditionnement>
<yo-display-calcul-conditionnement-erreur></yo-display-calcul-conditionnement-erreur>
