import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BonCfDTO} from '../../../../core/dtos/boncfs-dto';
import {UniteDeProductionDTO} from '../../../../core/dtos/unite-de-production-dto';
import {UtilsService} from '../../../../core/utils/utils.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {GenericFormService} from '../../../../core/services/generics/generic-form.service';
import {Subscription} from 'rxjs';
import {UniteDeProduction__SecteurFournisseurDTO} from '../../../../core/dtos/unite-de-production__secteur-fournisseur-dto';
import {DATEPICKER_FR, MSG_KEY, MSG_SEVERITY} from '../../../../core/constants';
import {sortBy as _sortBy} from 'lodash';
import {UnitesDeProductionService} from '../../../../core/services/entities/unites-de-production.service';
import {CommandesService} from '../../../../core/services/gestion-commandes/commandes.service';
import {BoncfService} from '../../../../core/services/entities/boncf.service';
import {ToastService} from "../../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-create-proposition-fournisseur',
  templateUrl: './create-proposition-fournisseur.component.html',
  styleUrls: ['./create-proposition-fournisseur.component.scss']
})
export class CreatePropositionFournisseurComponent implements OnInit, OnDestroy {

  @Input() displayDialog: boolean;

  @Input() set uniteDeProductionList(value: UniteDeProductionDTO[]) {

    this._uniteDeProductionList = [];

    if(!this.utils.isCollectionNullOrEmpty(value)){
      this._uniteDeProductionList = value;
    }
    this.initForm();
  }

  @Output() onClose = new EventEmitter<boolean>();
  @Output() onSave = new EventEmitter<BonCfDTO>();

  _uniteDeProductionList: UniteDeProductionDTO[];
  secteurFournisseurList: UniteDeProduction__SecteurFournisseurDTO[];

  form: FormGroup;
  subForm: Subscription;
  fr = DATEPICKER_FR;
  disabledDays: number[] = [];
  joursSemaineFournisseur: number[];
  udpJoursLivraison: number[];

  // Délai de livraison du secteurFournisseur
  delaiLivraison = 0;
  // Heure limite de commande
  heureLimite = new Date();

  // Date minimale pour le jour de livraison
  minDate: Date;


  constructor(public utils: UtilsService,
              private gfs: GenericFormService,
              private commandesSvc: CommandesService,
              public bonCfSvc: BoncfService,
              private cd: ChangeDetectorRef,
              private udpSvc: UnitesDeProductionService,
              private toastSvc: ToastService) {}

  initForm() {
    // par defaut, on selectionne la 1ere unité de production
    let udp: UniteDeProductionDTO = this._uniteDeProductionList ? this._uniteDeProductionList[0] : undefined;

    this.form = new FormGroup({
      uniteDeProduction: new FormControl(udp, [Validators.required]),
      udpSecteurFournisseur: new FormControl(undefined, [Validators.required]),
      dateLivraison: new FormControl(new Date(), [Validators.required]),
    });

    this.form.controls['dateLivraison'].setValue(null);
    // precharger les secteurs fournisseurs à partir de l'unite de production
    this.getSecteursFournisseurs(undefined);
  }

  /**
   * Récupérer les scteurs fournisseurs de l'unite de production
   * Récupere les jours de livraison de l'unite de production
   * @param event
   */
  getSecteursFournisseurs(event) {

    // reset des valeurs
    this.form.controls['udpSecteurFournisseur'].setValue(undefined);
    this.form.controls['dateLivraison'].setValue(null);
    this.udpJoursLivraison = [];
    this.secteurFournisseurList = [];

    const udp: UniteDeProductionDTO = this.form.controls['uniteDeProduction'].value;

    if (!this.utils.isNullOrEmpty(udp)) {
      // recuperer les jours de livraison de l'unite de production
      this.udpJoursLivraison = this.udpSvc.getJoursLivraisonOfUdp(udp);

      // recuperer les secteurs fournisseurs de l'unite de production
      this.udpSvc.getSecteursFournisseurs(udp.id).subscribe(response => {
        if (!this.utils.isResponseSupplierError(response)) {
          this.secteurFournisseurList = response.resultList;
          this.secteurFournisseurList = _sortBy(this.secteurFournisseurList, 'fournisseurLibelle');
          if(this.secteurFournisseurList.length===1){
            this.form.controls['udpSecteurFournisseur'].setValue(this.secteurFournisseurList[0]);
            this.getJoursLivraisons(undefined);
          }
          this.cd.markForCheck();
        }
      });
    }
  }


  getJoursLivraisons(event) {

    this.joursSemaineFournisseur = [];
    this.delaiLivraison = 0;
    this.heureLimite = new Date();

    const secteurFournisseur: UniteDeProduction__SecteurFournisseurDTO = this.form.controls['udpSecteurFournisseur'].value;

    if (!this.utils.isNullOrEmpty(secteurFournisseur)) {
      this.delaiLivraison = secteurFournisseur.delaiLivraisonJours;
      this.heureLimite = this.utils.convertNumberDateToDate(secteurFournisseur.delaiLivraisonHeureLimite);

      this.udpSvc.getLivraisons(secteurFournisseur).subscribe(response => {
        let days = response.resultList;
        if (this.utils.isCollectionNullOrEmpty(days)) {
          days = [];
        }
        this.joursSemaineFournisseur = days.map(liv => liv.numeroJourSemaine);
        this.form.controls['dateLivraison'].setValue(null);
      });
    }
  }


  ngOnInit() {
    this.setMinDate();
    this.initForm();
  }

  closeDialog() {
    this.displayDialog = false;
    this.onClose.emit(this.displayDialog);
  }

  addPropositionCommande() {
    this.gfs.validateAllFormFields(this.form);
    if (this.form.valid) {
      const udp: UniteDeProductionDTO = this.form.controls['uniteDeProduction'].value;
      const udpSecteurFournisseur: UniteDeProduction__SecteurFournisseurDTO = this.form.controls['udpSecteurFournisseur'].value;
      const dateLivraison: Date = this.form.controls['dateLivraison'].value;

      this.commandesSvc.saveNewPropositionCommande(udp.id, udpSecteurFournisseur.idSecteurFournisseur, dateLivraison)
        .subscribe(response => {
          const pc: BonCfDTO = response.one;
          this.onSave.emit(response.one);
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Proposition de commande '${pc.numeroBcf}' créée avec succès`);
        });
    }
  }

  /**
   * Le calendrier est désactivé tant qu'un secteur fournisseur n'a pas été choisi.
   */
  isCalendarDisabled(): boolean {
     let disabled = this.utils.isNullOrEmpty(this.form.controls['udpSecteurFournisseur'].value);
     return disabled;
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subForm);
  }

  private setMinDate() {
    const date = new Date();
    date.setDate(date.getDate() - 30);
    date.setDate(1);
    this.minDate = date;
  }
}
