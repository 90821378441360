import {Component, OnInit} from "@angular/core";
import {UtilsService} from "../../../core/utils/utils.service";
import {Subscription} from "rxjs";
import {InternationalizationService} from "../../../core/services/i8n/i8n.service";
import {SiteDTO} from "../../../core/dtos/site-dto";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {FormControl, FormGroup} from "@angular/forms";
import {MSG_KEY, MSG_SEVERITY} from "../../../core/constants";
import {ToastService} from "../../../core/services/technique/toast.service";
import {RepasDTO} from "../../../core/dtos/repas-dto";
import {RepasService} from "../../../core/services/entities/repas.service";

@Component({
  selector: 'yo-meal-edition-dialog',
  templateUrl: './meal-edition-dialog.component.html',
  styleUrls: ['./meal-edition-dialog.component.scss']
})
export class MealEditionDialogComponent implements OnInit {

  meal: RepasDTO;

  dialogTitle: string;

  subOpenDialog: Subscription;

  displayDialog: boolean = false;

  sitePlaceholder: string;

  idSelectedSite: number;

  form: FormGroup;

  constructor(private utilsSvc: UtilsService,
              private repasSvc: RepasService,
              private i8nSvc: InternationalizationService,
              private auth2Svc: Auth2Service,
              private toastSvc: ToastService) {
  }

  ngOnInit() {
    this.initForm();
    this.openDialogEditionSubscription();
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subOpenDialog);
  }

  openDialogEditionSubscription = (): void => {
    this.subOpenDialog = this.repasSvc.openDialogEdition$
      .subscribe((r: RepasDTO) => {
        this.displayDialog = true;
        if (!r) {
          this.meal = new RepasDTO();
          this.dialogTitle = this.i8nSvc.getLabelFromCode("TITLE_CREATION_MEAL", null);
        } else {
          this.meal = r;
          this.dialogTitle = this.i8nSvc.getLabelFromCode("TITLE_MODIFICATION_MEAL", null);
        }
        this.initForm();
      });
  };

  initForm = (): void => {
    this.sitePlaceholder = !this.canModify() ? this.meal?.site?.libelle : 'Selectionner le site...';
    this.idSelectedSite = this.meal?.site?.id;
    this.form = new FormGroup({
      libelle: new FormControl(this.meal?.libelle),
      ordre: new FormControl(this.meal?.ordre),
      actif: new FormControl(this.meal?.actif != null ? this.meal?.actif : true),
      code: new FormControl(this.meal?.code)
    });
  };

  onChangeSite = ($event: any): void => {
    this.idSelectedSite = $event.selectedItem?.id;
  }

  canModify = () => {
    return this.repasSvc.canModify(this.meal);
  }

  save = (): void => {
    if (this.form.valid) {
      let item: RepasDTO = {...this.meal };
      item.site = {id: this.idSelectedSite } as SiteDTO;
      item.libelle = this.form.controls['libelle'].value;
      item.ordre = this.form.controls['ordre'].value;
      item.actif = this.form.controls['actif'].value;
      item.code = this.form.controls['code'].value;
      delete item.regimesAlimentaire;
      this.repasSvc.save(item)
        .subscribe(response => {
          this.repasSvc.announceMealSaved();
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Sauvegarde du repas réalisé avec succès`);
          this.closeDialog();
        });
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez compléter le formulaire`);
    }
  }

  findAllLocalSites = (): SiteDTO[] => {
    return this.auth2Svc.utilisateur.siteListLocaux;
  }

  closeDialog = (): void => {
    this.displayDialog = false;
  };

}
