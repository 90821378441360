// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]7.0-rc-11485(e86253d990)-C01/04/2025-21:32:46-B01/04/2025-21:38:36' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]7.0-rc-11485(e86253d990)-C01/04/2025-21:32:46-B01/04/2025-21:38:36",
  branch: "master",
  latestTag: "7.0-rc",
  revCount: "11485",
  shortHash: "e86253d990",
  longHash: "e86253d990febdaf5dc1922b5e379b17410ac1eb",
  dateCommit: "01/04/2025-21:32:46",
  dateBuild: "01/04/2025-21:38:36",
  buildType: "Ansible",
  } ;
