import {Component, OnDestroy} from "@angular/core";
import {GestionGefService} from "../../../core/services/gef/gestion-gef.service";
import {Subscription} from "rxjs";
import {UtilsService} from "../../../core/utils/utils.service";
import {MSG_KEY, MSG_SEVERITY} from "../../../core/constants";
import {ToastService} from "../../../core/services/technique/toast.service";
import {ResponseWrapper} from "../../../core/suppliers/wrappers/response-wrapper";

@Component({
  selector: 'yo-lanceurs-flux-gef',
  templateUrl: './lanceurs-flux-gef.component.html',
  styleUrls: ['./lanceurs-flux-gef.component.scss']
})

export class LanceursFluxGefComponent implements OnDestroy {

  importPartielFluxExportFournisseur: boolean = false;
  importPartielFluxExportArticles: boolean = false;
  importPartielFluxExportCommandesFournisseurs: boolean = false;
  importPartielFluxExportBonsReception: boolean = false;

  subFluxExportFournisseurs: Subscription;
  subFluxExportArticles: Subscription;
  subFluxExportCommandeFournisseurs: Subscription;
  subFluxExportBonsReception: Subscription;

  constructor(protected gestionGefSvc: GestionGefService,
              protected utils: UtilsService,
              protected toastSvc: ToastService) {
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subFluxExportFournisseurs);
    this.utils.unsubscribe(this.subFluxExportArticles);
    this.utils.unsubscribe(this.subFluxExportCommandeFournisseurs);
    this.utils.unsubscribe(this.subFluxExportBonsReception);
  }

  fluxExportFournisseurs() {
    this.subFluxExportFournisseurs = this.gestionGefSvc.launchFluxExportFournisseurs(this.importPartielFluxExportFournisseur)
      .subscribe(response => {
        this.handleToastBatchStatus(response);
      });
  }

  fluxExportArticles() {
    this.subFluxExportArticles = this.gestionGefSvc.launchFluxExportArticles(this.importPartielFluxExportArticles)
      .subscribe(response => {
        this.handleToastBatchStatus(response);
      });

  }

  fluxExportCommandesFournisseurs() {
    this.subFluxExportCommandeFournisseurs = this.gestionGefSvc.launchFluxExportCommandesFournisseurs(this.importPartielFluxExportCommandesFournisseurs)
      .subscribe(response => {
        this.handleToastBatchStatus(response);
      });
  }

  fluxExportBonsReception() {
    this.subFluxExportBonsReception = this.gestionGefSvc.launchFluxExportBonsReception(this.importPartielFluxExportBonsReception)
      .subscribe(response => {
        this.handleToastBatchStatus(response);
      });
  }

  handleToastBatchStatus(response: ResponseWrapper<any>) {
    const severity = response.inError ? MSG_SEVERITY.ERROR : MSG_SEVERITY.INFO;
    const message = response.inError ? `Batch terminé en erreur` : `Batch terminé`;
    this.toastSvc.displayToast(MSG_KEY.ROOT, severity, message);
  }


}
