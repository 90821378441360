import {Injectable} from '@angular/core';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {Router} from '@angular/router';
import {GenericHandler} from '../generics/generic-handler';
import {UtilsService} from '../../utils/utils.service';
import {Title} from '@angular/platform-browser';
import {Auth2Service} from '../security/auth2.service';
import {HttpClient} from '@angular/common/http';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {ObjectDTO} from '../../dtos/object-dto';
import {TextureService} from './texture.service';
import {UniteDeProduction__ReglePrefereDTO} from '../../dtos/unite-de-production__regle-prefere-dto';
import {GenericRequestSupplier, Predicat, Search} from '../../suppliers/generics/generic-request-supplier';
import {catchError} from 'rxjs/operators';
import {Observable, of, Subject} from 'rxjs';
import {PREDICAT_OPERATOR, PREDICAT_TYPE} from "../../constants";
import {HttpService} from "../technique/http.service";

export const URL_POST_CALCUL_ARTICLES_PREFERES = `dolrest/gestion-unites-de-production/calcul/articles-preferes`;
export const URL_POST_CALCUL_ARTICLES_WITH_PERIOD_PRICE = `dolrest/gestion-unites-de-production/calcul/articles-with-period-price`;
export const URL_POST_UPDATE_UDP_REGLE_PREFERE = `dolrest/gestion-unites-de-production/update/regle-prefere`;

@Injectable({
  providedIn: 'root'
})
export class UniteDeProductionReglePrefereService extends GenericHandler<UniteDeProduction__ReglePrefereDTO> {

  private subjectRefreshGridArticlesPreferes = new Subject();
  refreshGridArticlesPreferes$ = this.subjectRefreshGridArticlesPreferes.asObservable();

  constructor(utils: UtilsService,
              private gds: GenericDatagridService,
              private textureSvc: TextureService,
              private httpSvc: HttpService,
              auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title) {
    super(utils, auth2Svc, router, http, title);

  }

  createEmptyDTO(): UniteDeProduction__ReglePrefereDTO {
    return new UniteDeProduction__ReglePrefereDTO();
  }

  createUdpReglePrefere(idUniteDeProduction: number,): UniteDeProduction__ReglePrefereDTO {

    const udpRp = new UniteDeProduction__ReglePrefereDTO();
    udpRp.udpId = idUniteDeProduction;

    return udpRp;
  }


  getAllFromEnvironnement(): void {
  }

  getCreateNewObjectLabel(): string {
    return "";
  }

  getEditObjectLabel(data: ObjectDTO): string {
    return "";
  }

  getEntityName(): string {
    return "UniteDeProduction__ReglePrefere";
  }

  getFields(dto: ObjectDTO): FormFieldBaseSupplier<any>[] {
    return [];
  }

  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getOas(): boolean {
    return false;
  }

  getSort(): string[] {
    return ['code,asc'];
  }

  getTitle(): string {
    return "";
  }

  getTotalRecordsLabel(): string {
    return "";
  }


  filterByIdUniteDeProduction(idUniteDeProduction: number) {

    const grs = new GenericRequestSupplier(this.getEntityName());
    const lcEntityName = this.getEntityName().toLowerCase();

    const search = new Search();

    const predicat1 = new Predicat();
    predicat1.path = `${lcEntityName}.uniteDeProduction.id`;
    predicat1.operator = PREDICAT_OPERATOR.Equals;
    predicat1.type = PREDICAT_TYPE.Integer;
    predicat1.value = idUniteDeProduction + '';

    search.predicats = [predicat1];

    grs.search = search;

    return grs;


  }

  calculerArticlesPreferes = (udpRp: UniteDeProduction__ReglePrefereDTO, overrideModificationManuelle: boolean): Observable<any> =>
    this.httpSvc.post(`${URL_POST_CALCUL_ARTICLES_PREFERES}?overrideModificationManuelle=${overrideModificationManuelle ? 'true' : 'false'}`, udpRp);

  announceRefreshGridArticlesPreferes = () =>  this.subjectRefreshGridArticlesPreferes.next(null);

  updateReglePrefere(upReglePrefere: UniteDeProduction__ReglePrefereDTO) {
    return this.http.post(URL_POST_UPDATE_UDP_REGLE_PREFERE, upReglePrefere,).pipe(
      catchError(err => {
        console.error(err);
        // ne pas mettre le catch error, pour éviter l'erreur de timeout proxy
        return of(null);
      })
    );
  }

}
