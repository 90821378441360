import {BonAllotGroupingOrder} from "../../enums/bon-allotissement-grouping-enum";
import {BonAllotissementSortingEnum} from "../../enums/bon-allotissement-sorting-enum";

export default class BonAllotissementSettingsMl {
 idPlanProduction: number;
 grouping :BonAllotGroupingOrder;
 force: boolean;
 totalTraca: boolean;
 sortingDate: BonAllotissementSortingEnum;
 planProductionDetailsList: number[];
 displayMeal: boolean;
}
