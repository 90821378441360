<dx-popup
  [showTitle]="true"
  title="Impression de la facture"
  [(visible)]="displayDialog"
  [width]="900"
  [height]="'auto'"
  [maxHeight]="800"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHidden)="closeDialog()"
>
  <div class="settings-facture">
    <div>Commentaire :</div>
    <div class="dialog-line">
      <div class="bl-option">
        <dx-text-area
          class="textarea"
          [(ngModel)]="comment"
          placeholder="Commentaire"
        ></dx-text-area>
      </div>
    </div>
  </div>

  <div class="dialog-line">
    <dx-check-box
      [(ngModel)]="detailsOption" text="Impression avec détails"
      [value]="false">
    </dx-check-box>
  </div>
  <div class="dialog-line">
    <dx-check-box
      [(ngModel)]="logoOption" text="Impression avec logo"
      [value]="true">
    </dx-check-box>
  </div>
  <div class="custom-button-container-right">
    <yo-button label="Imprimer" iconClass="fas fa-print"
               (onClick)="print()">
    </yo-button>
    <yo-button buttonClass="cta-inner-delete" iconClass="fas fa-times" (click)="closeDialog()">
    </yo-button>
  </div>
</dx-popup>
