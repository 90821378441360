import {ObjectDTO} from "../object-dto";

export class UniteDeProductionInfoFacturationDto extends ObjectDTO {
  formeJuridiqueCapital: string;
  siret: string;
  codeApe: string;
  iban: string;
  bic: string;

}
