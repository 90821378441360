import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {GefConnectionDto} from "../../dtos/gef/gef-connection-dto";
import {Auth2Service} from "../security/auth2.service";
import {HttpService} from "../technique/http.service";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {GraphQLService} from "../technique/graphql.service";
import {ObjectDTO} from "../../dtos/object-dto";
import {GefAuditDto} from "../../dtos/gef/gef-audit-dto";

@Injectable({
  providedIn: 'root'
})
export class GestionGefService {

  URL_FLUX_GEF_EXPORT_FOURNISSEUR: String = `/dolrest/jobs/export-gef-fournisseur`;
  URL_FLUX_GEF_EXPORT_FPRODUIT_DECLINAISON: String = `/dolrest/jobs/export-gef-produit-declinaison`;
  URL_FLUX_GEF_EXPORT_COMMANDE_FOURNISSEUR: String = `/dolrest/jobs/export-gef-bon-commande-fournisseur`;
  URL_FLUX_GEF_EXPORT_BON_RECEPTION: String = `/dolrest/jobs/export-gef-bon-reception`;

  private gefConnectionFluxEditionDialog = new Subject<GefConnectionDto>();
  openGefConnectionFluxEditionDialog = this.gefConnectionFluxEditionDialog.asObservable();

  private subjectGefConnectionSaved = new Subject<GefConnectionSavedSupplier>();
  gefConnectionSaved$ = this.subjectGefConnectionSaved.asObservable();

  private subjectDisplayGefAudit = new Subject<GefAuditDto[]>();
  displayGefAudit$ = this.subjectDisplayGefAudit.asObservable();

  announceOpenGefConnectionFluxEditionDialog = (connection: GefConnectionDto) => {
    this.gefConnectionFluxEditionDialog.next(connection);
  };

  announceGefConnectionSaved = (connection: GefConnectionDto, isUpdate: boolean = false) => {
    this.subjectGefConnectionSaved.next({isUpdate, gefConnection: connection} as GefConnectionSavedSupplier);
  };

  announceOpenGefAuditDialog = (gefAuditList: GefAuditDto[]) => {
    this.subjectDisplayGefAudit.next(gefAuditList);
  };


  constructor(private auth2Svc: Auth2Service,
              private httpSvc: HttpService,
              private graphQlSvc: GraphQLService
  ) {
  }

  findAllGefConnections = (): Observable<ResponseWrapper<GefConnectionDto>> => {
    return this.httpSvc.get('/dolrest/gef/connection', null);
  }

  findAllGefProviders = (): Observable<any> => {
    return this.graphQlSvc.sendQuery(`
        {
          allGefProviders {
              id,
              libelle,
              code,
              actif
          }
        }
      `)
  }

  findAllBonCfStatus(ordre: string): Observable<any> {
    return this.graphQlSvc.sendQuery(`
        {
          allBonCfStatus(filters: {
                ordre: "ASC"
              }) {
              id,
              libelle,
              code
          }
        }
      `)
  }

  launchFluxExportFournisseurs(importPartiel: boolean): Observable<any> {
    return this.httpSvc.get(`${this.URL_FLUX_GEF_EXPORT_FOURNISSEUR}?importPartiel=${importPartiel}`, null);
  }

  launchFluxExportArticles(importPartiel: boolean): Observable<any> {
    return this.httpSvc.get(`${this.URL_FLUX_GEF_EXPORT_FPRODUIT_DECLINAISON}?importPartiel=${importPartiel}`, null);
  }

  launchFluxExportCommandesFournisseurs(importPartiel: boolean): Observable<any> {
    return this.httpSvc.get(`${this.URL_FLUX_GEF_EXPORT_COMMANDE_FOURNISSEUR}?importPartiel=${importPartiel}`, null);
  }

  launchFluxExportBonsReception(importPartiel: boolean): Observable<any> {
    return this.httpSvc.get(`${this.URL_FLUX_GEF_EXPORT_BON_RECEPTION}?importPartiel=${importPartiel}`, null);
  }

  save = (gefConnection: GefConnectionDto): Observable<ResponseWrapper<GefConnectionDto>> => {
    return this.httpSvc.post('/dolrest/gef/save', gefConnection);
  }

  canModify(element: ObjectDTO): boolean {
    if (!element || !element?.site || !element?.site.id)
      return true;
    return this.auth2Svc.isSiteLocal(element.site.id);
  }


}

export interface GefConnectionSavedSupplier {
  isUpdate: boolean;
  gefConnection: GefConnectionDto;
}
