import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UtilsService} from '../../../../core/utils/utils.service';
import {ToastService} from "../../../../core/services/technique/toast.service";
import {
  PreparationConditionnementService
} from "../../../../core/services/conditionnements/preparation-conditionnement.service";
import {Subscription} from "rxjs";
import {ProductionService} from "../../../../core/services/gestion-production/production.service";
import * as moment from "moment/moment";
import {DxListComponent} from "devextreme-angular";
import BonConditionnementSettingsMl from "../../../../core/dtos/conditionnement/bon-conditionnement-settings-ml";
import WorkshopFilterMl from "../../../../core/dtos/conditionnement/workshop-filter-ml";

@Component({
  selector: 'yo-print-settings-editions-conditionnement',
  templateUrl: './print-settings-editions-conditionnement.component.html',
  styleUrls: ['./print-settings-editions-conditionnement.component.scss']
})
export class PrintSettingsEditionsConditionnementComponent implements OnInit, OnDestroy {

  constructor(public utils: UtilsService,
              private toastSvc: ToastService,
              private preparationConditionnementSvc: PreparationConditionnementService,
              private prodSvc: ProductionService) {
  }

  readonly PAYSAGE: string = 'PAYSAGE';
  readonly PORTRAIT: string = 'PORTRAIT';
  private subDisplayDialog: Subscription;
  private subAteliers: Subscription;


  typesJours: any[] =
    [
      {text: 'Jour de consommation', value: 'jourConso'},
      {text: 'Jour de conditionnement', value: 'jourCondi'},
    ];

  datesConso: any;
  datesCondi: any;
  selectedDates: any[] = [];
  atelierSelected: any[];
  ateliers: any[] = [];

  typeJourSelected: any;
  @ViewChild("productList") listPlats: DxListComponent;
  @ViewChild("listAtelier") listAtelier: DxListComponent;
  @ViewChild("listDatesCondi") listDatesCondi: DxListComponent;
  @ViewChild("listDatesConso") listDatesConso: DxListComponent;
  @Input('idPlanProduction') idPlanProduction: number;

  displayWeight: boolean = false;

  displayDialog: boolean = false;

  displayTotal: boolean = false;

  dontSortByConsumptionDay: boolean = false;

  printDetails: boolean = false;

  formatPrinting: string = this.PORTRAIT;

  formatPrintingOptions: any[] = [{ label: 'Paysage', value: this.PAYSAGE }, { label: 'Portrait', value: this.PORTRAIT }];

  ngOnInit(): void {
    this.subDisplayDialog = this.preparationConditionnementSvc.askPrintConditionnement$
      .subscribe(
        () => {
          this.initData();
        }
      );
    this.typeJourSelected = 'jourCondi';
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subDisplayDialog)
    this.utils.unsubscribe(this.subAteliers)
  }

  closeDialog = (): void => {
    this.displayDialog = false;
    this.formatPrinting = this.PORTRAIT;
    this.printDetails = false;
    this.selectedDates = null;
    this.typeJourSelected = 'jourCondi';
  }

  print = (): void => {
    const bonCondiSettings : BonConditionnementSettingsMl = new BonConditionnementSettingsMl();
    bonCondiSettings.landscape = this.formatPrinting === this.PAYSAGE;
    bonCondiSettings.printDetails = this.printDetails;
    bonCondiSettings.mode = 'SIMPLE';
    bonCondiSettings.displayWeight = this.displayWeight;
    bonCondiSettings.dontSortByConsumptionDay = this.dontSortByConsumptionDay;
    bonCondiSettings.atelierFilter = this.listAtelier.selectedItems;
    bonCondiSettings.displayTotal = this.displayTotal
    this.typeJourSelected === 'jourConso' ? bonCondiSettings.consoDateFilter = this.selectedDates : bonCondiSettings.condiDateFilter = this.selectedDates
    this.preparationConditionnementSvc.printBonConditionnement(this.idPlanProduction, bonCondiSettings);
    this.closeDialog();
  }

  private initData() {
      this.prodSvc.getPrintSettings(this.idPlanProduction, false)
        .subscribe(response => {
          if (response) {
            const res: any = response.one;
            this.datesConso = this.convertDates(res.datesAvailable.datesConsommation);
            this.datesCondi = this.convertDates(res.datesAvailable.datesConditionnement);
            this.ateliers = res.ateliers;
          }
        });
    this.displayDialog = true;
  }

  private convertDates(datesToConvert: any) {
    let dateList: any[] = [];
    datesToConvert.sort((n1, n2) => n1 - n2);
    datesToConvert.map((dateConso, idx) => dateList.push({
      id: `${idx}`,
      label: this.utils.getFrenchDate_dddd_Do_MMMM_YYYY(moment(dateConso)),
      value: dateConso
    }));
    return dateList;
  }

  private updateAtelierList() {
    const filter = new WorkshopFilterMl();
    filter.idPlanProduction = this.idPlanProduction;
    filter.dates = this.selectedDates;
    filter.typeJour = this.typeJourSelected;
    this.subAteliers = this.prodSvc.getAteliers(filter)
      .subscribe(response => {
      if (response) {
        this.ateliers = response.one;
      }
    });
  }

  changeJourObligatoire(data: any) {
    this.selectedDates = this.typeJourSelected === 'jourConso' ? this.listDatesConso.selectedItems.map(date => date.value) : this.listDatesCondi.selectedItems.map(date => date.value)
    this.updateAtelierList()
  }

  changeSelectedJour(data: any) {
    this.typeJourSelected = data.itemData.value;
  }

  changeSelectedAtelier(data: any) {
    this.atelierSelected = data.itemData.value;
  }
}
