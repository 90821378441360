import {Component, OnInit} from "@angular/core";
import {FacturationClientService} from "../../../../core/services/entities/facturation-client.service";
import {SelectItem} from "primeng/api";
import {
  TransfertHeadcountSettingsModel
} from "../../../../core/models/facturation/transfert-headcount-settings-model";
import {ToastService} from "../../../../core/services/technique/toast.service";
import {MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";

@Component({
  selector: 'yo-transfert-effectives',
  templateUrl: './transfert-effectives.component.html',
  styleUrls: ['./transfert-effectives.component.scss']
})
export class TransfertEffectivesComponent implements OnInit {

  displayDialog = false;

  modes: SelectItem[] = [
    {label: 'Prévision', value: 1},
    {label: 'Fabrication', value: 2},
  ];

  typeEffectifSource: number = 1;

  overrideMode: boolean = false;

  selectedPeriode: Date[] = [new Date(), new Date()];

  constructor(private facturationClientSvc: FacturationClientService,
              private toastSvc: ToastService) { }

  ngOnInit(): void {
    this.facturationClientSvc.openDialogTransfertEffectives$.subscribe(() => {
      this.displayDialog = true;
    });
  }

  closeDialog = (): void => {
    this.displayDialog = false;
  }

  changeTypeEffectifSource($event) {
    this.typeEffectifSource = $event.value;
  }

  protected readonly close = close;

  transfertEffectives() {
    const transfertEffectivesSettings: TransfertHeadcountSettingsModel = new TransfertHeadcountSettingsModel();
    transfertEffectivesSettings.typeEffectifSource = this.typeEffectifSource;
    transfertEffectivesSettings.overrideMode = this.overrideMode;
    transfertEffectivesSettings.dateStart = this.selectedPeriode[0];
    transfertEffectivesSettings.dateEnd = this.selectedPeriode[1];
    this.facturationClientSvc.transfertEffectives(transfertEffectivesSettings).subscribe(() => {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, 'Les effectifs ont été transférés');
    });
  }

  onDateFilterValueChange = (data: any) => {
    this.selectedPeriode = [data[0], data[1]];
  };
}

