import {Component, OnInit} from "@angular/core";
import {UtilsService} from "../../../core/utils/utils.service";
import {Subscription} from "rxjs";
import {InternationalizationService} from "../../../core/services/i8n/i8n.service";
import {SiteDTO} from "../../../core/dtos/site-dto";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {FormControl, FormGroup} from "@angular/forms";
import {MSG_KEY, MSG_SEVERITY} from "../../../core/constants";
import {ToastService} from "../../../core/services/technique/toast.service";
import {TaxeDTO} from "../../../core/dtos/taxe-dto";
import {TaxesService} from "../../../core/services/entities/taxes.service";

@Component({
  selector: 'yo-taxe-edition-dialog',
  templateUrl: './taxe-edition-dialog.component.html',
  styleUrls: ['./taxe-edition-dialog.component.scss']
})
export class TaxeEditionDialogComponent implements OnInit {

  taxe: TaxeDTO;

  dialogTitle: string;

  subOpenDialog: Subscription;

  displayDialog: boolean = false;

  sitePlaceholder: string;

  idSelectedSite: number;

  form: FormGroup;

  constructor(private utilsSvc: UtilsService,
              private taxeSvc: TaxesService,
              private i8nSvc: InternationalizationService,
              private auth2Svc: Auth2Service,
              private toastSvc: ToastService) {
  }

  ngOnInit() {
    this.initForm();
    this.openDialogEditionSubscription();
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subOpenDialog);
  }

  openDialogEditionSubscription = (): void => {
    this.subOpenDialog = this.taxeSvc.openDialogEdition$
      .subscribe((tx: TaxeDTO) => {
        this.displayDialog = true;
        if (!tx) {
          this.taxe = new TaxeDTO();
          this.dialogTitle = this.i8nSvc.getLabelFromCode("TITLE_CREATION_TAX", null);
        } else {
          this.taxe = tx;
          this.dialogTitle = this.i8nSvc.getLabelFromCode("TITLE_MODIFICATION_TAX", null);
        }
        this.initForm();
      });
  };

  initForm = (): void => {
    this.sitePlaceholder = !this.canModify() ? this.taxe?.site?.libelle : 'Selectionner le site...';
    this.idSelectedSite = this.taxe?.site?.id;
    this.form = new FormGroup({
      libelle: new FormControl(this.taxe?.libelle),
      value: new FormControl(this.taxe?.valeur),
      actif: new FormControl(this.taxe?.actif != null ? this.taxe?.actif : true),
      code: new FormControl(this.taxe?.code)
    });
  };

  onChangeSite = ($event: any): void => {
    this.idSelectedSite = $event.selectedItem?.id;
  }

  canModify = () => {
    return this.taxeSvc.canModify(this.taxe);
  }

  save = (): void => {
    if (this.form.valid) {
      let item: TaxeDTO = {...this.taxe };
      item.site = {id: this.idSelectedSite } as SiteDTO;
      item.libelle = this.form.controls['libelle'].value;
      item.valeur = this.form.controls['value'].value;
      item.actif = this.form.controls['actif'].value;
      item.code = this.form.controls['code'].value;
      this.taxeSvc.save(item)
        .subscribe(response => {
          this.taxeSvc.announceTaxSaved();
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Sauvegarde de la taxe réalisée avec succès`);
          this.closeDialog();
        });
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez compléter le formulaire`);
    }
  }

  findAllLocalSites = (): SiteDTO[] => {
    return this.auth2Svc.utilisateur.siteListLocaux;
  }

  closeDialog = (): void => {
    this.displayDialog = false;
  };

}
