import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {GefAuditDto} from "../../../../core/dtos/gef/gef-audit-dto";
import {GestionGefService} from "../../../../core/services/gef/gestion-gef.service";


@Component({
  selector: 'yo-gef-audit-button',
  templateUrl: './gef-audit-button.component.html',
  styleUrls: ['./gef-audit-button.component.scss']
})
export class GefAuditButtonComponent {


  @Input() gefAuditList: GefAuditDto[];

  constructor(public gestionGefSvc: GestionGefService) {
  }

  openGefAuditDialog(event: Event): void {
    event.stopPropagation();
    this.gestionGefSvc.announceOpenGefAuditDialog(this.gefAuditList);
  }

}
