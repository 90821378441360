import {Component, OnDestroy, OnInit} from "@angular/core";
import {ProductionService} from "../../../../core/services/gestion-production/production.service";
import {Subscription} from "rxjs";
import {InternationalizationService} from "../../../../core/services/i8n/i8n.service";


@Component({
  selector: 'yo-display-calcul-conditionnement-erreur',
  templateUrl: './display-calcul-conditionnement-erreur.component.html',
})

export class DisplayCalculConditionnementErreurComponent implements OnInit, OnDestroy {

  constructor(private readonly prodSvc: ProductionService,
              private i8nSvc: InternationalizationService) {}

  displayErrors: boolean = false;
  errors: any[] = [];
  subErrors: Subscription;

  ngOnDestroy(): void {
    this.subErrors.unsubscribe();
  }

  ngOnInit() {
    this.subErrors = this.prodSvc.displayCalculCondiErrors$.subscribe((response) => {
      for (const [key, value] of Object.entries(response)) {
        const v: any = value;
        const argsArray = v.args ? v.args.split(",") : [];
        const error: any = this.i8nSvc.getLabelFromCode(v.code, argsArray);
        this.errors.push({error: error});
      }
      this.displayErrors = true;
    });
  }

  closeErrors() {
    this.displayErrors = false;
    this.errors = [];
  }
}
