import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Subscription} from 'rxjs';
import {FichetechniqueSupplier} from '../fichetechnique-resolver.service';
import {UtilsService} from '../../../../core/utils/utils.service';
import {ActivatedRoute} from '@angular/router';
import {FichetechniqueService} from '../../../../core/services/gestionproduits/fichetechnique.service';
import {ProduitDTO} from '../../../../core/dtos/produit-dto';
import {ProduitDeclinaisonDTO} from '../../../../core/dtos/produit-declinaison-dto';
import {ProduitnomenclatureDTO, UDM_TYPE} from '../../../../core/dtos/produitnomenclature-dto';
import {SelectItem} from 'primeng/api';
import {RoutemapService} from '../../../../core/services/routemap.service';
import {Auth2Service} from '../../../../core/services/security/auth2.service';
import {ProduitsService} from '../../../../core/services/entities/produits.service';
import {find as _find} from 'lodash'
import {GenericDatagridService} from '../../../../core/services/generics/generic-datagrid.service';
import {DialogMsgSupplier, Paragraphe} from '../../../../core/suppliers/dialog-msg-supplier';
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY, TYPE_LIST_UDP} from '../../../../core/constants';
import {saveAs as fs_saveAs} from 'file-saver';
import {MPrintFicheTechniqueDTO} from './model-print-pdf-ft';
import {MPrintFicheTechniqueComposants} from './model-print-pdf-ft-composants';
import {UniteDeProductionDTO} from '../../../../core/dtos/unite-de-production-dto';
import {UnitesDeProductionService} from '../../../../core/services/entities/unites-de-production.service';
import {ResponseWrapper} from '../../../../core/suppliers/wrappers/response-wrapper';
import {ProduitDeclinaisonService} from '../../../../core/services/entities/produit-declinaison.service';
import {ToastService} from "../../../../core/services/technique/toast.service";
import {GestionUniteProductionService} from "../../../../core/services/gestion-unites-productions/gestion-unite-production.service";

type VIEW = 'cout' | 'quantite';

@Component({
  selector: 'yo-produitsrecette',
  templateUrl: './produitsrecette.component.html',
  styleUrls: ['./produitsrecette.component.scss']
})
export class ProduitsrecetteComponent implements OnInit, OnDestroy {

  displayMap: { [key: string]: boolean } = {};
  displaySearchPanel: boolean = false;
  displayPrintPanel: boolean = false;
  displayDuplication: boolean = false;
  cols: any[] = [];
  title: string = '';

  composants: ProduitnomenclatureDTO[] = [];
  cellsMap: Map<string, ProduitnomenclatureDTO> = new Map();
  conditionnementMap: Map<string, boolean> = new Map();

  views: SelectItem[] = [
    {label: 'Quantités', value: 'quantite', icon: 'fab fa-nutritionix'},
    {label: 'Coûts', value: 'cout', icon: 'fas fa-piggy-bank'}

  ];

  // SUBSCRIPTIONS
  sub: Subscription;
  subSelectedProduitFromSearch: Subscription;
  subSaveFicheTechnique: Subscription;
  subPlatesModel: Subscription;
  subCloseAjoutComposantDialog: Subscription;
  subHasIDistri: Subscription;

  recetteNbPortions

  //CHAMPS
  yoProduit: ProduitDTO;
  idProduit: number;


  // Chargement de la sauvegarde
  loading: boolean = false;

  // cout matiere
  selectedView: VIEW = 'quantite';

  // Matrice
  colsPd: ProduitDeclinaisonDTO[]; //Plat par déclinaison
  rowsPd: ProduitDeclinaisonDTO[]; //Composante plat Denrée
  cellsPn: ProduitnomenclatureDTO[];

  // Duplication
  selectedSourceId: number;
  selectedSourceProduitDecline: ProduitDeclinaisonDTO;
  selectedTargetsId: number[] = [];

  // Print
  selectedPd: ProduitDeclinaisonDTO;

  // Unité De Production
  uniteDeProductionList: UniteDeProductionDTO[] = [];
  selectedUniteDeProduction: UniteDeProductionDTO;

  hasRightsIDistri = false;

  pathFile: string = HELP_FOLDERS.PRODUITS + '/produits-fiche-technique';

  constructor(public utils: UtilsService,
              public routeMapSvc: RoutemapService,
              private route: ActivatedRoute,
              public uniteDeProductionSvc: UnitesDeProductionService,
              private produitSvc: ProduitsService,
              private produitDecliSvc: ProduitDeclinaisonService,
              public produitsSvc: ProduitsService,
              public ficheTechSvc: FichetechniqueService,
              public auth2Svc: Auth2Service,
              private cd: ChangeDetectorRef,
              public gds: GenericDatagridService,
              private gestionUniteProductionSvc: GestionUniteProductionService,
              private toastSvc: ToastService) {
  }


  ngOnInit() {
    this.displayMap['displayFicheTechnique'] = true;
    this.initData();
    this.subCloseAjoutComposantDialog = this.ficheTechSvc.closeAjoutComposantDialog$.subscribe(response => {
      this.displaySearchPanel = false;
      this.cd.markForCheck();
    });
    this.subSelectedProduitFromSearch = this.ficheTechSvc.yoSelectedProduitDecliSearch$.subscribe(pdFromSearch => {
      this.addComponentRow(pdFromSearch, this.rowsPd, this.colsPd, this.cellsMap);
    });
    this.subHasIDistri = this.auth2Svc.hasGestionIDistri$.subscribe(hasRights => this.hasRightsIDistri = hasRights);
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.sub);
    this.utils.unsubscribe(this.subSelectedProduitFromSearch);
    this.utils.unsubscribe(this.subSaveFicheTechnique);
    this.utils.unsubscribe(this.subPlatesModel);
    this.utils.unsubscribe(this.subCloseAjoutComposantDialog);
    this.utils.unsubscribe(this.subHasIDistri);
  }

  initData = (): void => {
    this.uniteDeProductionSvc.getUniteDeProductionlist(TYPE_LIST_UDP.OnlyLocal).subscribe((response: ResponseWrapper<UniteDeProductionDTO>) => {
      if (response.resultList.length > 0) {
        this.uniteDeProductionList = response.resultList;
        if (this.utils.isNullOrEmpty(this.selectedUniteDeProduction) && !this.utils.isCollectionNullOrEmpty(this.uniteDeProductionList))
          this.selectedUniteDeProduction = this.uniteDeProductionList[0];
        this.initDataResolverSubscription();
      }
    });
  };

  initDataResolverSubscription = (): void => {
    this.sub = this.route.data.subscribe((data: { fichetechniqueSupplier: FichetechniqueSupplier }) => {
      this.idProduit = data.fichetechniqueSupplier.idProduit;
      if (data.fichetechniqueSupplier && data.fichetechniqueSupplier.idSiteContratMenu) {
        this.uniteDeProductionSvc.findByIdSite(data.fichetechniqueSupplier.idSiteContratMenu)
          .subscribe(response => {
            if (response && response.one && this.uniteDeProductionList && this.uniteDeProductionList.length) {
              const udpFound: UniteDeProductionDTO = response.one;
              const udpFoundFromList: UniteDeProductionDTO = this.uniteDeProductionList.find(udp => udp.id === udpFound.id);
              if (udpFoundFromList) this.selectedUniteDeProduction = udpFoundFromList;
              this.initDataGrid();
            }
          });
      } else if (data.fichetechniqueSupplier && data.fichetechniqueSupplier.idUdp) {
        const udpFoundFromList: UniteDeProductionDTO = this.uniteDeProductionList.find(udp => udp.id === data.fichetechniqueSupplier.idUdp);
        if (udpFoundFromList) this.selectedUniteDeProduction = udpFoundFromList;
        this.initDataGrid();
      } else {
        this.initDataGrid();
      }
    });
  };

  initDataGrid = (): Promise<void> => {
    const produit$ = this.produitSvc.getProduitById(this.idProduit);
    const colsProduitDeclinaison$ = this.produitDecliSvc.getProduitDeclinaisonListByIdProduit(this.idProduit);
    const cellsProduitNomenclature$ = this.produitDecliSvc.getProduitsdecliProduitNomenclatureFichetech(this.idProduit, this.selectedUniteDeProduction.id);
    const rowsProduitDeclinaison$ = this.produitDecliSvc.getProduitsdeclisFichetechComponent(this.idProduit, this.selectedUniteDeProduction.id);
    const modesOperatoires$ = this.produitSvc.getModesOperatoiresByProduitId(this.idProduit);

    const all$ = combineLatest([produit$, colsProduitDeclinaison$, rowsProduitDeclinaison$, cellsProduitNomenclature$, modesOperatoires$]);

    return all$.toPromise().then((data) => {

      // produit
      this.yoProduit = data[0];
      this.title = this.libelleUniteTechniqueProduit();


      //cols
      this.colsPd = data[1];
      this.colsPd = this.produitsSvc.sortListeProduitDeclinesDTO(this.colsPd);
      if (this.colsPd.length > 0) {
        this.selectedPd = this.colsPd[0]
      }
      //rows
      this.rowsPd = data[2];

      //cells from back
      this.cellsPn = data[3];

      this.cols = this.initCols(this.colsPd);
      this.cellsMap = this.initCells(this.cellsPn, this.rowsPd, this.colsPd);

      this.cellsMap.forEach((value, key) => {
        const keyConditionneAPart: string = key.split('-')[0];
        if (!this.conditionnementMap.has(keyConditionneAPart)) {
          this.conditionnementMap.set(keyConditionneAPart, value.conditionneAPart);
        }
      });

      // adapatation des quantites au nombre de personnes de la recette
      this.setPlatesNumberModelValue(this.yoProduit.recetteNbPortions);

      this.utils.setTitle('FICHE TECHNIQUE', this.yoProduit.libelle);
    });
  };

  help = (): DialogMsgSupplier => {
    let dms = new DialogMsgSupplier();
    dms.title = `Paramétrage d'un produit/plat`;
    dms.logo = 'fa fa-question-circle  yoni-color';

    let p1: Paragraphe = new Paragraphe();
    p1.title = `Fiche technique d'un <b>produit/plat</b>`;
    p1.lines = [
      `Il s’agit ici de composer vos <b>fiches techniques</b> en ajoutant des composants qui peuvent être, soit des <b>produits/denrée</b>, soit des <b>produits/plat</b>.`,
      `La quantité d’un composant est exprimé dans l’unité technique de ce composant.`,
      `En basculant en mode « Coûts » vous pourrez connaitre le prix de revient de votre <b>déclinaison</b>.`,
    ];

    dms.content = {
      intro: `Toute <b>déclinaison</b> d'un <b>produit/plat</b> permet d’ouvrir une composition de <b>fiche technique</b>.`,
      paragraphes: [p1]
    };

    return dms;
  };


  initCols = (produitsDeclinaisons: ProduitDeclinaisonDTO[]): any[] => {

    const cols = [
      {field: 'actions', header: 'Actions', tooltip: ''},
      {field: 'composants', header: 'Composants', tooltip: ''},
      {
        field: 'conditionneAPart',
        header: 'Conditionné à part',
        tooltip: 'Indique que cette denrée doit être conditionné à part'
      },
      {field: 'codes', header: 'Codes', tooltip: ''},
      {field: 'prixAchat', header: `Prix d'achat en UT`, tooltip: `Prix d'achat en Unité Technique`},
      {field: 'uniteDeMesure', header: 'Unité', tooltip: 'la quantité saisie est une unité technique'}
    ];

    if (!this.utils.isCollectionNullOrEmpty(produitsDeclinaisons)) {
      let declinaisons: any[] = produitsDeclinaisons.map(pd => ({ pdId: pd.id, libelle: pd.libelle, libelleDeclinaison: pd.declinaison.libelle }) ).sort((a, b) => a.libelleDeclinaison.localeCompare(b.libelleDeclinaison));
      declinaisons.forEach(pd => cols.push({field: pd.pdId + '', header: pd.libelleDeclinaison, tooltip: pd.libelle}));
    }

    return cols;
  };

  /**
   * Récupérer la cellule à partir de la map de cellules
   * @param idProduitUtilise
   * @param idProduitDecline
   */
  getCell = (idProduitUtilise: number, idProduitDecline: number): ProduitnomenclatureDTO => {
    const key = this.cellKey(idProduitUtilise, idProduitDecline);
    return this.cellsMap.get(key);
  };

  /**
   * Récupérer la quantite ou le volumeEnLitre d'une cellule<br>
   * Si unite de mesure = masse, on prend la quantite en kg<br>
   * Si unite de mesure = volumeEnLitre, on prend le volumeEnLitre en litre<br>
   * @param cell
   */
  getCellModelValue = (cell: ProduitnomenclatureDTO): number => {
    if (!this.utils.isNullOrEmpty(cell)) {
      return cell.quantiteEnUtTmp;
    }
    return 0;
  };

  /**
   * Mettre à jour la masse ou le volumeEnLitre d'une cellule<br>
   * @param cell
   */
  setCellModelValue: (cell: ProduitnomenclatureDTO, newVal) => void = (cell: ProduitnomenclatureDTO, newVal) => {
    if (!this.utils.isNullOrEmpty(newVal)) {
      const key = this.cellKey(cell.pdUtiliseId, cell.pdDeclineId);
      const pn = this.cellsMap.get(key);
      pn.quantiteEnUt = newVal / this.yoProduit.recetteNbPortions;
      pn.quantiteEnKg = parseFloat((pn.quantiteEnUt * pn.pdUtiliseRatioUniteTechnique).toFixed(4));
      pn.volumeEnLitre = parseFloat((cell.quantiteEnKg / cell.pdUtiliseRatioVolume).toFixed(4));
      pn.volumeEnLitreTmp = parseFloat((pn.volumeEnLitre * this.yoProduit.recetteNbPortions).toFixed(4));
      pn.quantiteEnKgTmp = parseFloat((pn.quantiteEnKg * this.yoProduit.recetteNbPortions).toFixed(4));
      pn.quantiteEnUtTmp = parseFloat((pn.quantiteEnUt * this.yoProduit.recetteNbPortions).toFixed(4));
    }
  };

  getConditionneAPartLigne = (idProduitDeclinaison: number): boolean => this.conditionnementMap.get(`${idProduitDeclinaison}`);

  setConditionneAPartValue = (idProduitDeclinaison: number, newVal) => {
    this.conditionnementMap.set(`${idProduitDeclinaison}`, newVal);
  };

  /**
   * Met à jour la cellule et renvoie sa quantite en KG
   * @param cell
   */
  public getQuantiteEnKg = (cell: ProduitnomenclatureDTO): number => {
    if (cell.pdUtiliseUdmType === UDM_TYPE.VOLUME && cell.volumeEnLitre > 0 && cell.pdUtiliseRatioVolume > 0)
      cell.quantiteEnKg = parseFloat((cell.volumeEnLitre / cell.pdUtiliseRatioVolume).toFixed(4));
    return cell.pdUtiliseRatioUniteTechnique * cell.quantiteEnUt;
  };

  public tooltipQuantiteEnKg = (produitnomenclature: ProduitnomenclatureDTO): string => {
    let response: string = '';
    if (!produitnomenclature.pdUtiliseTypeProduitFabrique) {
      if (!produitnomenclature.pdUtiliseUdmLibelle.toLowerCase().includes('kg')) {
        response +=
          produitnomenclature.quantiteEnUt +
          ' ' +
          produitnomenclature.pdUtiliseUdmLibelle + "(s)" +
          ' de ' +
          produitnomenclature.pdUtiliseRatioUniteTechnique +
          ' kg ' +
          ' soit ' +
          this.getQuantiteEnKg(produitnomenclature) +
          ' kg';
      } else {
        response += this.getQuantiteEnKg(produitnomenclature) +
          ' kg';
      }
    }
    return response;
  };

  /**
   * Met à jour la cellule et renvoie sa quantite en UT
   * @param cell
   */
  getQuantiteEnUT = (cell: ProduitnomenclatureDTO): number => {
    if (cell.pdUtiliseUdmType === UDM_TYPE.MASSE && cell.quantiteEnKg > 0 && cell.pdUtiliseRatioUniteTechnique > 0)
      cell.quantiteEnUt = parseFloat((cell.quantiteEnKg / cell.pdUtiliseRatioUniteTechnique).toFixed(4));

    if (cell.pdUtiliseUdmType === UDM_TYPE.VOLUME) {
      const quantiteEnKg = this.getQuantiteEnKg(cell);
      if (cell.quantiteEnKg > 0 && cell.pdUtiliseRatioUniteTechnique > 0)
        cell.quantiteEnUt = parseFloat((quantiteEnKg / cell.pdUtiliseRatioUniteTechnique).toFixed(4));
    }
    return cell.quantiteEnUt;
  };

  masseEnGrammes: (cell: ProduitnomenclatureDTO) => number = (cell: ProduitnomenclatureDTO): number => parseFloat((this.getQuantiteEnKg(cell) * 1000).toFixed(4));

  volumeEnMillilitres = (cell: ProduitnomenclatureDTO): number => parseFloat((cell.volumeEnLitre * 1000).toFixed(4));

  /**
   * Affichage en gramme ou en millilitres de la quantite
   * @param cell
   */
  uniteQuantiteHelper = (cell: ProduitnomenclatureDTO): string => {
    if (cell.pdUtiliseUdmType === UDM_TYPE.MASSE)
      return this.masseEnGrammes(cell) + ' g';
    else if (cell.pdUtiliseUdmType === UDM_TYPE.VOLUME)
      return this.volumeEnMillilitres(cell) + ' ml';
    return '';
  };

  initCells(cellsFromBack: ProduitnomenclatureDTO[], rows: ProduitDeclinaisonDTO[], cols: ProduitDeclinaisonDTO[]): Map<string, ProduitnomenclatureDTO> {


    const cellsMap: Map<string, ProduitnomenclatureDTO> = new Map();

    if (!this.utils.isCollectionNullOrEmpty(rows) && !this.utils.isCollectionNullOrEmpty(cols)) {

      for (let row of rows) {
        for (let col of cols) {

          // on recherche si la cellule existe coté back
          let cell = this.ficheTechSvc.initFromBackProduitNomenclature(cellsFromBack, row, col);

          // sinon on la créé
          if (this.utils.isNullOrEmpty(cell)) {
            cell = this.ficheTechSvc.initNewProduitNomenclature(col, row, 1);
          }

          const key = this.cellKey(row.id, col.id);
          cellsMap.set(key, cell);

        }
      }
    }
    return cellsMap;
  }

  /**
   * On met à jour l'ordre de la recette si des lignes sont interverties
   * @param $event
   */
  onRowReorder = (rows: ProduitDeclinaisonDTO[], cellsMap: Map<string, ProduitnomenclatureDTO>, $event: number) => {

    if (!this.utils.isCollectionNullOrEmpty(rows)) {

      let ordre = 1;
      for (let pdUtilise of rows) {

        cellsMap.forEach((value, key) => {

          if (value.pdUtiliseId === pdUtilise.id) {
            value.ordre = ordre;
          }
        });

        ordre++;

      }
    }
  };

  addComponentRow = (pdFromSearch: ProduitDeclinaisonDTO, rowsPd: ProduitDeclinaisonDTO[], colsPd: ProduitDeclinaisonDTO[], cellsMap: Map<string, ProduitnomenclatureDTO>) => {

    let exists = false;
    if (!this.utils.isCollectionNullOrEmpty(rowsPd)) {
      const elts = rowsPd.filter(item => item.id === pdFromSearch.id);
      if (!this.utils.isCollectionNullOrEmpty(elts)) {
        exists = true;
      }
    }

    if (exists)
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `${pdFromSearch.libelle} existe déjà dans la recette`);
    else {

      rowsPd.unshift(pdFromSearch);

      for (let col of colsPd) {

        const cellKey = this.cellKey(pdFromSearch.id, col.id);
        const newPn = this.ficheTechSvc.initNewProduitNomenclature(col, pdFromSearch, 0);
        cellsMap.set(cellKey, newPn);

      }

      // on relance le calcul de l'ordre sur la map de cellules
      this.onRowReorder(rowsPd, cellsMap, 1);
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `${pdFromSearch.libelle} ajouté à la recette`);
    }
  };

  removeComponent = (rowData: ProduitDeclinaisonDTO) => {

    // mise à jour des cellules
    if (!this.utils.isNullOrEmpty(rowData)) {

      for (let row of this.rowsPd) {
        for (let col of this.colsPd) {

          if (row.id === rowData.id) {
            const cellKey = this.cellKey(row.id, col.id);
            this.cellsMap.delete(cellKey);
          }
        }
      }

      // mise à jour des lignes
      this.rowsPd = this.rowsPd.filter(item => item.id != rowData.id);

      // recalculer l'ordre
      this.onRowReorder(this.rowsPd, this.cellsMap, 1);
      const summary = `Composant ${rowData.libelle} mis à la corbeille. Il sera réellement supprimé si vous enregistrez la fiche technique.`;
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, summary);
    }
  };

  /**
   * Modification du nombre de personnes de la recette, recalcul de l'affichage en consequence
   * @param $event
   */
  setPlatesNumberModelValue = ($event: number) => {

    // minimum 1 personne
    this.yoProduit.recetteNbPortions = $event && $event > 1 ? $event : 1;

    this.cellsMap.forEach((value, key) => {

      value.quantiteEnKgTmp = parseFloat((value.quantiteEnKg * this.yoProduit.recetteNbPortions).toFixed(4));
      value.quantiteEnUtTmp = parseFloat((value.quantiteEnUt * this.yoProduit.recetteNbPortions).toFixed(4));
      value.volumeEnLitreTmp = parseFloat((value.volumeEnLitre * this.yoProduit.recetteNbPortions).toFixed(4));

    });
  };

  /**
   * Dupliquer une recette convive sur les autres convives
   * @param idProduitDecline
   */
  duplicate = (selectedProduitDecline: ProduitDeclinaisonDTO, selectedTargetsId: number[]) => {

    const idProduitDecline = selectedProduitDecline.id;

    for (let col of this.colsPd) {

      if (col.id === idProduitDecline) {

        for (let row of this.rowsPd) {

          const keyOrig = this.cellKey(row.id, col.id);
          const pnOrig = this.cellsMap.get(keyOrig);

          for (let targetId of selectedTargetsId) {

            if (targetId != col.id) {
              const keyDup = this.cellKey(row.id, targetId);
              const pnDup = this.cellsMap.get(keyDup);
              pnDup.volumeEnLitre = pnOrig.volumeEnLitre;
              pnDup.quantiteEnUt = pnOrig.quantiteEnUt;
              pnDup.quantiteEnKg = pnOrig.quantiteEnKg;
              pnDup.quantiteEnKgTmp = pnOrig.quantiteEnKgTmp;
              pnDup.quantiteEnUtTmp = pnOrig.quantiteEnUtTmp;
              pnDup.volumeEnLitreTmp = pnOrig.volumeEnLitreTmp;
              pnDup.conditionneAPart = pnOrig.conditionneAPart;
            }
          }
        }
      }
    }
    this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Duplication de ${selectedProduitDecline.declinaison.libelle} terminé. La modification sera réellement prise en compte lorsque vous aurez enregistré la recette.`);
  };

  /**
   * udp prix d'achat (en UT) * quantiteEnUt du composant
   * @param cell
   * @return {number}
   */
  getPrixAchatComposant = (cell: ProduitnomenclatureDTO, row: ProduitDeclinaisonDTO): number => {

    if (row.prixAchat) {
      cell.prixUdpUtQuantite = row.prixAchat * cell.quantiteEnUt;
      return parseFloat(cell.prixUdpUtQuantite.toFixed(4));
    }

    return 0;

  };

  /**
   * Cout unitaire d'une déclinaison de produit<br>
   *     Somme des prix d'achat udp (ut) * quantiteEnUt de chaque composant de la déclinaison de produit
   * @param col produitdecline (colonne)
   */
  getCoutProduitDecline = (col: ProduitDeclinaisonDTO) => {

    let coutPd = 0;

    this.cellsMap.forEach((value, key) => {
      if (value.pdDeclineId === col.id) {
        coutPd += value.prixUdpUtQuantite;
      }
    });
    return coutPd;
  };

  /**
   * Changer l'affichage en cout matiere ou saisie quantité
   * @param $event
   */
  setViewModelValue = ($event: VIEW) => {

    this.selectedView = $event;

  };

  enableDisplaySearchPanel = () => {
    this.displaySearchPanel = true;
  };

  onHideSearchPanel = () => {
    this.displaySearchPanel = false;
  };

  onHidePrintPanel = () => {
    this.displayPrintPanel = false;
  };

  enableDisplayDuplication = (idProduitDecline: number) => {


    this.displayDuplication = true;

    this.selectedSourceId = +idProduitDecline;
    this.selectedSourceProduitDecline = this.getSourceProduitDecline(+idProduitDecline);
    this.selectedTargetsId = [];

    const selectedTargets = this.getTargetsCopy(this.selectedSourceProduitDecline);
    if (!this.utils.isCollectionNullOrEmpty(selectedTargets)) {
      selectedTargets.forEach(target => this.selectedTargetsId.push(target.id));
    }
  };

  onHideDuplication = () => {
    this.displayDuplication = false;
  };

  getSourceProduitDecline = (idProduitDecline: number) => {
    const source = _find(this.colsPd, {'id': idProduitDecline});

    return source;
  };

  getTargetsCopy = (source: ProduitDeclinaisonDTO): ProduitDeclinaisonDTO[] => {

    const targets: ProduitDeclinaisonDTO[] = [];

    if (!this.utils.isNullOrEmpty(source)) {
      for (let col of this.colsPd) {
        if (col.id != source.id) {
          targets.push(col);
        }
      }
    }

    return targets;
  };

  openFicheTechnique = (pd: ProduitDeclinaisonDTO) => {


    if (pd.fabrique) {
      this.routeMapSvc.goToSecondaryRoute(['gestionproduits', 'produit', pd.fabrique, pd.produitId || pd.produit.id, 'fichetechnique']);
    } else {
      this.routeMapSvc.goToSecondaryRoute(['gestionproduits', 'produit', pd.fabrique, pd.produitId || pd.produit.id, 'ficheidentite']);
    }

  };

  /**
   * Sauvegarde de la fiche technique.
   */
  save = () => {
    const produitsNomenclatures: ProduitnomenclatureDTO[] = [];
    if (this.cellsMap) {
      this.cellsMap.forEach((value, key) => {
        const pdId = key.split('-')[0];
        const clonePn: ProduitnomenclatureDTO = {...value};
        if (this.conditionnementMap.has(pdId)) {
          clonePn.conditionneAPart = this.conditionnementMap.get(pdId);
        } else {
          clonePn.conditionneAPart = false;
        }

        if(value.subtitution) {
          clonePn.quantiteEnKg = value.quantiteEnKgOriginal;
          clonePn.quantiteEnUt = value.quantiteEnUtOriginal;
        }

        produitsNomenclatures.push(clonePn);
      });
    }

    //sauvegarde des produitNomeclature
    this.ficheTechSvc.save(this.yoProduit, produitsNomenclatures).subscribe(response => {
      // Sauvegarde des produitDeclinaison (plat declinaison)
      this.produitDecliSvc.saveProduitDeclinaisonList(this.colsPd).subscribe( response => {
        this.calculerPrixPlat();
      });
    });

  };

  calculerPrixPlat = (): void => {
    this.gestionUniteProductionSvc.computePriceMealByIdsProductFromUdp(this.selectedUniteDeProduction.id, [this.idProduit])
      .subscribe(response => {
        this.loading = false;
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Recette enregistrée`);
      });
  };


  /**
   * Indique si le produit this.yoProduit peut être modifié.
   */
  canModify = (): boolean => {
    if (!this.utils.isNullOrEmpty(this.yoProduit)) {
      return this.auth2Svc.isSiteLocal(this.yoProduit.site.id);
    }
  };

  private cellKey = (idProduitUtilise: number, idProduitDecline: number): string => idProduitUtilise + '-' + idProduitDecline;

  classDeplacerComponent = (): string => {
    let response: string = 'fa fa-bars cursor margin-right-10';
    if (!this.gds.canModify(this.yoProduit)) {
      response += ' ';
      response += 'deplacercomposantInterdit';
    } else {
      response += ' ';
      response += 'deplacercomposantAutorise';
    }

    return response;
  };

  /**
   * Renvoie le libellé de l'Unité technique du {@link ProduitDTO} yoProduit.
   */
  libelleUniteTechniqueProduit = () => {
    if (!this.utils.isNullOrEmpty(this.yoProduit) && !this.utils.isNullOrEmpty(this.yoProduit.uniteDeMesure)) {
      return this.yoProduit.uniteDeMesure.libelle;
    }
  };

  imprimerFicheTechnique = () => {
    this.displayPrintPanel = true;
  };

  copyRawWeightToNetWeight = (): void => {
    this.colsPd.forEach(produitDecline => produitDecline.totalPoidsNetRecetteUT = this.getPoidsBrut(produitDecline));
  }

  exportPdf = () => {

    const printPdfFtDto: MPrintFicheTechniqueDTO = new MPrintFicheTechniqueDTO();

    printPdfFtDto.libelle = this.yoProduit.libelle;
    printPdfFtDto.libelleDeclinaison = this.selectedPd.declinaison.libelle;
    printPdfFtDto.nombreUT = this.yoProduit.recetteNbPortions;
    printPdfFtDto.libelleUT = this.libelleUniteTechniqueProduit();
    printPdfFtDto.dlc = '' + this.selectedPd.dlc;
    printPdfFtDto.printFicheTechniqueComposantList = [];
    printPdfFtDto.modeoperatoire = this.selectedPd.modeOperatoire;

    let poidsTotal = 0;
    let poidsTotalKg = 0;
    // let coutTotal = 0;

    for (const produitDeclinaisonDTO of this.rowsPd) {
      let modelPrintPdfFtComposants: MPrintFicheTechniqueComposants = new MPrintFicheTechniqueComposants();
      let cell = this.getCell(produitDeclinaisonDTO.id, this.selectedPd.id);
      modelPrintPdfFtComposants.composant = produitDeclinaisonDTO.libelle;
      modelPrintPdfFtComposants.code = produitDeclinaisonDTO.code;
      modelPrintPdfFtComposants.unite = cell.pdUtiliseUdmLibelle;
      modelPrintPdfFtComposants.quantite = Math.round(cell.quantiteEnUt * printPdfFtDto.nombreUT * 10000) / 10000;
      modelPrintPdfFtComposants.quantiteKg = Math.round(this.getQuantiteEnKg(cell) * printPdfFtDto.nombreUT * 10000) / 10000;
      poidsTotal += modelPrintPdfFtComposants.quantite;
      poidsTotalKg += modelPrintPdfFtComposants.quantiteKg;

      printPdfFtDto.printFicheTechniqueComposantList.push(modelPrintPdfFtComposants);
    }

    // printPdfFtDto.coutTotal = coutTotal;
    printPdfFtDto.poidsTotal = Math.round(poidsTotal * 10000) / 10000;
    printPdfFtDto.poidsTotalKg = Math.round(poidsTotalKg * 10000) / 10000;

    this.produitsSvc.printFicheTechnique(printPdfFtDto).subscribe(response => {
      let blob = new Blob([response], {type: 'application/pdf'});
      fs_saveAs(blob, 'fiche_technique.pdf');
      this.onHidePrintPanel();
    });
  };

  onChangeSelectedUniteDeProduction = (event: any) => {
    this.initDataGrid();
  };

  getPoidsBrut = (produitDecli: ProduitDeclinaisonDTO) => {
    let poidBrut = 0;

    this.cellsMap.forEach((value, key) => {
      if (value.pdDeclineId === produitDecli.id) {
        if (value.pdDeclineRatioUT === 0) {
          value.isErreurPrix = true
          value.messageErreurPrix = "Attention le ratio UT est à 0, cela fausse le calcul"
        }
        poidBrut += this.masseEnGrammes(value);
      }
    });
    return poidBrut;
  };

  seTotalPoidsNetRecetteUt = (pdDecline: ProduitDeclinaisonDTO, event) => {
    this.colsPd.forEach(pd => {
      if (pd.id === pdDecline.id) {
        pd.totalPoidsNetRecetteUT = event;
      }
    });
  };
}
