<dx-popup
  [showTitle]="true"
  [title]="title"
  [(visible)]="displayDialog"
  width="70vw"
  height="auto"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHidden)="closeDialog(false)"
>
  <dx-scroll-view
    id="scrollview"
    showScrollbar="always"
  >
    <form *ngIf="filiale" (ngSubmit)="initSaveFiliale()" id="filialeForm" #filialeForm="ngForm">
      <div class="row">
        <div class="col">
          <div class="title-ca">Informations générales</div>
          <div class="container-fournisseur">
            <div class="dialog-line-equal">
              <div class="label" title="site">Site <span class="danger-color">*</span></div>
              <div class="value">
                <yo-lookup
                  *ngIf="isCreateMode"
                  #siteLookup
                  [data]="sitesList"
                  [selectedItem]="selectedSite"
                >
                </yo-lookup>
                <dx-text-box
                  *ngIf="!isCreateMode"
                  readOnly="true"
                  [(ngModel)]="filiale.site.libelle"
                  #site="ngModel"
                  name="site"
                >
                </dx-text-box>
              </div>
            </div>

            <div class="dialog-line-equal">
              <div class="label" title="Libellé">Libellé <span class="danger-color">*</span></div>
              <div class="value">
                <dx-text-box
                  [readOnly]="!canEdit()"
                  required
                  class='inputfield'
                  [(ngModel)]="filiale.libelle"
                  #libelle="ngModel"
                  name="libelle"
                >
                </dx-text-box>
              </div>
            </div>

            <div class="dialog-line-equal">
              <div class="label" title="Code">Code <span class="danger-color">*</span></div>
              <div class="value">
                <dx-text-box
                  [required]="!isCreateMode"
                  [readOnly]="!canEdit()"
                  class='inputfield'
                  [(ngModel)]="filiale.code"
                  #code="ngModel"
                  name="code"
                >
                </dx-text-box>
              </div>
            </div>

            <div class="dialog-line-equal">
              <div class="label" title="Siret">Siret</div>
              <div class="value">
                <dx-text-box
                  [readOnly]="!canEdit()"
                  class='inputfield'
                  [(ngModel)]="filiale.siret"
                  #siret="ngModel"
                  name="siret"
                >
                </dx-text-box>
              </div>
            </div>

            <div class="dialog-line-equal actif-container">
              <div class="label" title="Actif">Actif</div>
              <div class="value">
                <dx-check-box
                  [readOnly]="!canEdit()"
                  [(ngModel)]="filiale.actif"
                  #actif="ngModel"
                  name="actif"
                >
                </dx-check-box>
              </div>
            </div>

            <div class="dialog-line-equal">
              <div class="label" title="Secteur">Secteur <span class="danger-color">*</span></div>
              <div class="value">
                <yo-tagbox
                  [readOnly]="!canEdit()"
                  #secteursTagbox
                  [data]="secteurList"
                  [selectedItems]="editSelectedSecteurs"
                >
                </yo-tagbox>
              </div>
            </div>

            <div class="dialog-line-equal">
              <div class="label text-nowrap" title="Frais de port">Frais de port</div>
              <div class="value">
                <dx-check-box
                  [readOnly]="!canEdit()"
                  [(ngModel)]="filiale.francoDePortActif"
                  #francoDePortActif="ngModel"
                  name="francoDePortActif"
                >
                </dx-check-box>
              </div>
            </div>

            <div *ngIf="filiale.francoDePortActif">
              <div class="dialog-line-equal">
                <div class="label text-nowrap" title="Coût des frais de port">Coût des frais de port</div>
                <div class="value">
                  <div class="input-group input-group-sm">
                    <dx-text-box
                      [readOnly]="!canEdit()"
                      class='inputfield'
                      [(ngModel)]="filiale.francoDePortCout"
                      #francoDePortCout="ngModel"
                      name="francoDePortCout"
                    >
                    </dx-text-box>
                    <div class="input-group-append ">
                      <span class="input-group-text">€</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="dialog-line-equal">
                <div class="label text-nowrap" title="Montant HT minimal">Montant HT minimal</div>
                <div class="value">
                  <div class="input-group input-group-sm">
                    <dx-text-box
                      [readOnly]="!canEdit()"
                      class='inputfield'
                      [(ngModel)]="filiale.francoDePortMontant"
                      #francoDePortMontant="ngModel"
                      name="francoDePortMontant"
                    >
                    </dx-text-box>
                    <div class="input-group-append ">
                      <span class="input-group-text">€</span>
                    </div>
                  </div>
                </div>
              </div>


              <div class="dialog-line-equal">
                <div class="label text-nowrap" title="Poids minimal">Poids minimal</div>
                <div class="value">
                  <div class="input-group input-group-sm">

                    <dx-text-box
                      [readOnly]="!canEdit()"
                      class='inputfield'
                      [(ngModel)]="filiale.francoDePortPoids"
                      #francoDePortPoids="ngModel"
                      name="francoDePortPoids"
                    >
                    </dx-text-box>
                    <div class="input-group-append ">
                      <span class="input-group-text">KG</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="dialog-line-equal">
                <div class="label text-nowrap" title="TVA frais de port">TVA frais de port</div>
                <div class="value">

                  <div class="input-group input-group-sm">
                    <dx-text-box
                      [readOnly]="!canEdit()"
                      class='inputfield'
                      [(ngModel)]="filiale.francoDePortTva"
                      #francoDePortTva="ngModel"
                      name="francoDePortTva"
                    >
                    </dx-text-box>
                    <div class="input-group-append ">
                      <span class="input-group-text">%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="title-ca">Adresse</div>
          <div class="container-fournisseur">
            <div class="dialog-line-equal">
              <div class="label" title="Nom de l'adresse">Nom de l'adresse</div>
              <div class="value">
                <dx-text-box
                  [readOnly]="!canEdit()"
                  class='inputfield'
                  [(ngModel)]="filiale.adresse.nom"
                  #nom="ngModel"
                  name="nom"
                >
                </dx-text-box>
              </div>
            </div>

            <div class="dialog-line-equal">
              <div class="label text-nowrap" title="Adresse 1">Adresse 1</div>
              <div class="value">
                <dx-text-box
                  [readOnly]="!canEdit()"
                  class='inputfield'
                  [(ngModel)]="filiale.adresse.adresse1"
                  #adresse1="ngModel"
                  name="adresse1"
                >
                </dx-text-box>
              </div>
            </div>

            <div class="dialog-line-equal">
              <div class="label text-nowrap" title="Adresse 2">Adresse 2</div>
              <div class="value">
                <dx-text-box
                  [readOnly]="!canEdit()"
                  class='inputfield'
                  [(ngModel)]="filiale.adresse.adresse2"
                  #adresse2="ngModel"
                  name="adresse2"
                >
                </dx-text-box>
              </div>
            </div>

            <div class="dialog-line-equal">
              <div class="label text-nowrap" title="Adresse 3">Adresse 3</div>
              <div class="value">
                <dx-text-box
                  [readOnly]="!canEdit()"
                  class='inputfield'
                  [(ngModel)]="filiale.adresse.adresse3"
                  #adresse3="ngModel"
                  name="adresse3"
                >

                </dx-text-box>
              </div>
            </div>

            <div class="dialog-line-equal">
              <div class="label text-nowrap" title="Code Postal">Code Postal</div>
              <div class="value">
                <dx-text-box
                  [readOnly]="!canEdit()"
                  class='inputfield'
                  [(ngModel)]="filiale.adresse.codePostal"
                  #codePostal="ngModel"
                  name="codePostal"
                >
                </dx-text-box>
              </div>
            </div>

            <div class="dialog-line-equal">
              <div class="label" title="Ville">Ville</div>
              <div class="value">
                <dx-text-box
                  [readOnly]="!canEdit()"
                  class='inputfield'
                  [(ngModel)]="filiale.adresse.ville"
                  #ville="ngModel"
                  name="ville"
                >
                </dx-text-box>
              </div>
            </div>

            <div class="dialog-line-equal">
              <div class="label" title="Téléphone">Téléphone</div>
              <div class="value">
                <dx-text-box
                  [readOnly]="!canEdit()"
                  class='inputfield'
                  [(ngModel)]="filiale.adresse.telephone"
                  #telephone="ngModel"
                  name="telephone"
                >
                </dx-text-box>
              </div>
            </div>

            <div class="dialog-line-equal">
              <div class="label" title="Fax">Fax</div>
              <div class="value">
                <dx-text-box
                  [readOnly]="!canEdit()"
                  class='inputfield'
                  [(ngModel)]="filiale.adresse.fax"
                  #fax="ngModel"
                  name="fax"
                >
                </dx-text-box>
              </div>
            </div>

            <div class="dialog-section">
              <span>Envoi des commandes</span>
              <div class="dialog-line-equal">
                <div class="label" title="Email 1">Email 1</div>
                <div class="value">
                  <dx-text-box
                    [readOnly]="!canEdit()"
                    class='inputfield'
                    [(ngModel)]="filiale.adresse.email"
                    #email="ngModel"
                    name="email"
                  >
                  </dx-text-box>
                </div>
              </div>
              <div class="dialog-line-equal">
                <div class="label" title="Email 2">Email 2</div>
                <div class="value">
                  <dx-text-box
                    [readOnly]="!canEdit()"
                    class='inputfield'
                    [(ngModel)]="filiale.emailCommande"
                    #email="ngModel"
                    name="emailCommande"
                  >
                  </dx-text-box>
                </div>
              </div>
            </div>
            <div class="dialog-section">
              <span>Envoi des factures et avoirs</span>
              <div class="dialog-line-equal">
                <div class="label" title="Email 1">Email 1</div>
                <div class="value">
                  <dx-text-box
                    [readOnly]="!canEdit()"
                    class='inputfield'
                    [(ngModel)]="filiale.emailFacturation1"
                    #email="ngModel"
                    name="emailFacturation1"
                  >
                  </dx-text-box>
                </div>
              </div>
              <div class="dialog-line-equal">
                <div class="label" title="Email 2">Email 2</div>
                <div class="value">
                  <dx-text-box
                    [readOnly]="!canEdit()"
                    class='inputfield'
                    [(ngModel)]="filiale.emailFacturation2"
                    #email="ngModel"
                    name="emailFacturation2"
                  >
                  </dx-text-box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="custom-button-container-right">
        <yo-button *ngIf="canEdit()"
                   buttonClass="cta-success"
                   (onClick)="initSaveFiliale()"
                   iconClass="fas fa-save"
                   [disabled]="!isFormValid()">

        </yo-button>
        <yo-button iconClass="fas fa-times"
                   buttonClass="cta-delete"
                   (onClick)="closeDialog(false)">

        </yo-button>
      </div>
    </form>
  </dx-scroll-view>
</dx-popup>
