import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'yo-devextreme-date-dispo',
  templateUrl: './devextreme-date-dispo.component.html',
  styleUrls: ['./devextreme-date-dispo.component.scss']
})
export class DevextremeDateDispoComponent implements OnInit {

  @Input() dateCreationMenu :boolean;
  @Input() dateSaisieEffectif: boolean;
  @Input() dateFacturation: boolean;

  @Input() cellData: any;

  constructor() { }

  ngOnInit() {
  }

}
