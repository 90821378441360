import {Injectable} from "@angular/core";
import {HttpService} from "../technique/http.service";
import {UtilsService} from "../../utils/utils.service";
import {HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {UniteDeProduction__ReglePrefereDTO} from "../../dtos/unite-de-production__regle-prefere-dto";
import {URL_POST_CALCUL_ARTICLES_WITH_PERIOD_PRICE} from "../entities/unite-de-production__regle-prefere.service";
import {UniteDeProductionDTO} from "../../dtos/unite-de-production-dto";

export const URL_POST_COMPUTE_PRICE_MEAL_FROM_UDP = `dolrest/gestion-unites-de-production/compute-price-meal-from-udp`;
export const URL_POST_COMPUTE_PRICE_MEAL_BY_PRODUCTS_FROM_UDP = `dolrest/gestion-unites-de-production/compute-price-meal-by-products-from-udp`;

@Injectable({
  providedIn: 'root'
})
export class GestionUniteProductionService{

  constructor(private httpSvc: HttpService, private utils: UtilsService) {
  }

  /**
   * Lance la mise à jour des prix des plats pour une unité de production
   * @param idUdp
   */
  computePriceMealFromUdp(idUdp: number){

    const params = new HttpParams()
      .set('idUdp', idUdp + '')
      .set('mailing', true);

    return this.httpSvc.post(URL_POST_COMPUTE_PRICE_MEAL_FROM_UDP, params);
  }

  /**
   * Lance la mise à jour des prix d'un plat pour une unité de production
   * @param idUdp
   * @param idsProduit
   */
  computePriceMealByIdsProductFromUdp = (idUdp: number, idsProduit: number[]): Observable<any> => this.httpSvc.post(`${URL_POST_COMPUTE_PRICE_MEAL_BY_PRODUCTS_FROM_UDP}?idUdp=${idUdp}&idsProduit=${idsProduit}`, null);

  /**
   * Lance la mise à jour des prix des articles selon la période de prix du jour
   * @param udpRp
   */
  calculerArticlesWithPeriodPrice = (idUdp: number): Observable<any> =>
    this.httpSvc.post(`${URL_POST_CALCUL_ARTICLES_WITH_PERIOD_PRICE}?idUdp=${idUdp}`, null);

}
