import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {UtilsService} from "../../../../../core/utils/utils.service";
import {ToastService} from "../../../../../core/services/technique/toast.service";
import {FacturationClientService} from "../../../../../core/services/entities/facturation-client.service";
import {FactureClientSettingsML} from "../../../../../core/dtos/facturation/clients/factures/facture-client-settings-ml";
import {Subscription} from "rxjs";
import {FactureClientDto} from "../../../../../core/dtos/facturation/clients/factures/facture-client-dto";

@Component({
  selector: 'yo-print-facture-settings',
  templateUrl: './print-facture-settings.component.html',
  styleUrls: ['./print-facture-settings.component.scss']
})

export class PrintFactureSettingsComponent implements OnInit, OnDestroy {

  constructor(public utils: UtilsService,
              private toastSvc: ToastService,
              private facturationClientSvc: FacturationClientService) {
  }

  displayDialog: boolean;
  printSettingsSub: Subscription;
  settingsList: FactureClientSettingsML[] = [];
  detailsOption: boolean = false;
  logoOption: boolean = true;
  comment: string


  ngOnInit(): void {
    this.initSettings();
  }

  ngOnDestroy() {
    this.utils.unsubscribe(this.printSettingsSub);
  }

  private initSettings() {
    this.printSettingsSub = this.facturationClientSvc.factureClientPrint$
      .subscribe((factureList: FactureClientDto[]) => {
        this.settingsList = [];
        factureList.map(facture => {
          this.settingsList.push({
            id: facture.id
          } as FactureClientSettingsML)
        })
        if (factureList.length === 1) {
          this.detailsOption = !factureList[0].pointFacturation.synthetique;
        }
        this.displayDialog = true;
      })
  }

  closeDialog() {
    this.displayDialog = false;
  }

  print() {
    this.settingsList.forEach(setting => {
      setting.comment = this.comment;
      setting.details = this.detailsOption;
      setting.logo = this.logoOption;
    })
    this.facturationClientSvc.printFactureClient(this.settingsList);
    this.closeDialog()
  }

}
