import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FactureClientDto} from '../../../core/dtos/facturation/clients/factures/facture-client-dto';
import {Subscription} from 'rxjs';
import {UtilsService} from "../../../core/utils/utils.service";
import {FacturationClientService} from "../../../core/services/entities/facturation-client.service";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {PointFacturationDto} from "../../../core/dtos/facturation/clients/points-facturation/point-facturation-dto";
import {LookUpComponent} from "../../../shared/ui/lookup/lookup.component";
import {UniteDeProductionDTO} from "../../../core/dtos/unite-de-production-dto";
import {GenerateFactureClientDto} from "../../../core/dtos/facturation/clients/factures/generate-facture-client-dto";
import {MSG_KEY, MSG_SEVERITY, TYPE_LIST_UDP} from "../../../core/constants";
import {UnitesDeProductionService} from "../../../core/services/entities/unites-de-production.service";
import {TagBoxComponent} from "../../../shared/ui/tag-box/tag-box.component";
import {confirm} from "devextreme/ui/dialog";
import {ResponseWrapper} from "../../../core/suppliers/wrappers/response-wrapper";
import {IsDeletableObject} from "../../../core/models/is-deletable-object";
import {InternationalizationService} from "../../../core/services/i8n/i8n.service";
import {ToastService} from "../../../core/services/technique/toast.service";
import moment from "moment";


@Component({
  templateUrl: './factures-clients.component.html',
  styleUrls: ['./factures-clients.component.scss']
})
export class FacturesClientsComponent implements OnInit, OnDestroy {

  @ViewChild("pointFacturationTagBox") pointFacturationTagBox: TagBoxComponent;
  @ViewChild("uniteDeProductionLookup") uniteDeProductionLookup: LookUpComponent;

  facturesClient: any[] = [];

  allPointsFacturation: PointFacturationDto[] = [];
  allUniteDeProduction: UniteDeProductionDTO[] = [];

  selectedRows: number[] = [];
  displayCreation: boolean = false;

  selectedPeriode: Date[] = [new Date(), new Date()];

  findAllFacturesSub: Subscription;
  findAllPointsFacturationSub: Subscription;
  factureClientCreatedSub: Subscription;
  findAllUpSub: Subscription;
  generateFactureClientSub: Subscription;
  deleteSub: Subscription;

  constructor(public utilsSvc: UtilsService,
              public auth2Svc: Auth2Service,
              private i8nSvc: InternationalizationService,
              private toastSvc: ToastService,
              private udpSvc: UnitesDeProductionService,
              private facturationClientSvc: FacturationClientService) {
  }

  ngOnInit(): void {
    this.initData();
    this.initFactureClientCreatedSubscription();
  }

  initData(): void {
    this.findAllFacturesSub = this.facturationClientSvc.findAllFacturesClient().subscribe(response => {
      this.facturesClient = response.allFacturesClient;
    });

    this.findAllPointsFacturationSub = this.facturationClientSvc.findAllPointsFacturation(null).subscribe(response => {
      this.allPointsFacturation = response.allPointsFacturation;
    })

    this.findAllUpSub = this.udpSvc.getUniteDeProductionlist(TYPE_LIST_UDP.OnlyLocal).subscribe(response => {
      this.allUniteDeProduction = response.resultList;
      this.allUniteDeProduction = this.allUniteDeProduction.filter(udp => this.auth2Svc.isSiteLocal(udp.site.id));
    });
  }

  initFactureClientCreatedSubscription = (): void => {
    this.factureClientCreatedSub = this.facturationClientSvc.factureClientCreated$
      .subscribe((response: FactureClientDto) => {
          this.facturesClient.push(response.pointFacturation);
      });
  };

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.findAllFacturesSub);
    this.utilsSvc.unsubscribe(this.findAllPointsFacturationSub);
    this.utilsSvc.unsubscribe(this.factureClientCreatedSub);
    this.utilsSvc.unsubscribe(this.findAllUpSub);
    this.utilsSvc.unsubscribe(this.generateFactureClientSub);
    this.utilsSvc.unsubscribe(this.deleteSub);
  }

  generateFactureClient() {
    const generateFactureClient: GenerateFactureClientDto = {
      'pointFacturationList': this.pointFacturationTagBox.getSelectedItems(),
      'upFacturatrice': this.uniteDeProductionLookup.getSelectedItem(),
      'dateDebut': this.selectedPeriode[0],
      'dateFin': this.selectedPeriode[1]
    }

    this.generateFactureClientSub = this.facturationClientSvc.generateFactureClient(generateFactureClient).subscribe((response) => {
      if (response.one.factureClientList.length > 0) {
        this.initData()
      }
      if (response.one.factureClientList.length === 0) {
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, `Absence d'effectif de facturation ou de paramétrage du point de facturation`);
      }
      if (response.one.errors.length > 0) {
        this.facturationClientSvc.announceFactureClientErrors(response.one.errors);
      }
      this.closeCreationDialog();
    })
  }

  onDateFilterValueChange = (data: any) => {
    this.selectedPeriode = [data[0], data[1]];
  };

  openCreationDialog(): void {
    this.displayCreation = true;
  }

  closeCreationDialog(): void {
    this.displayCreation = false;
  }

  openDialogDetails = (generateFactureClient: GenerateFactureClientDto, factureClient: FactureClientDto): void => {
      this.facturationClientSvc.announceOpenDialogDetailsFactureClient(generateFactureClient, factureClient);
  };

  deleteValues = async (): Promise<void> => {
    let idsToDeleteInTable = Object.assign([], this.selectedRows);
    const isDeletionValidated: boolean = await confirm(this.i8nSvc.getLabelFromCode(idsToDeleteInTable.length > 1 ? 'CONFIRM_DELETION_PLURAL' : 'CONFIRM_DELETION_SINGULAR', null), this.i8nSvc.getLabelFromCode('Suppression', null));

    if (!isDeletionValidated) {
      return new Promise(null);
    }

    this.deleteSub = this.facturationClientSvc.deleteFactureClient(this.selectedRows).subscribe(response => {
      const res: any = (response as ResponseWrapper<IsDeletableObject>).one;

      if (res) {
        if (res.messagesErrorList && res.messagesErrorList.length > 0) {
          for (const error of res.messagesErrorList) {
            const elementToKeep = this.facturesClient.find(pointFacturation => pointFacturation.code === error.args);
            idsToDeleteInTable = idsToDeleteInTable.filter(id => elementToKeep.id !== id);
          }
        } else {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, this.i8nSvc.getLabelFromCode(idsToDeleteInTable.length > 1 ? `DELETION_COMPLETED_PLURAL` : `DELETION_COMPLETED_SINGULAR`, null));
          this.reInitSelectedRows();
        }
        this.facturesClient = this.facturesClient.filter(pointFacturation => !idsToDeleteInTable.find(id => id === pointFacturation.id));
      }
    });
  }

  printFactures = async (): Promise<void> => {
    const facturesSelectedToPrint = this.facturesClient.filter(facture => this.selectedRows.includes(facture.id))
    this.facturationClientSvc.announceOpenPrintSettings(facturesSelectedToPrint);
  }

  canValidateOrDelete = (): boolean => this.selectedRows.length > 0 && this.facturesClient.filter(fc => this.selectedRows.includes(fc.id) && fc.statut === 'VALIDE').length === 0 ;

  validateFacture = async (): Promise<void> => {

    const idsToValidate = Object.assign([], this.selectedRows);
    const question: string = idsToValidate.length > 1 ? 'Voulez-vous vraiment valider les factures sélectionnées ?' : 'Voulez-vous vraiment valider la facture sélectionnée ?';
    const title: string = idsToValidate.length > 1 ? 'Validation des factures' : 'Valider la facture';
    const isValidationValidated: boolean = await confirm(question, title);

    if (!isValidationValidated) {
      return new Promise(null);
    }

    this.facturationClientSvc.validateFactureClient(this.selectedRows).subscribe((response) => {
      if (!this.utilsSvc.isResponseSupplierError(response)) {
        this.initData();
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, idsToValidate.length > 1 ? 'Factures validées avec succès' : 'Facture validée avec succès');
        this.reInitSelectedRows();
      }
    });

  }

  reInitSelectedRows = (): void  => {
    this.selectedRows = [];
  }

  getDateHeaderFilterDataSource() {
    return this.facturesClient.map(item => ({
      text: moment(item.dateFacturation, 'DD/MM/YYYY HH:mm:ss').format('LL'),
      value: this.utilsSvc.transformStringToDate(item.dateFacturation)
    }));
  }

  openTransfertEffectivesDialog() {
    this.facturationClientSvc.announceOpenDialogTransfertEffectives();
  }
}
