<div class="custom-toolbar">
  <div class="custom-toolbar-subdivision-left custom-toolbar-element">
    <yo-button
      iconClass="fa fa-plus"
      label="CRÉER"
      (onClick)="openEditConfig(null)"
      tooltip="Créer un élément facturable">
    </yo-button>
  </div>
</div>

<div class="container-table">
  <dx-data-grid
    [dataSource]="gefConnectionList"
    keyExpr="id"
    height="100%"
    width="100%"
    [hoverStateEnabled]="true"
    [allowColumnResizing]="true"
    [rowAlternationEnabled]="false"
    [showRowLines]="true"
    [showBorders]="true"
    #grid
  >
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxi-column dataField="id" alignment="center" caption="Actions" cellTemplate="actionsCellTemplate" width="100"
                [allowFiltering]="false">
    </dxi-column>
    <dxi-column dataField="site.libelle" caption="Site" cellTemplate="siteCellTemplate">
    </dxi-column>
    <dxi-column dataField="gefProvider" alignment="left" caption="GEF"
                cellTemplate="gefProviderCellTemplate"></dxi-column>
    <dxi-column dataField="gefFluxList" alignment="left" caption="Abonnement(s) aux Flux"
                cellTemplate="fluxCellTemplate"
                width="400"
    >
    </dxi-column>
    <dxi-column dataField="actif" alignment="center" caption="Actif" [allowFiltering]="false"></dxi-column>

    <div *dxTemplate="let cell of 'actionsCellTemplate'" class="custom-button-container">
      <yo-cell-button
        (yoNavigation)="openEditConfig(cell.row.data)"
        [yoTooltipShowDelay]="500"
        [yoSmall]="true"
        yoTextAlign="center"
        [yoIconClass]="gestionGefSvc.canModify(cell.row.data)? 'fa fa-edit' : 'fas fa-eye'"
        [yoTooltip]="gestionGefSvc.canModify(cell.row.data)? 'Modifier la configuration GEF' : 'Voir la configuration GEF'">
      </yo-cell-button>
    </div>

    <div *dxTemplate="let cell of 'siteCellTemplate'">
      <yo-site [siteLibelle]="cell.row.data.site.libelle" [siteId]="cell.row.data.site.id"></yo-site>
    </div>

    <div *dxTemplate="let cell of 'gefProviderCellTemplate'">
      <span class="genlabel cursor">{{ cell.row.data.gefProvider.code | uppercase }}</span>
    </div>

    <div *dxTemplate="let cell of 'fluxCellTemplate'">
      <ng-container *ngFor="let flux of cell.row.data.gefFluxList">
        <div class="font-12 mg-l-5 ">
          <span class="genlabel">{{ flux }}</span>
        </div>
      </ng-container>
    </div>


  </dx-data-grid>
</div>

<yo-connections-gef-config-edition-dialog></yo-connections-gef-config-edition-dialog>
