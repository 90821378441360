<dx-popup
  [showTitle]="true"
  [title]="dialogTitle"
  [(visible)]="displayDialog"
  [width]="800"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHidden)="closeDialog()"
>

  <form [formGroup]="form">
    <div class="dialog-line">
      <div class="label" title="Site">Site <span class="danger-color">*</span></div>
      <div class="value">
        <yo-lookup
          #siteLookup
          [data]="findAllLocalSites()"
          [selectedItem]="idSelectedSite"
          autoSelect="false"
          [placeholder]="sitePlaceholder"
          (onValueChange)="onChangeSite($event)"
          [disabled]="!canModify() || gefConnection?.id != null"
        ></yo-lookup>
      </div>
    </div>

    <div class="dialog-line">
      <div class="label" title="Interface">Interface <span class="danger-color">*</span></div>
      <div class="value">
        <yo-lookup
          #gefProvidersLookup
          [data]="gefProviders"
          [placeholder]="'Sélectionner un connecteur GEF'"
          [selectedItem]="idSelectedGefProvider"
          (onValueChange)="onChangeGefProvider($event)"
          [disabled]="!canModify() || gefConnection?.id != null"
        ></yo-lookup>
      </div>
    </div>

    <div class="dialog-line">
      <div class="label text-nowrap" title="Abonnement flux">Abonnement flux</div>
      <div class="value">
        <yo-tagbox
          [data]="fluxList"
          [selectedItems]="selectedFlux"
          (onValueChange)="onSelectedFluxChange($event)"
          [disabled]="!canModify()"
        >
        </yo-tagbox>
      </div>
    </div>

    <div class="dialog-line">
      <div class="label" title="Actif">Actif <span class="danger-color">*</span></div>
      <div class="value">
        <dx-check-box
          [value]="true"
          formControlName="actif"
          [disabled]="!canModify()">
        </dx-check-box>
      </div>
    </div>

    <!-- Intégrer le form specifique à Elap - Affiché uniquement si le provider séléctionné est ELAP-->
    <div *ngIf="isGefProviderSelected(GEF_PROVIDER.ELAP_FINANCE)">

      <div class="dialog-line">
        <div class="dialog-line">
          <div class="label" title="Code Etablissement">Code Etablissement</div>
          <div class="value">
            <dx-text-box
              class="inputfield"
              formControlName="codeEtablissement"
              [disabled]="!canModify()">
            </dx-text-box>
          </div>
        </div>
        <div class="dialog-line">
          <div class="label" title="Nom Client">Nom du client</div>
          <div class="value">
            <dx-text-box
              class="inputfield"
              formControlName="nomClient"
              [disabled]="!canModify()">
            </dx-text-box>
          </div>
        </div>
      </div>

      <div class="dialog-line">
        <div class="dialog-line">
          <div class="label" title="Access Token">Token</div>
          <div class="value">
            <dx-text-box
              class="inputfield"
              formControlName="accessToken"
              [mode]="!canModify() ? 'password' : 'text'"
              [disabled]="!canModify()">
            </dx-text-box>
          </div>
        </div>
        <div class="dialog-line">
          <div class="label" title="Code ELAP de l'article Frais de port">Code Frais de port</div>
          <div class="value">
            <dx-text-box
              class="inputfield"
              formControlName="codeArticleFraisPort"
              [disabled]="!canModify()">
            </dx-text-box>
          </div>
        </div>
      </div>


      <div class="dialog-line">
        <div class="dialog-line">
          <div class="label" title="Dispo Achat">Dispo Achat</div>
          <div class="value">
            <dx-text-box
              class="inputfield"
              formControlName="dispoAchat"
              [disabled]="!canModify()">
            </dx-text-box>
          </div>
        </div>
        <div class="dialog-line">
          <div class="label" title="Compte Contrepartie">Compte Contrepartie</div>
          <div class="value">
            <dx-text-box
              class="inputfield"
              formControlName="compteContrepartie"
              [disabled]="!canModify()">
            </dx-text-box>
          </div>
        </div>
      </div>

      <div class="dialog-line">
        <div class="dialog-line">
          <div class="label" title="Compte Charge">Compte Charge</div>
          <div class="value">
            <dx-text-box
              class="inputfield"
              formControlName="compteCharge"
              [disabled]="!canModify()">
            </dx-text-box>
          </div>
        </div>
        <div class="dialog-line">
          <div class="label" title="Compte FNP">Compte FNP</div>
          <div class="value">
            <dx-text-box
              class="inputfield"
              formControlName="compteFNP"
              [disabled]="!canModify()">
            </dx-text-box>
          </div>
        </div>
      </div>

      <div class="dialog-line">
        <div class="dialog-line">
          <div class="label" title="Nature Achat">Nature Achat</div>
          <div class="value">
            <dx-text-box
              class="inputfield"
              formControlName="natureAchat"
              [disabled]="!canModify()">
            </dx-text-box>
          </div>
        </div>
        <div class="dialog-line">
          <div class="label" title="Destination Achat">Destination Achat</div>
          <div class="value">
            <dx-text-box
              class="inputfield"
              formControlName="destinationAchat"
              [disabled]="!canModify()"
            >
            </dx-text-box>
          </div>
        </div>
      </div>

      <div class="dialog-line">
        <div class="dialog-line">
          <div class="label" title="WorkFlow Etape Initiale EJ">WfEtapeInitiale EJ</div>
          <div class="value">
            <dx-text-box
              class="inputfield"
              formControlName="EJwfEtapeInitiale"
              [disabled]="!canModify()"
            >
            </dx-text-box>
          </div>
        </div>
        <div class="dialog-line">
          <div class="label" title="WorkFlow Transistion EJ">WfTransition EJ</div>
          <div class="value">
            <dx-text-box
              class="inputfield"
              formControlName="EJwfTransition"
              [disabled]="!canModify()"
            >
            </dx-text-box>
          </div>
        </div>
      </div>      <div class="dialog-line">
        <div class="dialog-line">
          <div class="label" title="WorkFlow Etape Initiale SF">WfEtapeInitiale SF</div>
          <div class="value">
            <dx-text-box
              class="inputfield"
              formControlName="SFwfEtapeInitiale"
              [disabled]="!canModify()"
            >
            </dx-text-box>
          </div>
        </div>
        <div class="dialog-line">
          <div class="label" title="WorkFlow Transistion SF">WfTransition SF</div>
          <div class="value">
            <dx-text-box
              class="inputfield"
              formControlName="SFwfTransition"
              [disabled]="!canModify()"
            >
            </dx-text-box>
          </div>
        </div>
      </div>

      <div class="dialog-line">
        <div class="dialog-line">
          <div class="label" title="Statut des bons de commandes à exporter">Statut bons cf <span
            class="danger-color">*</span></div>
          <div class="value">
            <yo-lookup
              #bonCfStatutExportLookup
              [data]="bonCfStatutExportlist"
              [selectedItem]="idSelectedBonCfStatutExport"
              autoSelect="false"
              (onValueChange)="onChangeBonCfStatutExport($event)"
              [disabled]="!canModify()"
            ></yo-lookup>
          </div>
        </div>
        <div class="dialog-line"></div>
      </div>
    </div>

    <div class="mt-2 custom-button-container-right">
      <yo-button
        type="submit"
        iconClass="fas fa-save"
        tooltip="Enregistrer"
        buttonClass="cta-success"
        (onClick)="save()"
        [hidden]="!canModify()">
      </yo-button>
      <yo-button
        buttonClass="cta-delete"
        (onClick)="closeDialog()"
        tooltip="Fermer"
        iconClass="fas fa-times">
      </yo-button>
    </div>
  </form>

</dx-popup>
