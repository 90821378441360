import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {GEF_FLUX_DISPONIBLE, GEF_PROVIDER, MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {SiteDTO} from "../../../../core/dtos/site-dto";
import {Subscription} from "rxjs";
import {GefConnectionDto} from "../../../../core/dtos/gef/gef-connection-dto";
import {FormControl, FormGroup} from "@angular/forms";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {ToastService} from "../../../../core/services/technique/toast.service";
import {UtilsService} from "../../../../core/utils/utils.service";
import {GestionGefService} from "../../../../core/services/gef/gestion-gef.service";
import {LookUpComponent} from "../../../../shared/ui/lookup/lookup.component";
import {GefProviderDto} from "../../../../core/dtos/gef/gef-provider-dto";
import {BoncfStatutDTO} from "../../../../core/dtos/boncf-statut-d-t-o";
import {confirm} from "devextreme/ui/dialog";


@Component({
  selector: 'yo-connections-gef-config-edition-dialog',
  templateUrl: './connections-gef-config-edition-dialog.component.html',
  styleUrls: ['./connections-gef-config-edition-dialog.component.scss']
})

export class ConnectionsGefConfigEditionDialogComponent implements OnInit, OnDestroy {

  @ViewChild("siteLookup") siteLookup: LookUpComponent;
  @ViewChild("bonCfStatutExportLookup") bonCfStatutExportLookup: LookUpComponent;
  @ViewChild("gefProvidersLookup") gefProvidersLookup: LookUpComponent;

  public readonly GEF_PROVIDER = GEF_PROVIDER;

  gefProviders: GefProviderDto[] = [];
  bonCfStatutExportlist: BoncfStatutDTO[] = [];
  fluxList: any[] = [];

  dialogTitle: string;
  displayDialog: boolean = false;

  form: FormGroup = new FormGroup({});

  sitePlaceholder: String = 'Selectionner le site...';
  idSelectedSite: number;
  idSelectedGefProvider: number;
  selectedFlux: number[];
  idSelectedBonCfStatutExport: number;

  subOpenDialog: Subscription
  subFindAllGefProviders: Subscription
  subFindAllBonCfStatus: Subscription

  gefConnection: GefConnectionDto;

  constructor(private auth2Svc: Auth2Service,
              private toastSvc: ToastService,
              private gestionGefSvc: GestionGefService,
              public utilsSvc: UtilsService) {
  }

  ngOnInit() {
    this.initData();
    this.initForm();
    this.openDialogEditionSubscription();
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subOpenDialog);
    this.utilsSvc.unsubscribe(this.subFindAllGefProviders);
    this.utilsSvc.unsubscribe(this.subFindAllBonCfStatus);
  }


  openDialogEditionSubscription = (): void => {
    this.subOpenDialog = this.gestionGefSvc.openGefConnectionFluxEditionDialog
      .subscribe((connection: GefConnectionDto) => {
        this.displayDialog = true;
        if (!connection) {
          this.gefConnection = new GefConnectionDto();
          this.gefConnection.id = null;
          this.dialogTitle = 'Création d\'une configuration GEF';
        } else {
          this.gefConnection = connection;
          this.dialogTitle = 'Modification d\'une configuration GEF';
          this.idSelectedGefProvider = this.gefProviders.find(gp => gp.code === this.gefConnection?.gefProvider?.code)?.id;
        }
        this.initForm();
      });
  };

  findAllLocalSites = (): SiteDTO[] => {
    return this.auth2Svc.utilisateur.siteListLocaux;
  }

  findAllBonCfStatus() {
    this.subFindAllBonCfStatus = this.gestionGefSvc.findAllBonCfStatus('ASC').subscribe(result => {
      this.bonCfStatutExportlist = result.allBonCfStatus;
    })
  }

  initData = (): void => {
    this.subFindAllGefProviders = this.gestionGefSvc.findAllGefProviders().subscribe(result => {
      this.gefProviders = result.allGefProviders;
      if (this.gefProviders.length === 1) {
        this.idSelectedGefProvider = this.gefProviders[0].id;
      }
    });
    this.findAllBonCfStatus();
    this.initFluxList();
  }

  initFluxList = (): void => {
    this.fluxList = Object.entries(GEF_FLUX_DISPONIBLE).map(([key, value], index) => ({
      id: index,
      code: key,
      libelle: value
    }));
  }


  initForm = (): void => {
    this.sitePlaceholder = !this.canModify() ? this.gefConnection?.site?.libelle : 'Sélectionner le site...';
    this.idSelectedSite = this.gefConnection?.site?.id;
    this.selectedFlux = this.fluxList.filter(f => this.gefConnection?.gefFluxList?.includes(f.code))
      .map(f => f.id);
    this.idSelectedBonCfStatutExport = this.gefConnection?.properties?.ejProperties?.bonCfStatutExport?.id;
    this.form = new FormGroup({
      actif: new FormControl(this.gefConnection?.actif),
      // ElapProperties fields
      codeEtablissement: new FormControl(this.gefConnection?.properties?.codeEtablissement),
      nomClient: new FormControl(this.gefConnection?.properties?.nomClient),
      accessToken: new FormControl(this.gefConnection?.properties?.accessToken),
      codeArticleFraisPort: new FormControl(this.gefConnection?.properties?.codeArticleFraisPort),
      dispoAchat: new FormControl(this.gefConnection?.properties?.articleProperties?.dispoAchat),
      compteContrepartie: new FormControl(this.gefConnection?.properties?.articleProperties?.compteContrepartie),
      compteCharge: new FormControl(this.gefConnection?.properties?.articleProperties?.compteCharge),
      compteFNP: new FormControl(this.gefConnection?.properties?.articleProperties?.compteFNP),
      natureAchat: new FormControl(this.gefConnection?.properties?.articleProperties?.natureAchat),
      destinationAchat: new FormControl(this.gefConnection?.properties?.articleProperties?.destinationAchat),
      EJwfEtapeInitiale: new FormControl(this.gefConnection?.properties?.ejProperties?.wfEtapeInitiale),
      EJwfTransition: new FormControl(this.gefConnection?.properties?.ejProperties?.wfTransition),
      SFwfEtapeInitiale: new FormControl(this.gefConnection?.properties?.sfProperties?.wfEtapeInitiale),
      SFwfTransition: new FormControl(this.gefConnection?.properties?.sfProperties?.wfTransition)
    });
  };


  async save() {
    if (this.form.valid) {


      const saveGefConnection: GefConnectionDto = {...this.gefConnection};
      saveGefConnection.site = {
        id: this.siteLookup.getSelectedItem().id,
        libelle: this.siteLookup.getSelectedItem().libelle
      } as SiteDTO;
      saveGefConnection.gefProvider = this.gefProviders.find(gp => gp.id === this.idSelectedGefProvider);
      saveGefConnection.gefFluxList = this.fluxList.filter(flux => this.selectedFlux.includes(flux.id)).map(flux => flux.code);
      const response = await this.checkFluxSelection(saveGefConnection.gefFluxList);
      if (!response) {
        return;
      }
      saveGefConnection.actif = this.form.controls['actif'].value
      if (this.isGefProviderSelected(GEF_PROVIDER.ELAP_FINANCE)) {
        saveGefConnection.jsonProperties = {
          'codeEtablissement': this.form.controls['codeEtablissement'].value,
          'nomClient': this.form.controls['nomClient'].value,
          'accessToken': this.form.controls['accessToken'].value,
          'codeArticleFraisPort': this.form.controls['codeArticleFraisPort'].value,
          'articleProperties': {
            'dispoAchat': this.form.controls['dispoAchat'].value,
            'compteContrepartie': this.form.controls['compteContrepartie'].value,
            'compteCharge': this.form.controls['compteCharge'].value,
            'compteFNP': this.form.controls['compteFNP'].value,
            'natureAchat': this.form.controls['natureAchat'].value,
            'destinationAchat': this.form.controls['destinationAchat'].value,
          },
          'ejProperties': {
            'wfEtapeInitiale': this.form.controls['EJwfEtapeInitiale'].value,
            'wfTransition': this.form.controls['EJwfTransition'].value,
            'bonCfStatutExport': {
              id: this.bonCfStatutExportLookup.getSelectedItem().id,
              libelle: this.bonCfStatutExportLookup.getSelectedItem().libelle
            } as BoncfStatutDTO
          },
          'sfProperties': {
            'wfEtapeInitiale': this.form.controls['SFwfEtapeInitiale'].value,
            'wfTransition': this.form.controls['SFwfTransition'].value,
          }
        }
      }
      saveGefConnection.properties = null;
      this.gestionGefSvc.save(saveGefConnection)
        .subscribe(response => {
          if (response && response.one) {
            const isUpdate = saveGefConnection.id !== null && saveGefConnection.id !== undefined && saveGefConnection.id !== 0;
            this.gestionGefSvc.announceGefConnectionSaved(response.one, isUpdate);
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Sauvegarde de la connection à la GEF réalisée avec succès`);
            this.closeDialog();
          }
        });
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez compléter le formulaire`);
    }
  }

  canModify() {
    return this.gestionGefSvc.canModify(this.gefConnection);
  }

  onChangeSite = ($event: any): void => {
    this.idSelectedSite = $event;
  }

  onChangeBonCfStatutExport = ($event: any): void => {
    this.idSelectedBonCfStatutExport = $event;
  }

  onChangeGefProvider = ($event: any): void => {
    this.idSelectedGefProvider = $event;
    this.initFluxList();
    this.fluxList = this.fluxList.filter(f => f.code.startsWith(this.gefProviders.find(gp => gp.id === this.idSelectedGefProvider)?.code))
  }

  isGefProviderSelected(gefProviderExpected: string) {
    return this.idSelectedGefProvider !== null ?
      this.idSelectedGefProvider === this.gefProviders.find(p => p.code === gefProviderExpected)?.id
      : false;
  }

  onSelectedFluxChange($event: any) {
    this.selectedFlux = $event;
  }

  checkFluxSelection = async (rowsSelected: String[]): Promise<boolean> => {
    const removedItems = this.gefConnection.gefFluxList.filter(item => !rowsSelected.includes(item));
    if (removedItems.length > 0) {
      const message = removedItems.length > 1 ? 'Voulez-vous vraiment supprimer ces flux de votre configuration (' + removedItems + ')' : 'Voulez-vous vraiment supprimer ce flux de votre configuration (' + removedItems + ')';
      return await confirm(message + '.</br> L\'historique GEF entre ce flux et votre interface sera définitivement supprimé.', 'Suppression de flux GEF');
    }
    return Promise.resolve(true);
  }

  closeDialog() {
    this.displayDialog = false;
  }

}
