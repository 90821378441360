<form [formGroup]="form" (ngSubmit)="saveFicheIdentite()" novalidate>
  <div class="d-flex justify-content-between mg-b-10">
    <div>
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="getHelp()" [width]="800"></yo-help>
      <strong>
        <label>Données administratives</label>
      </strong>
    </div>

    <yo-button
      tooltip="Enregistrer"
      type="submit"
      iconClass="fas fa-save"
      buttonClass="cta-success"
      [disabled]="!gds.canModify(selectedUniteDeProduction)"></yo-button>
  </div>

  <div class="fiche-id-size">


    <p-panel header="INFORMATIONS GÉNÉRALES" [toggleable]="true" [style]="{'margin-bottom': '10px'}">
      <ng-container *ngFor="let field of fieldsInfosGenerales">
        <yo-dynamic-form-field-bs
          [fields]="fieldsInfosGenerales"
          [field]="field"
          [form]="formInfoGenerales">
        </yo-dynamic-form-field-bs>
      </ng-container>
    </p-panel>

    <p-panel header="ADRESSES" [toggleable]="true" [style]="{'margin-bottom': '10px'}">
      <div class="d-flex flex-row">
        <div class="mg-r-15 w-50">
          <label>ADRESSE DE LIVRAISON</label>
          <ng-container *ngFor="let field of fieldsAdresseLivraison">
            <yo-dynamic-form-field-bs [fields]="fieldsAdresseLivraison" [field]="field"
                                      [form]="formAdresseLivraison"></yo-dynamic-form-field-bs>
          </ng-container>
        </div>


        <div class="w-50">
          <div class="d-flex flex-column">
            <div class="d-flex flex-row">
              <label class="mg-r-15">ADRESSE DE FACTURATION</label>

              <p-checkbox [formControl]="formSameAddress.controls['sameAddress']"
                          (onChange)="onChangeSameAddress($event)"
                          label="Identique à l'adresse de livraison"
                          binary="true">
              </p-checkbox>
              <!--              {{formSameAddress.value | json}}-->
            </div>


            <div *ngIf="!isSameAdresse">
              <ng-container *ngFor="let field of fieldsAdresseFacturation">
                <yo-dynamic-form-field-bs [fields]="fieldsAdresseFacturation" [field]="field"
                                          [form]="formAdresseFacturation">
                </yo-dynamic-form-field-bs>
              </ng-container>
            </div>


            <ng-container *ngIf="isSameAdresse">
              <em>
                <i class="far fa-comment space"></i>Adresse de facturation identique à l'adresse de livraison
              </em>
            </ng-container>
          </div>
        </div>

      </div>
    </p-panel>

    <p-panel [style]="{'margin-bottom': '10px'}" header="FACTURATION CLIENTS" [toggleable]="true">
      <div class="row">
        <div class="col-md-12">

          <div class="form-group">
            <div class="font-10 label" title="Forme Juridique et Capital">FORME JURIDIQUE ET CAPITAL</div>
            <div class="value">
              <dx-text-box [formControl]="formInfoFacturation.controls['formeJuridiqueCapital']" width="380"></dx-text-box>
            </div>
          </div>
          <div class="form-group">
            <div class="font-10 label" title="Siret">SIRET</div>
            <div class="value">
              <dx-text-box [formControl]="formInfoFacturation.controls['siret']" width="380"></dx-text-box>
            </div>
          </div>
            <div class="font-10 label" title="Code Ape">CODE APE</div>
            <div class="value">
              <dx-text-box [formControl]="formInfoFacturation.controls['codeApe']" width="380"></dx-text-box>
            </div>
            <div class="font-10 label" title="Iban">IBAN</div>
            <div class="value">
              <dx-text-box [formControl]="formInfoFacturation.controls['iban']" width="380"></dx-text-box>
            </div>
          <div class="form-group">
            <div class="font-10 label" title="Bic">BIC</div>
            <div class="value">
              <dx-text-box [formControl]="formInfoFacturation.controls['bic']" width="380"></dx-text-box>
            </div>
          </div>
        </div>
      </div>
    </p-panel>

    <p-panel [style]="{'margin-bottom': '10px'}" header="MARCHE" [toggleable]="true">
      <div class="row">
        <div class="col-md-12">
          <label class="label-estampille">Choix de la date sur laquelle repose les marchés</label>
        </div>
        <div class="col-md-12">
          <dx-select-box
            placeholder="Date de livraison"
            [items]="datesChoice"
            [formControl]="formMarche.controls['dateReferente']"
            [inputAttr]="{ 'aria-label': 'Product With Placeholder' }"
            [showClearButton]="true"
            valueExpr="value"
            displayExpr="label"
          ></dx-select-box>
        </div>
      </div>
    </p-panel>

    <p-panel [style]="{'margin-bottom': '10px'}" header="DIVERS" [toggleable]="true">
      <ng-container *ngFor="let field of fieldsDivers">
        <yo-dynamic-form-field-bs [fields]="fieldsDivers" [field]="field"
                                  [form]="formDivers">
        </yo-dynamic-form-field-bs>
      </ng-container>
      <div class="row">
        <div class="col-md-12">
          <label class="label-estampille">Estampille vétérinaire</label>
        </div>
        <div class="col-md-12">
          <input type="file" [style.max-width.px]="estampilleVeterinaireFile.width"
                 id="input-estampille"
                 (change)="onFileChange($event)"
                 accept="image/jpeg, image/png"
                 [readonly]="estampilleVeterinaireFile.readonly">
        </div>
      </div>
      <div class="row mg-t-10">
        <ng-container *ngIf="!utils.isNullOrEmpty(previewEstampilleVeterinaireFile)">
          <div class="col-md-auto">
            <p class="font-12 genlabel">Prévisualisation avant enregistrement</p>
            <img id="previewEstampilleVeterinaireFile" src="#" height="64">
          </div>
        </ng-container>
        <div class="col">
          <ng-container *ngIf="!utils.isNullOrEmpty(previewEstampilleVeterinaireFile)">
            <p class="font-12 genlabel">Image enregistrée</p>
          </ng-container>

          <yo-img-entity
            *ngIf="selectedUniteDeProduction && selectedUniteDeProduction.id && selectedUniteDeProduction.hasEstampilleVeterinaire"
            [entityId]="selectedUniteDeProduction.id" [refresh]="estampilleVeterinaireFile.refresh"
            (onError)="docError=$event"
            [height]="64"
            [openable]="true"
            [displayNoImage]="false"
            format="thumb"
            [entityName]="estampilleVeterinaireFile.entityName"
            [ordre]="1">
          </yo-img-entity>
          <yo-img-entity *ngIf="selectedUniteDeProduction && !selectedUniteDeProduction.hasEstampilleVeterinaire"
                         [refresh]="estampilleVeterinaireFile.refresh"
                         (onError)="docError=$event"
                         [height]="64"
                         [openable]="true"
                         [displayNoImage]="true"
                         format="thumb"
                         [ordre]="1">
          </yo-img-entity>
          <i style="vertical-align: top; margin-left: 5px;" class="fas fa-times mg-r-20 danger-color cursor"
             *ngIf="selectedUniteDeProduction && selectedUniteDeProduction.id && selectedUniteDeProduction.hasEstampilleVeterinaire"
             (click)="deleteFile(estampilleVeterinaireFile)" title="Enlever la {{estampilleVeterinaireFile.label}}"
          ></i>
        </div>
      </div>
    </p-panel>

    <p-panel header="Commandes fournisseurs (Emplacement temporaire)" [toggleable]="true">
      <p-panel class="mg-t-5 mg-b-5" header="Colonnes des bons de commandes à masquer pour le format pdf">
        <p-listbox [options]="columnsBonCommandePdf" optionLabel="libelle" [multiple]="true" [style.width]="'100%'"
                   checkbox="true"
                   [formControl]="formColumnsBonCommandePdfSelected.controls['columnsBonCommandePdfSelected']">
        </p-listbox>
        <p-checkbox [formControl]="formColumnsBonCommandePdfSelected.controls['hideFinancialData']"
                    label="Masquer les informations financières"
                    binary="true">
        </p-checkbox>
      </p-panel>
    </p-panel>

  </div>
</form>
