import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UtilsService} from '../../../../core/utils/utils.service';
import {
  CatalogueAchatDialogSupplier,
  CataloguesAchatsService,
  PROVENANCE
} from '../../../../core/services/entities/catalogues-achats.service';
import {Subscription} from 'rxjs';
import {CatalogueAchatDTO} from '../../../../core/dtos/catalogue-achat-dto';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {DomSanitizer} from '@angular/platform-browser';
import {cloneDeep as _cloneDeep} from 'lodash';
import {GenericFormService} from '../../../../core/services/generics/generic-form.service';
import {GenericDatagridService} from '../../../../core/services/generics/generic-datagrid.service';
import {Router} from '@angular/router';
import {RoutemapService} from '../../../../core/services/routemap.service';
import {UniteDeProductionDTO} from "../../../../core/dtos/unite-de-production-dto";
import {ProduitDTO} from "../../../../core/dtos/produit-dto";
import {UnitesDeMesuresService} from "../../../../core/services/entities/unites-de-mesures.service";
import {UniteDeMesureDTO} from "../../../../core/dtos/unitedemesure-dto";
import {ProduitDeclinaisonDTO} from "../../../../core/dtos/produit-declinaison-dto";
import {NgForm} from "@angular/forms";
import {LookUpComponent} from "../../../../shared/ui/lookup/lookup.component";
import {AllergeneDTO} from "../../../../core/dtos/allergene-dto";
import {AllergenesService} from "../../../../core/services/entities/allergenes-service.service";
import {AppellationsService} from "../../../../core/services/entities/appellations.service";
import {AppellationDTO} from "../../../../core/dtos/appellations-dto";
import {GestionFournisseurService} from "../../../../core/services/gestion-fournisseurs/gestion-fournisseur.service";
import {MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {ToastService} from "../../../../core/services/technique/toast.service";
import {DxTagBoxComponent} from "devextreme-angular";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {GraphQLService} from "../../../../core/services/technique/graphql.service";
import {OrigineCarneDTO} from "../../../../core/dtos/origine-carne-dto";
import {OrigineDTO} from "../../../../core/dtos/origine-dto";
import {FournisseurDTO} from "../../../../core/dtos/fournisseur-d-t-o";

@Component({
  selector: 'yo-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit, OnDestroy {

  @ViewChild('articleForm') articleForm: NgForm;
  @ViewChild('produitDeclinaison') produitDeclinaison: LookUpComponent;
  @ViewChild('uniteDeFacturation') uniteDeFacturation: LookUpComponent;
  @ViewChild('uniteDeCommande') uniteDeCommande: LookUpComponent;
  @ViewChild('uniteStockage') uniteStockage: LookUpComponent;
  @ViewChild('appellationsTagBox') appellationsTagBox: DxTagBoxComponent;
  @ViewChild('naissance') naissanceLookup: LookUpComponent;
  @ViewChild('elevage') elevageLookup: LookUpComponent;
  @ViewChild('abattage') abattageLookup: LookUpComponent;

  unitesDeMesure: any[] = [];
  title: string;
  uniteDeProduction: UniteDeProductionDTO;
  produit: ProduitDTO;
  declinaisonsList: ProduitDeclinaisonDTO[];
  displayDialog = false;
  catalogueAchat: CatalogueAchatDTO;
  fullScreen: boolean = false;
  cads: CatalogueAchatDialogSupplier;

  private subFournisseurs: Subscription;
  private subUnitesDeProduction: Subscription;
  private subOrigines: Subscription;

  private subOpenDialog: Subscription;
  private subAllergenes: Subscription;
  private subAppelations: Subscription;
  private subArticleAppellations: Subscription;
  private subCloseDialogPeriodPrice: Subscription;
  private subFindCaById: Subscription;

  allergeneList: AllergeneDTO[] = [];
  produitAllergenes: number[] = [];
  allAppellations: AppellationDTO[];
  appellationsProduit: number[] = [];
  fournisseursList: any[];

  ratioUCUF: number;
  ratioUSUT: number;

  isSameUCUF: boolean;
  isSameUSUT: boolean;

  origines: OrigineDTO[];

  constructor(public utils: UtilsService,
              public auth2Svc: Auth2Service,
              private catalogueAchatSvc: CataloguesAchatsService,
              private gfs: GenericFormService,
              private gds: GenericDatagridService,
              private router: Router,
              private routeMapSvc: RoutemapService,
              public domSanitizer: DomSanitizer,
              private unitesDeMesuresSvc: UnitesDeMesuresService,
              private allergenesSvc: AllergenesService,
              private appelationsSvc: AppellationsService,
              private gestionFournisseurSvc: GestionFournisseurService,
              private toastSvc: ToastService,
              private graphQlSvc: GraphQLService) {
  }

  ngOnInit(): void {
    this.initOrigines();
    this.initAllergenes();
    this.initAppellations();
    this.initUnitesDeMesure();
    this.openDialogSubscription();
    this.periodPriceClosedSubscription();
  }

  openDialogSubscription = () => {
    this.subOpenDialog = this.catalogueAchatSvc.openDialogArticle$
      .pipe(
        tap(data => this.cads = data),
        tap(data => {
          this.displayDialog = true;
          switch (data?.routeProvenance) {
            case PROVENANCE.FOURNISSEUR:
              this.router.navigate(data.rootRoute);
              break;
            case PROVENANCE.PRODUIT:
              this.routeMapSvc.goToSecondaryRoute(data.rootRoute);
              break;
          }
        }),
        tap((data) => {
          if (data) {
            this.produit = _cloneDeep(data.produit);
            this.declinaisonsList = this.produit.produitDeclinaisons;
            this.uniteDeProduction = _cloneDeep(data.uniteDeProduction);
            if (data.catalogueAchat) {
              this.catalogueAchat = _cloneDeep(data.catalogueAchat);
              if (!this.utils.isCollectionNullOrEmpty(this.catalogueAchat.groupeAchatCaList))
                this.catalogueAchat.prix = this.catalogueAchat.groupeAchatCaList[0].prixUF.value;
            } else {
              this.catalogueAchat = this.catalogueAchatSvc.createEmptyCatalogueAchatDTO(this.produit);
              this.catalogueAchat.produitCarne = false;
              this.catalogueAchat.site = this.uniteDeProduction.site;
            }
          }

          this.initTitle();
          this.initFournisseurs();
          this.initData();
        }))
      .subscribe()
  };

  initData = () => {
    this.ratioUCUF = this.utils.roundTo(this.catalogueAchat.ratioUniteCommandeUniteBase / this.catalogueAchat.ratioUniteFacturationUniteBase, 4);
    this.ratioUSUT = this.utils.roundTo(this.catalogueAchat.ratioUniteStockageUniteBase / this.catalogueAchat.ratioUniteTechnique, 4);

    this.isSameUCUF = this.catalogueAchat.uniteDeCommande.id === this.catalogueAchat.uniteDeFacturation.id;
    if (this.isSameUCUF) this.ratioUCUF = 1;

    this.isSameUSUT = this.catalogueAchat.uniteTechnique.id === this.catalogueAchat.uniteDeStockage.id;
    if (this.isSameUSUT) this.ratioUSUT = 1;
  }

  initAppellations = (): void => {
    this.subAppelations = this.catalogueAchatSvc.openDialogArticle$.pipe(
      switchMap(data => {
        const idsSites = this.auth2Svc.utilisateur.sites.map(s => s.id);
        return this.graphQlSvc.sendQuery(`
          {
            allAppellations(filters: {
              siteIds: [${idsSites}],
              actif: true
            }) {
                id,
                code,
                libelle,
                actif,
                durable,
                bio,
                site {
                    id,
                    libelle,
                },
            }
          }
        `);
      }),
      switchMap(data => {
        this.allAppellations = data.allAppellations;
        return this.catalogueAchatSvc.getProduitArticleAppellations(this.catalogueAchat.produitArticle.id);
      }),
      catchError(err => this.utils.handleError(err))
    )
      .subscribe(data => {
        this.appellationsProduit = data.resultList.map(appellations => appellations.appellationId);
      });
  };

  initAllergenes = (): void => {
    this.subAllergenes = this.catalogueAchatSvc.openDialogArticle$.pipe(
      switchMap(data => {
        const idsSites = this.auth2Svc.utilisateur.sites.map(s => s.id);
        return this.graphQlSvc.sendQuery(`
          {
            allAllergenes(filters: {
                siteIds: [${idsSites}]
              }) {
              id,
              libelle,
              appellationInformationConsommateur,
              code,
              actif,
              site { id }
            }
          }
        `);
      }),
      switchMap(data => {
        this.allergeneList = data.allAllergenes;
        if(!this.catalogueAchat.id) this.produitAllergenes.push(this.allergeneList.find(allergene => allergene.code == 'NONRENS').id);
        else return this.catalogueAchatSvc.getProduitArticleAllergenes(this.catalogueAchat.produitArticle.id);
      }),
      catchError(err => this.utils.handleError(err))
    )
      .subscribe(data => {
        this.produitAllergenes = data.resultList.map(allergenes => allergenes.allergeneId);
      });

  };

  initOrigines = (): void => {
    const idsSites = this.auth2Svc.utilisateur.sites.map(s => s.id);
    this.subOrigines = this.graphQlSvc.sendQuery(`
          {
            allOrigines(filters: {
                siteIds: [${idsSites}]
              }) {
              id,
              libelle,
              code,
              actif,
              site { id }
            }
          }
        `)
      .subscribe(data => {
        this.origines = data.allOrigines;
      });
  }

  closeDialog = () => {
    this.displayDialog = false;
  };

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subFournisseurs);
    this.utils.unsubscribe(this.subUnitesDeProduction);
    this.utils.unsubscribe(this.subOpenDialog);
    this.utils.unsubscribe(this.subArticleAppellations);
    this.utils.unsubscribe(this.subCloseDialogPeriodPrice);
    this.utils.unsubscribe(this.subFindCaById);
    this.utils.unsubscribe(this.subOrigines);
  }

  initTitle = (): void => {
    if (this.catalogueAchat.id === 0) {
      this.title = 'Créer un article';
    } else {
      this.title = `Modifier l'article ${this.catalogueAchat.produitArticle.libelle.toUpperCase()} /  ${this.catalogueAchat.fournisseur.libelle}`;
    }
  };

  toggleFullScreen = (): void => {
    this.fullScreen = !this.fullScreen;
  }

  private initUnitesDeMesure = (): void => {
    this.subUnitesDeProduction = this.unitesDeMesuresSvc.getAllUniteDeMesure().subscribe((data: UniteDeMesureDTO[]) => {
      if (data) {
        this.unitesDeMesure = data['resultList'];
      } else
        this.unitesDeMesure = [];
    });
  };

  isFormValid = (): boolean => {
    return this.articleForm?.valid &&
      this.catalogueAchat.prix > 0 &&
      this.catalogueAchat.ratioUniteCommandeUniteBase > 0 &&
      this.catalogueAchat.ratioUniteFacturationUniteBase > 0 &&
      this.catalogueAchat.ratioUniteStockageUniteBase > 0 &&
      this.produitDeclinaison?.getSelectedItem() &&
      this.uniteDeFacturation?.getSelectedItem() &&
      this.uniteDeCommande?.getSelectedItem() &&
      this.uniteStockage?.getSelectedItem() &&
      this.catalogueAchat?.fournisseur !== undefined;
  }

  isDisabled = (): boolean => !this.utils.isCollectionNullOrEmpty(this.catalogueAchat.caByPeriod)
    && this.catalogueAchat.caByPeriod.filter(capp => capp.dateEffective <= new Date()).length != 0;

  canModifyForUpdate = (): boolean => this.catalogueAchat.site && this.catalogueAchat.site.id && this.uniteDeProduction.site.id == this.catalogueAchat.site.id && !this.catalogueAchat.caParentId;

  onChange = (id: number, propriete: string): void => {
    this.catalogueAchat[propriete] = this.unitesDeMesure.find(unite => unite.id == id);

    this.isSameUCUF = this.catalogueAchat.uniteDeCommande.id === this.catalogueAchat.uniteDeFacturation.id;
    if (this.isSameUCUF) this.ratioUCUF = 1;
    this.onChangeRatioUC();

    this.isSameUSUT = this.catalogueAchat.uniteTechnique.id === this.catalogueAchat.uniteDeStockage.id;
    if (this.isSameUSUT) this.ratioUSUT = 1;
    this.onChangeRatioUS();
  }

  private initFournisseurs = (): void => {
    let fournisseur;
    if (this.catalogueAchat.id == 0) {
      this.subFournisseurs = this.gestionFournisseurSvc.getFournisseuByIdUdp(this.uniteDeProduction.id).subscribe((data) => {
        if (data) {
          this.fournisseursList = data.resultList.filter(item => item.site.id == this.uniteDeProduction.site.id);
        }
      })
    } else {
      this.fournisseursList = []
      this.subFournisseurs = this.gestionFournisseurSvc.getFournisseuById(this.catalogueAchat.fournisseur.id).subscribe((data) => {
        if (data) {
          fournisseur = data.one;
          this.fournisseursList.push(fournisseur);
        }
      });
    }
  };

  periodPriceClosedSubscription = (): void => {
    this.subCloseDialogPeriodPrice = this.catalogueAchatSvc.savedPricePeriod$
      .subscribe(() => this.findById());
  }

  findById = (): void => {
    this.subFindCaById = this.catalogueAchatSvc.findCaById(this.catalogueAchat.id, this.uniteDeProduction.id).subscribe(result => {
      this.catalogueAchat = result.one;
      if (!this.utils.isCollectionNullOrEmpty(this.catalogueAchat.groupeAchatCaList))
        this.catalogueAchat.prix = this.catalogueAchat.groupeAchatCaList[0].prixUF.value;
      this.initTitle();
      this.initData();
    });
  }

  saveCatalogueAchat = (): void => {

    this.catalogueAchat.produitArticle.produitDeclinaison = this.produitDeclinaison.getSelectedItem();

    if (!this.catalogueAchat.origineAnimale)
      this.catalogueAchat.origineAnimale = new OrigineCarneDTO();

    this.catalogueAchat.origineAnimale.naissance = this.naissanceLookup?.getSelectedItem();
    this.catalogueAchat.origineAnimale.elevage = this.elevageLookup?.getSelectedItem();
    this.catalogueAchat.origineAnimale.abattage = this.abattageLookup?.getSelectedItem();

    this.catalogueAchat.prix = parseFloat((this.catalogueAchat.prix + '').trim().replace(',', '.'));
    this.catalogueAchat.prixOrigine = this.catalogueAchat.prix;

    const idsAllergene: number[] = this.allergeneList.filter((allergene) => this.produitAllergenes.includes(allergene.id))
      .map(allegene => allegene.id);
    const idsAppellation: number[] = this.allAppellations.filter((appellation) => this.appellationsProduit.includes(appellation.id))
      .map(appellation => appellation.id);

    this.catalogueAchatSvc.saveCatalogueAchat(this.catalogueAchat, idsAllergene, idsAppellation, false).subscribe(response => {
      if (!this.utils.isResponseSupplierError(response)) {

        this.catalogueAchat = response.one;
        if (!this.utils.isCollectionNullOrEmpty(this.catalogueAchat.groupeAchatCaList))
          this.catalogueAchat.prix = this.catalogueAchat.groupeAchatCaList[0].prixUF.value;
        this.cads.catalogueAchat = this.catalogueAchat;
        this.cads.isNew = false;

        // on notifie les abonnés que l'article a été sauvegardé
        this.catalogueAchatSvc.announceSavedDTO(response);
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Enregistrement de l'article "${this.catalogueAchat.produitArticle.libelle.toUpperCase()}" effectué. Vous pouvez (si vous le voulez) renseigner les appellations et les allergènes.`);
      }
      this.displayDialog = false;
    });

    this.closeDialog();
  }

  onChangeSelectedFournisseur = ($event) => {
    if (this.catalogueAchat.id == 0) this.catalogueAchat.fournisseur = $event.currentSelectedRowKeys[0];
  }

  onAllergenesChange = ($event): void => {
    this.produitAllergenes = $event.value;
  };

  onAppellationsChange = ($event): void => {
    this.appellationsProduit = $event.value;
  };

  openDialogPeriodPrice = () => {
    this.catalogueAchatSvc.announceOpenDialogPricePeriod(this.catalogueAchat, this.uniteDeProduction);
  };

  onChangeRatioUC = () => this.catalogueAchat.ratioUniteCommandeUniteBase = this.ratioUCUF * this.catalogueAchat.ratioUniteFacturationUniteBase;

  onChangeRatioUCUF = () => {
    if (!this.isSameUCUF) this.ratioUCUF = this.utils.roundTo(this.catalogueAchat.ratioUniteCommandeUniteBase / this.catalogueAchat.ratioUniteFacturationUniteBase, 4);
    else this.catalogueAchat.ratioUniteFacturationUniteBase = this.catalogueAchat.ratioUniteCommandeUniteBase;
  }

  onChangeRatioUS = () => this.catalogueAchat.ratioUniteStockageUniteBase = this.ratioUSUT * this.catalogueAchat.ratioUniteTechnique;

  onChangeRatioUSUT = () => {
    if (!this.isSameUSUT) this.ratioUSUT = this.utils.roundTo(this.catalogueAchat.ratioUniteStockageUniteBase / this.catalogueAchat.ratioUniteTechnique, 4);
  }

  onPriceUpdated = () => {
    this.catalogueAchat.groupeAchatCaList[0].prixUF.value = this.catalogueAchat.prix;
  };

  getOriginePlaceholder(origineName: string, selectedId: number) {
    if (this.isDisabled() && !selectedId)
      return `Aucun lieu ${origineName} sélectionné`
    return `Choisir un lieu ${origineName}`;
  }

}
