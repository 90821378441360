<p-dialog
  (onHide)="closeDialog()"
  [dismissableMask]="false"
  position="top"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayDialog"
  [responsive]="true"
  showEffect="fade"
  [modal]="true"
  [breakpoint]="640"
  [style]="{'width':'1200px'}"
>

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-money-bill-transfer mg-r-5"></i>Historique GEF
    </div>
  </ng-template>

  <p-scrollPanel [style]="getScrollPanelStyle()">

    <dx-data-grid
      [dataSource]="gefAuditList"
      keyExpr="id"
      width="100%"
      [hoverStateEnabled]="true"
      [allowColumnResizing]="true"
      [rowAlternationEnabled]="true"
      [showRowLines]="true"
      [showBorders]="true"
      #grid>
      <dxi-column dataField="gefConnection" caption="Interface" cellTemplate="templateInterface"
                  alignement="left"></dxi-column>
      <dxi-column dataField="selectedFlux" caption="Flux" cellTemplate="templateFlux"
                  alignement="left"></dxi-column>
      <dxi-column dataField="lastSynchro" caption="Dernière synchronisation" cellTemplate="templateSynchroDate"
                  alignement="left"></dxi-column>

      <div *dxTemplate="let cell of 'templateSynchroDate'">
        {{ cell.row.data.lastSynchro  | date:'dd/MM/yyyy HH:mm:ss' }}
      </div>

      <div *dxTemplate="let cell of 'templateInterface'" class="wrap-text">
        {{ cell.row.data.gefConnection.gefProvider.libelle | titlecase }}
      </div>

      <div *dxTemplate="let cell of 'templateFlux'" class="wrap-text">
        {{ cell.row.data.selectedFlux }}
      </div>

    </dx-data-grid>

  </p-scrollPanel>

  <!--  FOOTER-->
  <ng-template pTemplate="footer">

    <!--    BOUTONS D'ACTIONS-->
    <ng-container *ngTemplateOutlet="tplActionButtons"></ng-container>

  </ng-template>

</p-dialog>

<!--TEMPLATE DES BOUTONS D'ACTION-->
<ng-template #tplActionButtons>
  <yo-button
    buttonClass="cta-inner-delete"
    iconClass="fas fa-times" (onClick)="closeDialog()" tooltip="Fermer"></yo-button>
</ng-template>
