export default class BonConditionnementSettingsMl {
  landscape: boolean = false;
  condiDateFilter: Date[];
  consoDateFilter: Date[];
  printDetails: boolean = false;
  displayWeight: boolean = false;
  dontSortByConsumptionDay: boolean = false;
  mode: string;
  atelierFilter: string[];
  displayTotal: boolean = false;
}
