import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {combineLatest, Observable} from 'rxjs';
import {ContratmenuDTO} from '../../core/dtos/contratmenu-dto';
import {HttpClient} from '@angular/common/http';
import {Auth2Service} from '../../core/services/security/auth2.service';
import {GenericDatagridService} from '../../core/services/generics/generic-datagrid.service';
import {PointDeLivraisonService} from '../../core/services/entities/point-de-livraison.service';
import {PointDeLivraisonDTO} from '../../core/dtos/point-de-livraison-d-t-o';
import {RepasDTO} from '../../core/dtos/repas-dto';
import {ContratMenuConvive__ContrainteAlimDTO} from '../../core/dtos/contratmenuconvive__contrainte-alim-dto';
import {ContratMenuConviveRepasPlcDateDTO} from '../../core/dtos/cmcr-plc-date-dto';
import {MPlcRepasRow} from '../../core/models/plc-repas-row';

@Injectable({
  providedIn: 'root'
})
export class MenuPlcResolverService implements Resolve<MenuPlcSupplier> {

  constructor(private http: HttpClient,
              private auth2Svc: Auth2Service,
              private gds: GenericDatagridService,
              private plcSvc: PointDeLivraisonService
  ) {
  }

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MenuPlcSupplier> | Promise<MenuPlcSupplier> | MenuPlcSupplier => {

    const mps = new MenuPlcSupplier();
    const plcList$ = this.plcSvc.getPlcListBySitesPrestations();
    const all$ = combineLatest([plcList$]);
    return all$.toPromise().then(response => {
      mps.plcList = response[0].resultList;
      return mps;
    });
  };
}

export class MenuPlcSupplier {

  contratsMenus: ContratmenuDTO[];
  plcList: PointDeLivraisonDTO[];
  repasList: RepasDTO[];

}



export class PlcWeek {
  repasList: PlcRepasWeek[];
}

export class PlcRepasWeek {
  rows: ContratMenuConvive__ContrainteAlimDTO[];
  cols: Date[];
  cells: ContratMenuConviveRepasPlcDateDTO[];
  repas: RepasDTO;
}

export class PlcWeekDetails {

  cmcCa: ContratMenuConvive__ContrainteAlimDTO;
  cols: Date[];
  repas: PlcRepas[];

}

export class PlcRepas {
  rows: MPlcRepasRow[];
  canModifyEffectif: boolean;
}
