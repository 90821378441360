import {Component, OnDestroy, OnInit} from "@angular/core";
import {UtilsService} from "../../../../core/utils/utils.service";
import {GestionGefService} from "../../../../core/services/gef/gestion-gef.service";
import {Subscription} from "rxjs";
import {GefAuditDto} from "../../../../core/dtos/gef/gef-audit-dto";

@Component({
  selector: 'yo-dialog-gef-audit',
  templateUrl: './dialog-gef-audit.component.html',
  styleUrls: ['./dialog-gef-audit.component.scss']
})
export class DialogGefAuditComponent implements OnInit, OnDestroy {

  subGefAudit: Subscription;
  displayDialog = false;

  gefAuditList: GefAuditDto[];

  constructor(public utils: UtilsService,
              public gestionGefSvc: GestionGefService) {
  }

  ngOnInit() {
    this.initData();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subGefAudit);
  }

  initData = () => {
    this.subGefAudit = this.gestionGefSvc.displayGefAudit$.subscribe(response => {
      this.displayDialog = true;
      this.gefAuditList = response;
    });
  };

  closeDialog = () => {
    this.gefAuditList = [];
    this.displayDialog = false;
  };

  getScrollPanelStyle = () => {
    if (window.innerHeight <= 768) {
      return {width: '100%', height: '540px'};
    } else if (window.innerHeight <= 1024) {
      return {width: '100%', height: '704px'};
    } else {
      return {width: '100%', height: '740px'};
    }
  };


}
