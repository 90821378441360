import {Injectable} from "@angular/core";

import commonErrorsJsonI8n from '../../i8n/errors/common.json';
import pmsErrorsJsonI8n from '../../i8n/errors/pms.json';
import conditionnementErrorsJsonI8n from '../../i8n/errors/conditionnement.json';
import portailErrorJsonI8n from '../../i8n/errors/portail.json';
import pdErrorJsonI8n from '../../i8n/errors/produit.declinaison.json';
import productErrorJsonI8n from '../../i8n/errors/produit.json';
import fournisseurErrorJsonI8n from '../../i8n/errors/fournisseur.json';
import catalogueAchatErrorJsonI8n from '../../i8n/errors/catalogue.achat.json';
import codesErrorJsonI8n from '../../i8n/errors/codes.json';
import pointsLivraisonErrorJsonI8n from '../../i8n/errors/points.livraison.json';
import receptionErrorJsonI8n from '../../i8n/errors/reception.json';
import ftErrorJsonI8n from '../../i8n/errors/fiche.technique.json';
import gemrcnErrorJsonI8n from '../../i8n/errors/gemrcn.json';
import historyErrorJsonI8n from '../../i8n/errors/history.json';
import listeBesoinDenreeErrorJsonI8n from '../../i8n/errors/liste.besoin.denree.json';
import referentielErrorJsonI8n from '../../i8n/errors/referentiel.json';
import udpErrorJsonI8n from '../../i8n/errors/udp.json';
import marketErrorJsonI8n from '../../i8n/errors/market.json';
import progressErrorJsonI8n from '../../i8n/errors/progress.json';
import lockErrorJsonI8n from '../../i8n/errors/lock.json';
import usersErrorJsonI8n from '../../i8n/errors/users.json';
import productionErrorJsonI8n from '../../i8n/errors/production.json';
import stocksErrorJsonI8n from '../../i8n/errors/stocks.json';
import documentStorageErrorJsonI8n from '../../i8n/errors/document.storage.json';
import commandsErrorJsonI8n from '../../i8n/errors/commands.json';
import ordersErrorJsonI8n from '../../i8n/errors/orders.json';
import menusErrorJsonI8n from '../../i8n/errors/menus.json';
import administrationErrorJsonI8n from '../../i8n/errors/administration.json';
import storageErrorJsonI8n from '../../i8n/errors/storage.json';
import clientsEffectifsJsonI8n from '../../i8n/errors/clients.effectifs.json';
import wizardConditionnementJsonI8n from '../../i8n/errors/wizard.conditionnement.json';
import preferencesJsonI8n from '../../i8n/errors/preferences.json';
import organizationsJsonI8n from '../../i8n/errors/organizations.json';
import avoirsErrorsJsonI8n from '../../i8n/errors/avoirs.json';
import authentificationJsonI8n from '../../i8n/errors/authentification.json';
import facturationJsonI8n from '../../i8n/errors/facturation.json';
import emailErrorsJsonI8n from '../../i8n/errors/emails.json';
import contratErrorsJsonI8n from '../../i8n/errors/contrats.json';

import commonWordsBusinessJsonI8n from '../../i8n/words/business/common.json';
import referentielWordsBusinessJsonI8n from '../../i8n/words/business/referentiel.json';
import conditionnementWordsBusinessJsonI8n from '../../i8n/words/business/conditionnement.json';
import pmsWordsBusinessJsonI8n from '../../i8n/words/business/pms.json';
import productionWordsBusinessJsonI8n from '../../i8n/words/business/production.json';
import organizationsRoundsJsonI8n from '../../i8n/words/business/organizations-rounds.json';
import avoirsWordsJsonI8n from '../../i8n/words/business/avoirs.json';

import questionsJsonI8n from '../../i8n/sentences/questions.json';
import commonJsonI8n from '../../i8n/sentences/common.json';

@Injectable({
  providedIn: 'root'
})
export class InternationalizationService {

  i8nLoaded: any;

  constructor() {
    this.i8nLoaded = {
      ...commonErrorsJsonI8n,
      ...pmsErrorsJsonI8n,
      ...commonWordsBusinessJsonI8n,
      ...referentielWordsBusinessJsonI8n,
      ...conditionnementWordsBusinessJsonI8n,
      ...pmsWordsBusinessJsonI8n,
      ...productionWordsBusinessJsonI8n,
      ...organizationsRoundsJsonI8n,
      ...conditionnementErrorsJsonI8n,
      ...portailErrorJsonI8n,
      ...pdErrorJsonI8n,
      ...productErrorJsonI8n,
      ...fournisseurErrorJsonI8n,
      ...catalogueAchatErrorJsonI8n,
      ...codesErrorJsonI8n,
      ...pointsLivraisonErrorJsonI8n,
      ...receptionErrorJsonI8n,
      ...ftErrorJsonI8n,
      ...gemrcnErrorJsonI8n,
      ...historyErrorJsonI8n,
      ...listeBesoinDenreeErrorJsonI8n,
      ...referentielErrorJsonI8n,
      ...udpErrorJsonI8n,
      ...marketErrorJsonI8n,
      ...progressErrorJsonI8n,
      ...lockErrorJsonI8n,
      ...usersErrorJsonI8n,
      ...productionErrorJsonI8n,
      ...stocksErrorJsonI8n,
      ...documentStorageErrorJsonI8n,
      ...commandsErrorJsonI8n,
      ...ordersErrorJsonI8n,
      ...menusErrorJsonI8n,
      ...administrationErrorJsonI8n,
      ...questionsJsonI8n,
      ...commonJsonI8n,
      ...storageErrorJsonI8n,
      ...clientsEffectifsJsonI8n,
      ...wizardConditionnementJsonI8n,
      ...preferencesJsonI8n,
      ...organizationsJsonI8n,
      ...avoirsErrorsJsonI8n,
      ...avoirsWordsJsonI8n,
      ...authentificationJsonI8n,
      ...emailErrorsJsonI8n,
      ...facturationJsonI8n,
      ...contratErrorsJsonI8n
    }
  }

  /**
   * Récupère le libellé complet et complété avec les arguments en fonction
   * de la langue passée en paramètre.
   * @param code Code libellé
   * @param args Arguments liés au libellé à afficher dans la langue choisie. Ce tableau peut être vide.
   * @param lang Code langue à 2 lettres (maj/min). fr est la valeur par défaut sinon renseigné.
   */
  getLabelFromCode = (code: string, args: string[], lang: string = 'fr'): string => {
    const langUsed = lang.toLowerCase();
    let label = this.i8nLoaded[code][langUsed];
    if (label && args && args.length) {
      for(let i = 0; i < args.length; ++i) {
        const strArg = args[i] !== '' && args[i] !== null && args[i] !== undefined && !/^\d+$/.test(args[i]) && this.i8nLoaded[args[i]] && code !== "ARGUMENT_MANDATORY" && code !== "EMPTY_LIST" ? this.i8nLoaded[args[i]][langUsed] : args[i];
        label = label.replace("{}", strArg);
      }
    }
    return label;
  }

}
