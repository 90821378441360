import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {MenusToolbarService} from '../../../../core/services/gestionmenus/menus-toolbar.service';
import {UtilsService} from '../../../../core/utils/utils.service';
import {PreferencesUtilisateurService} from '../../../../core/services/preferences-utilisateur.service';
import {MenusPlanning2Service} from '../../../../core/services/gestionmenus/menus-planning2.service';
import {
  CHOIX_EFFECTIF, CHOIX_TAUX_DE_PRISE,
  DATEPICKER_FR_INLINE,
  MENUS_PLANNING_CHOOSER,
  MSG_KEY,
  MSG_SEVERITY,
  OEM,
  OETDP,
  USER_PREFERENCE
} from '../../../../core/constants';
import {cloneDeep as _cloneDeep} from 'lodash'
import * as moment from 'moment';
import {catchError, debounceTime, switchMap} from 'rxjs/operators';
import {SelectItem} from 'primeng/api';
import {ToastService} from "../../../../core/services/technique/toast.service";
import InfoMenuValidationSupplier from "../../../../core/suppliers/gestionmenus/info-menu-validation-supplier";
import {ContratMenuConvive__ContrainteAlimDTO} from "../../../../core/dtos/contratmenuconvive__contrainte-alim-dto";
import {ContratMenuConviveDTO} from "../../../../core/dtos/contratmenuconvive-dto";
import {DxTreeViewComponent} from "devextreme-angular";
import {ContratmenuDTO} from "../../../../core/dtos/contratmenu-dto";
import {ContratsMenusConvivesService} from "../../../../core/services/gestioncontrats/contrats-menus-convives.service";

@Component({
  selector: 'yo-validation-menu',
  templateUrl: './validation-menu.component.html',
  styleUrls: ['./validation-menu.component.scss']
})
export class ValidationMenuComponent implements OnInit, OnDestroy, AfterViewInit {

  subValidationMenus: Subscription;
  subValidationMenuMonthChange: Subscription;

  displayDialog: boolean;
  localeFr = DATEPICKER_FR_INLINE;

  contrats: ContratmenuDTO[];
  prestations: ContratMenuConviveDTO[];
  prestationsFilter: ContratMenuConviveDTO[];
  regimesFilter: ContratMenuConvive__ContrainteAlimDTO[];
  selectedContrats: number[] = [];
  selectedPrestations: number[] = [];
  selectedRegimes: number[] = [];

  startDate: Date;
  stopDate: Date;
  selectedDates: Date[];
  disabledDays: number[] = [];
  datesCreationMenus: Date[] = [];
  datesSaisieEffectifs: Date[] = [];
  datesNonDisponibles: Date[] = [];
  datesFacuturation : Date[] = [];
  dateMin: Date;

  OEM = OEM;
  OETDP = OETDP;
  optionsEcraserMenu: SelectItem[] = [
    {label: 'E-', value: this.OEM.AUCUN_ECRASEMENT},
    {label: 'E', value: this.OEM.ECRASER_MENUS_SANS_MODIFICATION_MANUELLE},
    {label: 'E+', value: this.OEM.ECRASER_MENUS_AVEC_MODIFICATIONS_MANUELLES},

  ];

  optionsEcraserTauxDePrise: SelectItem[] = [
    {label: 'TDP-', value: OETDP.AUCUN_ECRASEMENT},
    {label: 'TDP+', value: OETDP.ECRASER},
  ];

  optionsChoixEffectif: any[] = [
    {label: 'Effectifs prévisionnels théoriques', value: CHOIX_EFFECTIF.EFFECTIF_THEORIQUE},
    {label: 'Derniers effectifs connus (le cas échéant)', value: CHOIX_EFFECTIF.DERNIER_EFFECTIF_CONNU},
  ];

  optionsChoixTauxDePrise: any[] = [
    {label: 'Taux de prise théoriques', value: CHOIX_TAUX_DE_PRISE.TAUX_DE_PRISE_THEORIQUE},
    {label: 'Derniers taux de prise connus (le cas échéant)', value: CHOIX_TAUX_DE_PRISE.DERNIER_TAUX_DE_PRISE_CONNU},
    {label: 'Taux de prise des menus', value: CHOIX_TAUX_DE_PRISE.TAUX_DE_PRISE_DES_MENUS},
  ];

  optionEcraserTauxDePrise = this.OETDP.AUCUN_ECRASEMENT;
  optionEcraserMenu = this.OEM.AUCUN_ECRASEMENT;
  optionChoixEffectif: CHOIX_EFFECTIF = CHOIX_EFFECTIF.DERNIER_EFFECTIF_CONNU;
  optionChoixTauxDePrise: CHOIX_TAUX_DE_PRISE = CHOIX_TAUX_DE_PRISE.DERNIER_TAUX_DE_PRISE_CONNU;

  isValidationMenuOk: boolean;

  chooserPlanning = MENUS_PLANNING_CHOOSER;

  @ViewChild('treeViewContrats', {read: DxTreeViewComponent}) treeViewContrats: DxTreeViewComponent;
  @ViewChild('treeViewPrestations', {read: DxTreeViewComponent}) treeViewPrestations: DxTreeViewComponent;
  @ViewChild('treeViewRegimes', {read: DxTreeViewComponent}) treeViewRegimes: DxTreeViewComponent;

  constructor(public menusToolbarSvc: MenusToolbarService,
              public mp2Svc: MenusPlanning2Service,
              public utils: UtilsService,
              public prefUserSvc: PreferencesUtilisateurService,
              public cmcSvc: ContratsMenusConvivesService,
              private toastSvc: ToastService) {
  }

  ngOnInit() {
    // A l'ouverture du dialog
    this.subValidationMenus = this.menusToolbarSvc.displayValidationMenusDialog$.subscribe((response: InfoMenuValidationSupplier) => {

      this.contrats = response.contrats;
      this.prestationsFilter = response.prestations;
      this.regimesFilter = response.regimes;

      this.selectedContrats.push(response.selectedContrat.id);
      this.selectedPrestations.push(response.selectedPrestation.id);
      this.selectedRegimes.push(response.selectedRegime.id);
      this.displayDialog = response.displayDialog;

      this.startDate = new Date();
      this.stopDate = new Date();

      this.optionEcraserMenu = this.OEM.AUCUN_ECRASEMENT;
      this.optionEcraserTauxDePrise = this.OETDP.AUCUN_ECRASEMENT;

      if (!this.utils.isNullOrEmpty(this.mp2Svc.planningMenus)) {
        if (this.mp2Svc.planningMenus.selectedDateMenu) {
          this.startDate = _cloneDeep(this.mp2Svc.planningMenus.selectedDateMenu);
          this.stopDate = _cloneDeep(this.mp2Svc.planningMenus.selectedDateMenu);
        }
        if (!this.utils.isCollectionNullOrEmpty(this.mp2Svc.planningMenus.dateList)) {
          this.startDate = _cloneDeep(moment(this.mp2Svc.planningMenus.dateList[0].date).toDate());
          this.stopDate = _cloneDeep(moment(this.mp2Svc.planningMenus.dateList[this.mp2Svc.planningMenus.dateList.length - 1].date).toDate());
        }

      }

      this.selectedDates = [this.startDate, this.stopDate];
      this.isValidationMenuOk = this.validDates(this.selectedDates);

      this.menusToolbarSvc.announceValidationMenuMonthChange(null);
    });

    // Au changement de mois sur le calendrier
    this.subValidationMenuMonthChange = this.menusToolbarSvc.validationMenuMonthChange$.pipe(
      debounceTime(100),
      switchMap(event => this.mp2Svc.changeMonth(this.mp2Svc.planningMenus.contratMenuConviveList[0], this.mp2Svc.planningMenus.selectedDateMenu, event)),
      catchError(err => this.utils.handleError(err))
    ).subscribe(response => {
      this.disabledDays = response.disabledDays;
      this.datesSaisieEffectifs = response.datesSaisieEffectifs;
      this.datesCreationMenus = response.datesCreationMenus;
      this.datesFacuturation = response.datesFacturation;
      this.dateMin = response.dateMin;
    });

  }

  closeDialog = () => {
    this.contrats = [];
    this.prestationsFilter = [];
    this.regimesFilter = [];
    this.selectedContrats = [];
    this.selectedPrestations = [];
    this.selectedRegimes = [];
    this.treeViewContrats?.instance.unselectAll();
    this.treeViewPrestations?.instance.unselectAll();
    this.displayDialog = false;
  };

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subValidationMenus);
    this.utils.unsubscribe(this.subValidationMenuMonthChange);
  }

  changeDates = ($event: any) => {
    console.log('validation-menu changeDates', $event);

    this.isValidationMenuOk = this.validDates(this.selectedDates);

    this.menusToolbarSvc.announceValidationMenuMonthChange($event);

  };

  validDates = (dates: Date[]) => {
    return !(this.utils.isCollectionNullOrEmpty(dates) || dates.length !== 2
      || this.utils.isNullOrEmpty(dates[0])
      || this.utils.isNullOrEmpty(dates[1]));
  };

  validerMenus = (valid: boolean) => {
    // console.log('validation-menu validerMenus');
    this.isValidationMenuOk = this.validDates(this.selectedDates);
    if (this.isValidationMenuOk) {
      // console.log('validation-menu validerMenus, on peut valider');

      this.mp2Svc.validerMenus(this.selectedDates, this.selectedPrestations, this.optionEcraserMenu,
        this.optionEcraserTauxDePrise, this.optionChoixEffectif, this.optionChoixTauxDePrise, valid).subscribe(response => {

        if (!this.utils.isResponseSupplierError(response)) {
          this.closeDialog();

          if (valid)
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Période validée avec succès`);
          else
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, `Période annulée avec succès`);

          // recharger le planning des menus
          this.menusToolbarSvc.announcePrestations(this.mp2Svc.planningMenus.contratMenuConviveList);
        }
      });
    }
  };

  getMonthMenusCompoDates = ($event: any) =>
    this.menusToolbarSvc.announceValidationMenuMonthChange($event);

  ngAfterViewInit(): void {

  }

  displayExprContrat = (item) => item && item.libelle ;

  /**
   * Méthode pour les multi-select avec les Tree-views
   */
  onDropDownBoxValueChanged = (e): void => {
    switch (e) {
      case MENUS_PLANNING_CHOOSER.CONTRAT:
        this.updateSelection(this.treeViewContrats && this.treeViewContrats.instance, e);
        break;
      case MENUS_PLANNING_CHOOSER.PRESTATION:
        this.updateSelection(this.treeViewPrestations && this.treeViewPrestations.instance, e);
        break;
      case MENUS_PLANNING_CHOOSER.REGIME:
        this.updateSelection(this.treeViewRegimes && this.treeViewRegimes.instance, e);
        break;
    }
  }

  onTreeViewReady = (e, value: string): void => this.updateSelection(e.component, value);

  updateSelection = (treeView, value): void => {
    if (!treeView) return;

    switch (value) {
      case MENUS_PLANNING_CHOOSER.CONTRAT:
        if (!this.selectedContrats) treeView.unselectAll();
        if (this.selectedContrats) this.selectedContrats.forEach(value => treeView.selectItem(value));
        break;

      case MENUS_PLANNING_CHOOSER.PRESTATION:
        if (!this.selectedPrestations) treeView.unselectAll();
        if (this.selectedPrestations) this.selectedPrestations.forEach(value => treeView.selectItem(value));
        break;

      case MENUS_PLANNING_CHOOSER.REGIME:
        if (!this.selectedRegimes) treeView.unselectAll();
        if (this.selectedRegimes) this.selectedRegimes.forEach(value => treeView.selectItem(value));
        break;
    }
  };

  onTreeViewSelectionChanged = (e, value: MENUS_PLANNING_CHOOSER): void => {
    switch (value) {
      case MENUS_PLANNING_CHOOSER.CONTRAT:
        this.selectedContrats = e.component.getSelectedNodeKeys();
        break;

      case MENUS_PLANNING_CHOOSER.PRESTATION:
        this.selectedPrestations = e.component.getSelectedNodeKeys();
        break;

      case MENUS_PLANNING_CHOOSER.REGIME:
        this.selectedRegimes = e.component.getSelectedNodeKeys();
        break;
    }

    this.changePlanning(e, value);
  };

  changePlanning = (event: any, chooser: MENUS_PLANNING_CHOOSER) => {
    switch (chooser) {
      case MENUS_PLANNING_CHOOSER.CONTRAT:
        if (this.utils.isNullOrEmpty(this.selectedContrats)) {
          this.selectedPrestations = [];
          this.selectedRegimes = [];
        } else {
          this.changePrestationFilter();
          this.changeRegimeFilter();
        }
        break;

      case MENUS_PLANNING_CHOOSER.PRESTATION:
        if (this.utils.isNullOrEmpty(this.selectedPrestations)) {
          this.regimesFilter = [];
        } else {
          this.changeRegimeFilter();
        }
    }
  };

  changePrestationFilter = (): void => {
    this.cmcSvc.findByIdsOffreAlimentaire(this.selectedContrats).subscribe(res => this.prestationsFilter = res.resultList);

    if(this.selectedContrats.length == 1) {
      let p: ContratMenuConviveDTO;
      if (!this.utils.isCollectionNullOrEmpty(this.prestationsFilter) && this.prestationsFilter.length == 1) {
        p = this.prestationsFilter[0];
      } else {
        p = this.loadPreferences(USER_PREFERENCE.GESTIONMENUS_PRESTATION, 'id', this.prestationsFilter);
      }

      if (p) {
        if(!this.selectedPrestations.find(id => id === p.id)) this.selectedPrestations.push(p.id);
        this.selectedPrestations.forEach(p => this.treeViewPrestations?.instance.selectItem(p));
      }
    }
  }

  changeRegimeFilter = (): void => {
    this.prestationsFilter.forEach(item => {
      item.contratMenuConvive__contrainteAlimList.forEach(cmcCa => {
        if (!this.regimesFilter.find(r => r.regimeId === cmcCa.regimeId)) {
          this.regimesFilter.push(cmcCa);
        }
      })
    });

    if(this.selectedContrats.length == 1) {
      let r: ContratMenuConvive__ContrainteAlimDTO;
      if (!this.utils.isCollectionNullOrEmpty(this.regimesFilter) && this.regimesFilter.length == 1) {
        r = this.regimesFilter[0];
      } else {
        r = this.loadPreferences(USER_PREFERENCE.GESTIONMENUS_REGIME, 'id', this.regimesFilter);
      }
      if (!this.selectedRegimes.find(id => id === r.regimeId)) this.selectedRegimes.push(r.regimeId);
      this.selectedRegimes.forEach(r => this.treeViewRegimes.instance.selectItem(r));
    }
  };

  loadPreferences = (key: string, property: string, objects: any[]) => {
    const pref = this.prefUserSvc.getPreferenceUtilisateurIntValue(key);
    const itemArr = objects ? objects.filter(item => item[property] === pref) : undefined;
    if (!this.utils.isNullOrEmpty(itemArr) && itemArr?.length > 0) {
      return this.utils.preSelectSingleList(objects, itemArr[0])
    } else {
      if (objects.length > 0) {
        return objects[0];
      } else {
        return undefined;
      }
    }
  };

  changeChoixEffectif = ($event: any) => {
    this.optionChoixEffectif = $event.value;
  };

  changeChoixTauxDePrise = ($event: any) => {
    this.optionChoixTauxDePrise = $event.value;
  };
}
