import {Component, OnDestroy, OnInit} from "@angular/core";
import {GefConnectionDto} from "../../../core/dtos/gef/gef-connection-dto";
import {GefConnectionSavedSupplier, GestionGefService} from "../../../core/services/gef/gestion-gef.service";
import {UtilsService} from "../../../core/utils/utils.service";
import {Subscription} from "rxjs";


@Component({
  selector: 'yo-connections-gef-config',
  templateUrl: './connections-gef-config.component.html',
  styleUrls: ['./connections-gef-config.component.scss']
})
export class ConnectionsGefConfigComponent implements OnInit, OnDestroy {

  gefConnectionList: GefConnectionDto[] = [];
  subFindAllGefConnections: Subscription;
  subUpdateGefConnection: Subscription;

  constructor(public gestionGefSvc: GestionGefService,
              public utilsSvc: UtilsService) {
  }

  ngOnInit(): void {
    this.subFindAllGefConnections = this.gestionGefSvc.findAllGefConnections().subscribe(results => {
      this.gefConnectionList = results.resultList
    })
    this.updateGefConnection();
  }

  updateGefConnection = (): void => {
    this.subUpdateGefConnection = this.gestionGefSvc.gefConnectionSaved$
      .subscribe((response: GefConnectionSavedSupplier) => {
        if (response.isUpdate) {
          const idx: number = this.gefConnectionList.findIndex(connection => connection.id === response.gefConnection.id);
          this.gefConnectionList[idx] = response.gefConnection;
        } else {
          this.gefConnectionList.push(response.gefConnection);
        }
      });
  };

  openEditConfig(connection: GefConnectionDto) {
    this.gestionGefSvc.announceOpenGefConnectionFluxEditionDialog(connection);
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subUpdateGefConnection);
    this.utilsSvc.unsubscribe(this.subFindAllGefConnections);
  }
}
