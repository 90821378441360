import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HttpService} from '../technique/http.service';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {saveAs as fs_saveAs} from 'file-saver';
import {HttpClient} from "@angular/common/http";
import {UtilsService} from "../../utils/utils.service";
import {PRINT_MODE} from "../../constants";
import BonLivraisonSettingsMl from "../../dtos/conditionnement/bon-livraison-settings-ml";
import BonConditionnementSettingsMl from "../../dtos/conditionnement/bon-conditionnement-settings-ml";
import BonAllotissementSettingsMl from "../../dtos/conditionnement/bon-allotissement-settings-ml";

export const URL_GET_ALL_RESULTS = `dolrest/conditionnements/calcul-conditionnements-plats/results/`;
export const URL_GET_ALL_CHILDREN_RESULTS = `dolrest/conditionnements/calcul-conditionnements-plats/children-results/`;
export const URL_START_CALCULATION = `dolrest/conditionnements/calcul-conditionnements-plats/start-calculation/`;
export const URL_CLEAN_CALCUL_IN_PROGRESS = `dolrest/conditionnements/calcul-conditionnements-plats/clean-calcul-in-progress/`;
export const URL_EDITION_BON_CONDITIONNEMENT = `dolrest/conditionnements/calcul-conditionnements-plats/bon-conditionnement/`;
export const URL_EDITION_BON_LIVRAISON = `dolrest/conditionnements/calcul-conditionnements-plats/bon-livraison/`;
export const URL_GET_PLC_BON_LIVRAISON = `dolrest/gestion-production/planProduction/get-plc/`;
export const URL_EDITION_BON_ALLOTISSEMENT = `dolrest/conditionnements/calcul-conditionnements-plats/bon-allotissement/`;


@Injectable({
  providedIn: 'root'
})
export class PreparationConditionnementService {

  constructor(private httpSvc: HttpService, private httpClient: HttpClient,
              public utils: UtilsService) {
  }

  private subCalculateConditionnementErrors = new Subject<any>();
  calculateConditionnementErrors$ = this.subCalculateConditionnementErrors.asObservable();

  private subAskPrintConditionnement = new Subject<any>();
  askPrintConditionnement$ = this.subAskPrintConditionnement.asObservable();

  private subPrintErrorsBonlivraison = new Subject<any>();
  printErrorsBonlivraison$ = this.subPrintErrorsBonlivraison.asObservable();

  private subPrintErrorsBonAllotissement = new Subject<any>();
  printErrorsBonAllotissement$ = this.subPrintErrorsBonAllotissement.asObservable();

  /**
   * Récupère le calcul des conditionnements regroupés et non regroupés pour
   * un plan de production.
   * @param pid Identifiant du plan de production
   * @returns {Observable<>}
   */
  getAllResults = (pid: number): Observable<ResponseWrapper<any>> => this.httpSvc.get(`${URL_GET_ALL_RESULTS}${pid}`, null);

  /**
   * Récupère le détails des calculs d'une ligne conditionnement
   * @param parentId Identifiant de la ligne parente
   */
  getChildrenResults = (parentId: number): Observable<ResponseWrapper<any>> => this.httpSvc.get(`${URL_GET_ALL_CHILDREN_RESULTS}${parentId}`, null);

  /**
   * Lance le calcul des conditionnements d'un plan de production.
   * @param pid Identifiant du plan de production
   */
  startCalculation = (pid: number, refresh?: boolean): Observable<ResponseWrapper<any>> => this.httpSvc.post(`${URL_START_CALCULATION}${pid}?refresh=${refresh}`, null, null);

  cleanCalculInProgress = (pid: number) => this.httpClient.delete(`${URL_CLEAN_CALCUL_IN_PROGRESS}${pid}`).subscribe();

  /**
   *
   * @param pid
   * @param mode SIMPLE / DETAILS
   */
  printBonConditionnement = (pid: number, printSettings: BonConditionnementSettingsMl): void => {
    this.httpClient.post(`${URL_EDITION_BON_CONDITIONNEMENT}${pid}`, printSettings, {responseType: 'blob'})
      .subscribe(response => {
        let blob = new Blob([response], {type: 'application/pdf'});
        fs_saveAs(blob, `edition-bon-conditionnement-plan-prod-${pid}-${printSettings.printDetails === true ? 'detaillé' : 'simple'}-${new Date().getTime()}.pdf`);
      });
  };

  printBonLivraison = (pid: number, settings: BonLivraisonSettingsMl, printMode: PRINT_MODE, force: Boolean = false): any => {
    this.httpClient.post(`${URL_EDITION_BON_LIVRAISON}${pid}/${printMode}/${force}`, settings, {responseType: 'blob'})
      .subscribe((response: Blob) => {
        response.arrayBuffer().then(arrayBuffer => {
          try {
            const result = new TextDecoder().decode(arrayBuffer);
            // Try to parse JSON, if it fails then we assume it's a binary PDF
            const jsonResult = JSON.parse(result);
            if (jsonResult !== null && !this.utils.isResponseSupplierError(jsonResult)) {
              if (typeof jsonResult === 'object') {
                jsonResult.settings = settings;
                jsonResult.printMode = printMode;
                jsonResult.idPlanProduction = pid;
                if (jsonResult.hasErrors) this.annnounceCalculConditionnementErrors(jsonResult);
                else if (jsonResult.platsErreurs.length > 0) this.announcePrintErrorsBonLivraison(jsonResult);
              }
            }
          } catch (e) {
            // If JSON parsing fails, we treat it as binary data (PDF)
            let blob = new Blob([response], {type: 'application/pdf'});
            fs_saveAs(blob, `edition-bon-livraison-plan-prod-${pid}.pdf`);
          }
        });
      });
  };

  announcePrintErrorsBonLivraison = (response: Map<string, Object>): void => {
    this.subPrintErrorsBonlivraison.next(response);
  }

  announcePrintConditionnement = (): void => {
    this.subAskPrintConditionnement.next(null);
  }

  annnounceCalculConditionnementErrors = (response: any): void => {
    this.subCalculateConditionnementErrors.next(response);
  }

  announcePrintErrorsBonAllotissement = (response: Map<string, Object>): void => {
    this.subPrintErrorsBonAllotissement.next(response);
  }

  printBonAllotissement = (settings : BonAllotissementSettingsMl): any => {
    this.httpClient.post(`${URL_EDITION_BON_ALLOTISSEMENT}`, settings, {responseType: 'blob'})
      .subscribe(response => {
        response.arrayBuffer().then(arrayBuffer => {
          try {
            const result = new TextDecoder().decode(arrayBuffer);
            // Try to parse JSON, if it fails then we assume it's a binary PDF
            const jsonResult = JSON.parse(result);
            if (jsonResult !== null && !this.utils.isResponseSupplierError(jsonResult)) {
              if (typeof jsonResult === 'object') {
                jsonResult.settings = settings;
                if (jsonResult.hasErrors) this.annnounceCalculConditionnementErrors(jsonResult);
                else if (jsonResult.platsErreurs.length > 0) this.announcePrintErrorsBonAllotissement(jsonResult);
              }
            }
          } catch (e) {
            // If JSON parsing fails, we treat it as binary data (PDF)
            let blob = new Blob([response], {type: 'application/pdf'});
            fs_saveAs(blob, `edition-bon-allotissement-plan-prod-${settings.idPlanProduction}.pdf`);
          }
        });
      });
  };

  getPlcBonLivraison = (idPlanProduction: number): Observable<ResponseWrapper<any>> => this.httpSvc.get(URL_GET_PLC_BON_LIVRAISON + idPlanProduction, null);
}


