import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from '../../../../core/constants';
import {UtilsService} from '../../../../core/utils/utils.service';
import {DialogMsgSupplier} from '../../../../core/suppliers/dialog-msg-supplier';
import {FournisseurDTO} from '../../../../core/dtos/fournisseur-d-t-o';
import {FournisseurService} from '../../../../core/services/entities/fournisseur.service';
import {GestionFournisseurService} from '../../../../core/services/gestion-fournisseurs/gestion-fournisseur.service';
import {AdresseDTO} from '../../../../core/dtos/adresse-dto';
import {ToastService} from "../../../../core/services/technique/toast.service";
import {SiteService} from "../../../../core/site.service";
import {SiteDTO} from "../../../../core/dtos/site-dto";
import {Subscription} from "rxjs";
import {SecteursService} from "../../../../core/services/entities/secteurs.service";
import {SecteurDTO} from "../../../../core/dtos/secteur-dto";
import {TagBoxComponent} from "../../../../shared/ui/tag-box/tag-box.component";
import {LookUpComponent} from "../../../../shared/ui/lookup/lookup.component";
import {SecteurFournisseurDTO} from "../../../../core/dtos/secteurfournisseur-dto";
import {NgForm} from "@angular/forms";
import {Auth2Service} from "../../../../core/services/security/auth2.service";

@Component({
  selector: 'yo-fournisseur-fiche-identite',
  templateUrl: './fournisseur-fiche-identite.component.html',
  styleUrls: ['./fournisseur-fiche-identite.component.scss']
})
export class FournisseurFicheIdentiteComponent implements OnInit {
  @ViewChild("secteursTagbox") secteursTagBox: TagBoxComponent;
  @ViewChild("siteLookup") siteLookup: LookUpComponent;
  @ViewChild("fournisseurForm") fournisseurForm!: NgForm;
  create: boolean = true;
  fullScreen: boolean = true;
  selectedSite: number;
  selectedSecteurs: number[];
  sitesList: SiteDTO[];
  secteursList: SecteurDTO[];
  fournisseur: FournisseurDTO;

  pathFile: string = HELP_FOLDERS.FOURNISSEURS + '/fournisseur-edition';

  constructor(public fournisseurSvc: FournisseurService,
              public gestionFournisseurScv: GestionFournisseurService,
              public utils: UtilsService,
              private toastSvc: ToastService,
              private sitesSvc: SiteService,
              private secteurSvc: SecteursService,
              private auth2Svc: Auth2Service,
              @Inject('dynamicData') public fournisseurData: FournisseurDTO) {
  }

  ngOnInit() {
    this.initSecteurs();
    this.initSites();
    this.initFournisseur();
  }

  ngOnDestroy(): void {
  }


  saveFicheIdentite = (): void => {
    this.fournisseur.adresse.entityName = this.fournisseurSvc.getEntityName();
    const fournisseurClean = this.getFournisseurClean();
    fournisseurClean.secteurFournisseurList = [];

    fournisseurClean.secteurFournisseurList = this.secteursTagBox.getSelectedItems().map(secteur => new SecteurFournisseurDTO(this.fournisseur.id, secteur));

    if (this.create)
      fournisseurClean.site = this.utils.getLightSite(this.siteLookup.getSelectedItem())

    this.gestionFournisseurScv.saveFournisseur(fournisseurClean).subscribe(response => {

      if (!this.utils.isResponseSupplierError(response)) {
        this.fournisseur = fournisseurClean;
        this.fournisseur.code = response.one.code;
        this.fournisseur.id = response.one.id;
        this.fournisseur.dateCreation = response.one.dateCreation;
        this.fournisseur.dateDerniereModification = response.one.dateDerniereModification;
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Enregistrement du fournisseur "${this.fournisseur.libelle.toUpperCase()}" réalisé avec succès`);

        // annoncer que la fiche d'identite a été sauvegardée. La grille des unites de prod doit être abonnée pour faire la mise à jour
        this.gestionFournisseurScv.announceRefreshGrid(this.fournisseur);
        // mise à jour de la liste des menus
        this.gestionFournisseurScv.announceFournisseur(this.fournisseur);
      }
    });
  }

  isFormValid = (): boolean => {
    if (this.create)
      return this.siteLookup?.getSelectedItem() && this.secteursTagBox?.getSelectedItems().length !== 0 && this.fournisseurForm?.valid;
    else
      return this.secteursTagBox?.getSelectedItems().length !== 0 && this.fournisseurForm?.valid;
  }


  help = (): DialogMsgSupplier => undefined;

  private initFournisseur = (): void => {
    this.fournisseur = this.fournisseurData;
    this.create = !(this.fournisseurData.id !== 0);
    if (this.create) {
      this.fournisseur.francoDePortCout = 0;
      this.fournisseur.francoDePortTva = 20;
      this.fournisseur.actif = true;
      this.fournisseur.francoDePortActif = false;
      this.fournisseur.adresse = new AdresseDTO(null, 0);
    } else {
      this.initSelectedSecteurs();
      this.selectedSite = this.fournisseur.site.id;
    }
  }

  private getFournisseurClean = () => {
    return JSON.parse(JSON.stringify(this.fournisseur))
  }

  private initSelectedSecteurs = (): void => {
    this.selectedSecteurs = this.fournisseur.secteurFournisseurList.map(secteur => secteur.secteur.id);
  }

  initSites = (): void => {
    this.secteurSvc.getAllFromEnvironnement();
    this.sitesList = this.auth2Svc.utilisateur.siteListLocaux;
  };

  private initSecteurs = (): void => {
    this.secteursList = this.secteurSvc.secteurEnvironnement;
   }

  canEdit = () => {
    return this.create || this.auth2Svc.isSiteLocal(this.fournisseur.site.id);
  };
}
