<dx-popup
  [showTitle]="true"
  [title]="dialogTitle"
  [(visible)]="displayDialog"
  [width]="1500"
  [height]="700"
  [showCloseButton]="true"
  [fullScreen]="fullScreenDialog"
  (onHiding)="closeDialog()">
  <dxi-toolbar-item
    widget="dxButton"
    location="after"
    [options]="{
            icon: 'fullscreen',
            onClick: toggleFullScreenInvoice
        }">
  </dxi-toolbar-item>

  <div class="dialog-line text-right">
    <div class="col-lg-12">
      <yo-button *ngIf="!this.utilsSvc.isIdInit(factureClient?.id)" label="Générer la facture" tooltip="Générer la facture"
                 (onClick)="generateFacture()" class="mg-r-5"></yo-button>
      <yo-button *ngIf="this.utilsSvc.isIdInit(factureClient?.id)" tooltip="Imprimer la facture" (onClick)="printFacture()" class="mg-r-5" iconClass="fas fa-print"></yo-button>
    </div>
  </div>

  <div class="dialog-line">
    <div class="col-lg-12">
      <table class="lignes-facture-table">
        <thead>
        <tr>
          <th>Numéro de facture</th>
          <th>Point de facturation</th>
          <th>Date de facturation</th>
          <th>Date d'échéance</th>
          <th>Date début</th>
          <th>Date fin</th>
          <th>Quantité</th>
          <th>Total HT</th>
          <th>Total TTC</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>{{ factureClient?.numeroFacture }}</td>
          <td>{{ factureClient?.pointFacturation?.libelle }}</td>
          <td>{{ utilsSvc.dateToDDMMYYYY(factureClient?.dateFacturation)}}</td>
          <td>{{ utilsSvc.dateToDDMMYYYY(factureClient?.dateEcheance)}}</td>
          <td>{{ utilsSvc.dateToDDMMYYYY(factureClient?.dateDebut)}}</td>
          <td>{{ utilsSvc.dateToDDMMYYYY(factureClient?.dateFin)}}</td>
          <td>{{ factureClient?.quantite }}</td>
          <td>{{ factureClient?.montantHT?.value }} {{ factureClient?.montantHT?.uniteMonetaire }}</td>
          <td>{{ factureClient?.montantTTC?.value }} {{ factureClient?.montantTTC?.uniteMonetaire }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="dialog-line remaining-space" *ngIf="factureClient?.ligneFactureClientList?.length">
    <dx-data-grid
      [dataSource]="factureClient?.ligneFactureClientList"
      keyExpr="id"
      width="100%"
      height="490"
      [hoverStateEnabled]="true"
      [allowColumnResizing]="true"
      [rowAlternationEnabled]="true"
      [showRowLines]="true"
      [showBorders]="true">
      <dxi-column dataField="libellePlc" caption="Point de livraison"></dxi-column>
      <dxi-column dataField="libelleElementFacturable" caption="Élément facturable"></dxi-column>
      <dxi-column dataField="libellePrestation" caption="Prestation"></dxi-column>
      <dxi-column dataField="libelleRepas" caption="Repas"></dxi-column>
      <dxi-column dataField="libelleRegime" caption="Régime"></dxi-column>
      <dxi-column dataField="quantite" caption="Quantité" alignment="left"></dxi-column>
      <dxi-column dataField="montantUnitaire" caption="Prix Unitaire" cellTemplate="montantCellTemplate"></dxi-column>
      <dxi-column dataField="montantHT" caption="Montant HT" cellTemplate="montantCellTemplate"></dxi-column>
      <dxi-column dataField="tauxTVA" caption="TVA" alignment="left" cellTemplate="tvaTemplate"></dxi-column>
      <dxi-column dataField="montantTTC" caption="Montant TTC" cellTemplate="montantCellTemplate"></dxi-column>

      <div *dxTemplate="let cell of 'montantCellTemplate'">
        {{ cell.value.value }} {{ cell.value.uniteMonetaire }}
      </div>

      <div *dxTemplate="let cell of 'tvaTemplate'">
        {{ cell?.value }} %
      </div>

      <dxo-pager
        [showPageSizeSelector]="true"
        [showNavigationButtons]="false"
        [allowedPageSizes]="[20, 50, 100, 200]"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} ligne(s)">
      </dxo-pager>
    </dx-data-grid>
  </div>
</dx-popup>
