import {ContratMenuConviveDTO} from '../../contratmenuconvive-dto';
import {RepasDTO} from '../../repas-dto';
import {LigneTableauDTO} from './ligne-tableau-dto';
import {LigneGroupeTableauDTO} from './ligne-groupe-tableau-dto';
import {CelluleTableauDTO} from './cellule-tableau-dto';
import {MenuEffectifRepasDTO} from '../../menu-effectif-repas-dto';
import {SizeTableauDTO} from './size-tableau-dto';
import {ChangeDetectorRef} from '@angular/core';
import {ContratMenuConvive__ContrainteAlimDTO} from '../../contratmenuconvive__contrainte-alim-dto';
import {ColonneDateDTO} from './colonne-date-dto';
import {TreeNode} from 'primeng/api';


export class PlanningMenusDTO{

  contratMenuConviveList : ContratMenuConviveDTO[] ;

  repasList:RepasDTO[];

  ligneTableauList:LigneTableauDTO[];

  ligneGroupTableauMap:Map<number,LigneGroupeTableauDTO>;

  datesFacture: number[];

  // dateList:number[];
  dateList:ColonneDateDTO[];

  celluleTableauMap : Map<string,CelluleTableauDTO>;

  menuEffectifRepasMap : Map<string,MenuEffectifRepasDTO>;

  tableauSize : SizeTableauDTO;

  changeDetector:ChangeDetectorRef;

  contrainteAlimentaire:ContratMenuConvive__ContrainteAlimDTO;

  selectedDateMenu:Date;

  selectedDecoupageRepasSettings : TreeNode[] =[];

}
