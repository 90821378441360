export default class BonLivraisonSettingsMl {
  comment: string;
  message: string;
  plcMessageList: number[];
  plcCommentList: number[];
  idPlanProductionDetails: number[];
  displayTotalAndPOR : boolean;
  dateStart: Date;
  dateEnd: Date;
}
